<template>
    <div id="main-box">
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive"/>
        </keep-alive>
        <router-view :key="key" v-if="!$route.meta.keepAlive"/>
    </div>
</template>

<script>
    export default {
        name: "Main",

        computed: {
            key() {
                //滚动条定位
                if (document.getElementById('main-box')) {
                    document.getElementById('main-box').scrollTop = 0;
                }
                return this.$route.name ? this.$route.name + +new Date() : this.$route + +new Date()
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import "@/assets/css/common/main.scss";
</style>
