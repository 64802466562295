<template>
    <div id="app">
        <div v-if="path === 'main'">
            <!--            <Header/>-->
            <div id="main">
                <Navbar/>
                <Main/>
            </div>
        </div>
        <div v-if="path === 'login'">
            <Login/>
        </div>
    </div>
</template>

<script>
import Login from '@/views/Login.vue';
import Header from '@/components/common/Header.vue';
import Main from '@/components/common/Main.vue';
import Navbar from '@/components/common/Navbar.vue';
import {AUTH_GET_USER_INFO} from '@/store/mutations';
import {mapActions, mapState} from 'vuex';

export default {
    name: 'app',
    async created() {
        if (this.$route.path !== '/login') {
            this.path = 'main';
            await this[AUTH_GET_USER_INFO]({type: AUTH_GET_USER_INFO});
            if (!this.userInfo.id) {
                this.path = 'login';
            }
        }
    },
    components: {
        Login,
        Header,
        Main,
        Navbar
    },
    computed: {
        ...mapState({
            userInfo: state => state.auth.userInfo
        }),
        key() {
            return this.$router.name ? this.$router.name + +new Date() : this.$router + +new Date()
        }
    },
    data() {
        return {
            path: 'login'
        };
    },
    methods: {
        ...mapActions({
            AUTH_GET_USER_INFO
        })
    }
};
</script>

<style lang="scss">
@import '@/assets/css/common/body.scss';
</style>
