<template>
    <div class="menu">
        <div class="title">
            <!--            <span class="f16 fw600 col23C1A2">weelv内容中台</span>-->
            <div class="title-n">
                <div class="logo-bd">
                    <img class="logo" src="@/assets/images/icon-logo.png"/>
                </div>
                <!--                <span class="f14 mg-t10">{{userInfo.name}}</span>-->
            </div>
        </div>
        <el-menu :default-active="defaultIndex" text-color="#314759" active-text-color="#78dcbf">
            <router-link to="/">
                <el-menu-item index="1">
                    <i class="el-icon-home"></i>
                    <span slot="title">首页</span>
                </el-menu-item>
            </router-link>
            <router-link to="/userMgt"
                         v-if="Array.isArray(userInfo.role_ids) && userInfo.role_ids.includes(roleMap.admin)">
                <el-menu-item index="2">
                    <i class="el-icon-wenda"></i>
                    <span slot="title">用户管理</span>
                </el-menu-item>
            </router-link>
            <router-link to="/weelvQuestionsList"
                         v-if="Array.isArray(userInfo.role_ids) && (userInfo.role_ids.includes(roleMap.admin) || userInfo.role_ids.includes(roleMap.weelv_normal))">
                <el-menu-item index="3">
                    <i class="el-icon-wenda"></i>
                    <span slot="title">weelv内容管理</span>
                </el-menu-item>
            </router-link>
            <!--            <router-link to="/catureQuestionsList"-->
            <!--                         v-if="Array.isArray(userInfo.role_ids) && (userInfo.role_ids.includes(roleMap.admin) || userInfo.role_ids.includes(roleMap.cature_normal))">-->
            <!--                <el-menu-item index="4">-->
            <!--                    <i class="el-icon-wenda"></i>-->
            <!--                    <span slot="title">小壳内容管理</span>-->
            <!--                </el-menu-item>-->
            <!--            </router-link>-->
            <router-link to="/spiderPetWenda"
                         v-if="Array.isArray(userInfo.role_ids) && (userInfo.role_ids.includes(roleMap.admin) || userInfo.role_ids.includes(roleMap.cature_normal))">
                <el-menu-item index="5">
                    <i class="el-icon-wenda"></i>
                    <span slot="title">宠物内容库</span>
                </el-menu-item>
            </router-link>
            <el-submenu index="6"
                        v-if="Array.isArray(userInfo.role_ids) && (userInfo.role_ids.includes(roleMap.admin) || userInfo.role_ids.includes(roleMap.weelv_normal))">
              <template slot="title">
                <i class="el-icon-wenda"></i>
                <span slot="title">花袖内容管理</span>
              </template>
              <router-link to="/younailQuestionsList">
                <el-menu-item index="6-1">
                  <i class="el-icon-wenda"></i>
                  <span slot="title">问答管理</span>
                </el-menu-item>
              </router-link>
              <router-link to="/younailQualityContentList"
                           v-if="Array.isArray(userInfo.role_ids) && userInfo.role_ids.includes(roleMap.admin)">
                <el-menu-item index="6-2">
                  <i class="el-icon-wenda"></i>
                  <span slot="title">精选内容管理</span>
                </el-menu-item>
              </router-link>
              <router-link to="/younailCourseContentList"
                           v-if="Array.isArray(userInfo.role_ids) && userInfo.role_ids.includes(roleMap.admin)">
                <el-menu-item index="6-3">
                  <i class="el-icon-wenda"></i>
                  <span slot="title">教程内容管理</span>
                </el-menu-item>
              </router-link>
            </el-submenu>
          <router-link to="/sogou/lizhiXml"
                       v-if="Array.isArray(userInfo.role_ids) && (userInfo.role_ids.includes(roleMap.admin) || userInfo.role_ids.includes(roleMap.sougou_lizhi))">
            <el-menu-item index="7">
              <i class="el-icon-wenda"></i>
              <span slot="title">搜狗立知</span>
            </el-menu-item>
          </router-link>
          <router-link to="/sogou/xml"
                       v-if="Array.isArray(userInfo.role_ids) && (userInfo.role_ids.includes(roleMap.admin) || userInfo.role_ids.includes(roleMap.weelv_normal))">
            <el-menu-item index="8">
              <i class="el-icon-wenda"></i>
              <span slot="title">搜狗XML列表</span>
            </el-menu-item>
          </router-link>
        </el-menu>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import pubStateField from "@/utils/stateField";

export default {
    created() {
        if (this.$route.name === 'userMgt') {
            this.defaultIndex = '2';
        } else if (this.$route.name === 'weelvQuestionsList') {
            this.defaultIndex = '3'
        } else if (this.$route.name === 'weelvQuestionsAnswer') {
            this.defaultIndex = '3'
        } else if (this.$route.name === 'younailQuestionsList') {
            this.defaultIndex = '6-1'
        } else if (this.$route.name === 'younailQuestionsAnswer') {
            this.defaultIndex = '6-1'
        } else if (this.$route.name === 'younailQualityContentList') {
            this.defaultIndex = '6-2'
        } else if (this.$route.name === 'younailQualityContentDetail') {
            this.defaultIndex = '6-2'
        } else if (this.$route.name === 'younailCourseContentList') {
            this.defaultIndex = '6-3'
        } else if (this.$route.name === 'younailCourseContentDetail') {
            this.defaultIndex = '6-3'
        } else if (this.$route.name === 'sougouIndex') {
          this.defaultIndex = '7'
        } else if (this.$route.name === 'sougouXmlIndex') {
          this.defaultIndex = '8'
        } else {
          this.defaultIndex = '1'
        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.auth.userInfo
        })
    },
    mounted() {
        let self = this;
        window.onbeforeunload = function (e) {
            if (['contentArticleCreate', 'contentArticleUpdate'].includes(self.$route.name)) {
                e = e || window.event;
                // 兼容IE8和Firefox 4之前的版本
                if (e) {
                    e.returnValue = '关闭提示';
                }
                // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
                return '关闭提示';
            } else {
                window.onbeforeunload = null;
            }
        };
    },
    data() {
        return {
            defaultIndex: '1',
            roleMap: pubStateField.roleMap,
        };
    }
};
</script>

<style lang="scss">
@import '@/assets/css/common/navbar.scss';
@import '@/assets/css/common/icon.scss';
</style>

<style>
.el-menu {
    border-right: none !important;
}

ul.el-menu.el-menu-vertical-index {
    border: 0;
}

.el-menu-vertical-index:not(.el-menu--collapse) {
    width: 280px;
    min-height: 400px;
}

.el-icon-menu {
    color: #fff !important;
}

.el-menu-item,
.el-submenu__title {
    font-weight: 500;
}

.el-submenu .el-menu-item {
    font-size: 12px;
}
</style>
