<template>
    <div class="main-page">
        <el-card>
            <div class="page-header">
                <span>用户列表</span>
                <el-button size="mini" type="primary" class="add-new-btn" @click="showModal('add','')">添加用户</el-button>
            </div>
			<el-table class="mg-t30" :data="userList.data">
			    <el-table-column prop="id" label="ID"></el-table-column>
			    <el-table-column prop="name" label="名称"></el-table-column>
				<el-table-column prop="mobile" label="手机号码"></el-table-column>
				<el-table-column prop="role_relation" label="角色">
					<template slot-scope="scope">
					   <span>{{scope.row.role_relation[0].role_info.name}}</span>
					</template>
				</el-table-column>
			    <el-table-column label="操作">
			        <template slot-scope="scope">
			            <el-button size="mini" type="primary" @click="showModal('edit',scope.row)">编辑</el-button>
			            <el-button size="mini" type="danger" @click="deleteUser(scope.row.id)">删除</el-button>
			        </template>
			    </el-table-column>
			</el-table>
            <div class="pagination">
                <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="userList.total"
                    :page-size="pageSize"
                    :current-page="userList.page"
                    @current-change="pageChange"
                ></el-pagination>
            </div>
        </el-card>

        <el-dialog width="500px" :title="editModalTitle" :visible.sync="editModal">
            <el-form :model="editModalParams" :label-width="'80px'">
                <el-form-item label="名称">
                    <el-input class="w240" size="small" placeholder="长度不能超过16个字符" v-model="editModalParams.name"></el-input>
                </el-form-item>
				<el-form-item label="手机号">
				    <el-input class="w240" size="small" placeholder="请输入手机号码" v-model="editModalParams.mobile"></el-input>
				</el-form-item>
                <el-form-item label="状态">
                    <el-select
                        v-model="editModalParams.status"
                        size="small"
                        placeholder="是否冻结"
						class="w240"
                    >
                        <el-option label="正常" value="1"></el-option>
						<el-option label="冻结" value="2"></el-option>
                    </el-select>
                </el-form-item>
				<el-form-item label="角色">
				    <el-select v-model="editModalParams.role_ids" size="small" class="w240">
				        <el-option v-for="(item,index) in rolesList" :label="item.name" :value="item.id"></el-option>
				    </el-select>
				</el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="editModal = false">取 消</el-button>
                <el-button size="small" type="primary" @click="editModalType === 'add' ? addUser() : updateUser()">保 存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import {
        AUTH_GET_USER_LIST,
        AUTH_GET_USER_ADD_STATUS,
        AUTH_GET_USER_DEL_STATUS,
        AUTH_GET_USER_UPD_STATUS,
        AUTH_GET_USER_ROLE_LIST, WEELV_QUESTIONS_GET_LIST
    } from '@/store/mutations'

    export default {
        computed: {
            ...mapState({
                userList: state => state.auth.userList,
				userRoleList: state => state.auth.userRoleList,
				addUserStatus: state => state.auth.addUserStatus,
				delUserStatus: state => state.auth.delUserStatus,
				updUserStatus: state => state.auth.updUserStatus
            })
        },
        mounted() {
			this[AUTH_GET_USER_ROLE_LIST]({ type: AUTH_GET_USER_ROLE_LIST })
            this.getUserList();
        },
		watch:{
			userRoleList(val){
				if(val){
					this.rolesList= [];
					val.forEach((item)=>{
						let obj = {};
						obj.id = item.id.toString();
						obj.name = item.name;
						this.rolesList.push(obj);
					})
				}
			}
		},
        data() {
            return {
                page: 1,
                pageSize: 20,
                editModal: false,
                editModalTitle: '',
				editModalType: 'add',
                editModalParams: {
                    id: '',
                    name: '',
					mobile: '',
                    status: '',
					role_ids: '2'
                },
				rolesList:[]
            }
        },
        methods: {
            ...mapActions({
                AUTH_GET_USER_LIST,
                AUTH_GET_USER_ADD_STATUS,
                AUTH_GET_USER_DEL_STATUS,
                AUTH_GET_USER_UPD_STATUS,
				AUTH_GET_USER_ROLE_LIST
            }),

			// 获取列表
            getUserList() {
                this[AUTH_GET_USER_LIST]({
                    type: AUTH_GET_USER_LIST,
                    params: {
                        page: this.page,
                        page_size: this.pageSize,
                    }
                });
            },

			// 弹窗
            showModal(type,item) {
				this.editModal = true;
				if(type == 'add'){
					this.editModalTitle = '新建用户';
					this.editModalType = 'add';
					this.editModalParams.id = '';
					this.editModalParams.name = '';
					this.editModalParams.mobile = '';
					this.editModalParams.status = '1';
					this.editModalParams.role_ids = '2';

				}else{
					this.editModalTitle = '编辑用户';
					this.editModalType = 'edit';
					this.editModalParams.id = item.id;
					this.editModalParams.name = item.name;
					this.editModalParams.mobile = item.mobile;
					this.editModalParams.status = item.status.toString();
					this.editModalParams.role_ids = item.role_relation[0].role_id.toString();
				}

            },

			// 添加用户
            async addUser() {
                await this[AUTH_GET_USER_ADD_STATUS]({ type: AUTH_GET_USER_ADD_STATUS,
                    params:{
                        name: this.editModalParams.name,
                        mobile: this.editModalParams.mobile,
						status: this.editModalParams.status,
						role_ids: this.editModalParams.role_ids
                    }
                })
                if (this.addUserStatus) {
                    this.$message.success('创建成功')
                    this.editModal = false
                    this.getUserList()
                }
            },

			// 编辑用户
            async updateUser() {
                await this[AUTH_GET_USER_UPD_STATUS]({ type: AUTH_GET_USER_UPD_STATUS,
                    params:{
                        id: this.editModalParams.id,
						name: this.editModalParams.name,
						mobile: this.editModalParams.mobile,
						status: this.editModalParams.status,
						role_ids: this.editModalParams.role_ids
                    }
                })
                if (this.updUserStatus) {
                    this.$message.success('更新成功')
                    this.editModal = false
                    this.getUserList()
                }
            },

			// 删除用户
            deleteUser(id) {
				this.$confirm('确认删除?', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'error'
				}).then(async() => {
					await this[AUTH_GET_USER_DEL_STATUS]({ type: AUTH_GET_USER_DEL_STATUS,
					    params:{
					        id: id
					    }
					})
					if (this.delUserStatus) {
					    this.$message.success('删除成功')
					    this.getUserList()
					}
				})
				.catch(() => {});
            },

			// 分页
			pageChange(page) {
			    this.page = page
			    this.getUserList()
			},
        }
    }
</script>

<style lang="scss" scoped>

</style>
