import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from '@/plugins/logger'

// 路由参数管理
import routerParams from '@/store/modules/routerParams'

// 用户管理
import auth from '@/store/modules/auth'

// 内容管理
import weelvQuestion from '@/store/modules/weelv/question'

//小壳问答
import catureQuestion from '@/store/modules/cature/question'

import spiderPetWenda from '@/store/modules/spider/index'

//younail问答
import younailQuestion from '@/store/modules/younail/question'
import younailQualityContent from '@/store/modules/younail/qualityContent'
import younailCourseContent from '@/store/modules/younail/courseContent'

import sougouIndex from '@/store/modules/sougou/index'


Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        routerParams,
        auth,
        weelvQuestion,
        catureQuestion,
        spiderPetWenda,
        younailQuestion,
        younailQualityContent,
        younailCourseContent,
        sougouIndex,
    },
    strict: debug,
    plugins: debug ? [createLogger()] : []
})
