import {
  ROUTER_CATURE_QUESTIONS_INDEX,
  ROUTER_WEELV_QUESTIONS_INDEX,
  ROUTER_YOUNAIL_COURSE_CONTENT_INDEX,
  ROUTER_YOUNAIL_QUALITY_CONTENT_INDEX,
  ROUTER_YOUNAIL_QUESTIONS_INDEX
} from '@/store/mutations'

const state = {
    weelvQuestionIndex: {
        page: 1,
        pageSize: 20,
      question_id: null,
      title: null,
      content_status: null,
      baidu_qa: null,
      baidu_feedback_status: null,
      audit_status: null,
      user_info_id: '',
      user_info_value: '',
      month: null,
      distribution_month: null,
      status: null,
    },
    catureQuestionIndex: {
        page: 1,
        pageSize: 20,
        question_id: null,
        title: null,
        content_status: null,
        baidu_qa: null,
        baidu_feedback_status: null,
        audit_status: null,
        user_info_id: '',
        user_info_value: '',
        month: null,
    },
    younailQuestionIndex: {
        page: 1,
      pageSize: 20,
      question_id: null,
      title: null,
      content_status: null,
      baidu_qa: null,
      baidu_feedback_status: null,
      audit_status: null,
      user_info_id: '',
      user_info_value: '',
      month: null,
      status: null,
    },
    younailQualityContentIndex: {
        page: 1,
        pageSize: 20,
        content_id: null,
        title: null,
        is_recommend: null,
        status: null,
    },
    younailCourseContentIndex: {
        page: 1,
        pageSize: 20,
        course_id: null,
        title: null,
        is_hot: null,
        type: null,
        status: null,
    }
}

const getters = {}

// actions
const actions = {
    [ROUTER_WEELV_QUESTIONS_INDEX]({commit, state}, action) {
        commit(action.type, {
            page: action.params.page || 1,
            pageSize: action.params.pageSize || 20,
          question_id: action.params.question_id,
          title: action.params.title,
          content_status: action.params.content_status,
          baidu_qa: action.params.baidu_qa,
          baidu_feedback_status: action.params.baidu_feedback_status,
          audit_status: action.params.audit_status,
          user_info_id: action.params.user_info_id,
          user_info_value: action.params.user_info_value,
          month: action.params.month,
          distribution_month: action.params.distribution_month,
          status: action.params.status,
        })
    },
    [ROUTER_CATURE_QUESTIONS_INDEX]({commit, state}, action) {
        commit(action.type, {
            page: action.params.page || 1,
            pageSize: action.params.pageSize || 20,
            question_id: action.params.question_id,
            title: action.params.title,
            content_status: action.params.content_status,
            baidu_qa: action.params.baidu_qa,
            baidu_feedback_status: action.params.baidu_feedback_status,
            audit_status: action.params.audit_status,
            user_info_id: action.params.user_info_id,
            user_info_value: action.params.user_info_value,
            month: action.params.month,
        })
    },
    [ROUTER_YOUNAIL_QUESTIONS_INDEX]({commit, state}, action) {
        commit(action.type, {
            page: action.params.page || 1,
          pageSize: action.params.pageSize || 20,
          question_id: action.params.question_id,
          title: action.params.title,
          content_status: action.params.content_status,
          baidu_qa: action.params.baidu_qa,
          baidu_feedback_status: action.params.baidu_feedback_status,
          audit_status: action.params.audit_status,
          user_info_id: action.params.user_info_id,
          user_info_value: action.params.user_info_value,
          month: action.params.month,
          status: action.params.status,
        })
    },
    [ROUTER_YOUNAIL_QUALITY_CONTENT_INDEX]({commit, state}, action) {
        commit(action.type, {
            page: action.params.page || 1,
            pageSize: action.params.pageSize || 20,
            content_id: action.params.content_id,
            title: action.params.title,
            is_recommend: action.params.is_recommend,
            status: action.params.status,
        })
    },
    [ROUTER_YOUNAIL_COURSE_CONTENT_INDEX]({commit, state}, action) {
        commit(action.type, {
            page: action.params.page || 1,
            pageSize: action.params.pageSize || 20,
            course_id: action.params.course_id,
            title: action.params.title,
            is_hot: action.params.is_hot,
            type: action.params.type,
            status: action.params.status,
        })
    },
}

// mutations
const mutations = {
    [ROUTER_WEELV_QUESTIONS_INDEX](state, data) {
        state.weelvQuestionIndex = data
    },
    [ROUTER_CATURE_QUESTIONS_INDEX](state, data) {
        state.catureQuestionIndex = data
    },
    [ROUTER_YOUNAIL_QUESTIONS_INDEX](state, data) {
        state.younailQuestionIndex = data
    },
    [ROUTER_YOUNAIL_QUALITY_CONTENT_INDEX](state, data) {
        state.younailQualityContentIndex = data
    },
    [ROUTER_YOUNAIL_COURSE_CONTENT_INDEX](state, data) {
        state.younailCourseContentIndex = data
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
