import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store/index'
import '@/plugins/element.js'
import './assets/css/element-variables.scss'
import axios from 'axios'

Vue.config.productionTip = false
window.axios = axios

router.beforeEach((to, from, next) => {
	if (to.meta.title) {
		document.title = to.meta.title
	}
	next()
})

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
