import {
    AUTH_GET_USER_INFO,
    AUTH_GET_USER_LIST,
    AUTH_GET_USER_ADD_STATUS,
    AUTH_GET_USER_DEL_STATUS,
    AUTH_GET_USER_UPD_STATUS,
    AUTH_GET_USER_DETAIL_BYID,
    AUTH_GET_USER_ROLE_LIST
} from '@/store/mutations'

import {loginCheck} from '@/utils/loginCheck'
import {Message} from 'element-ui'

const state = {
    userInfo: {
        role_ids: []
    },
    userList: [],
    userDetail: {},
    userRoleList: [],
    addUserStatus: false,
    delUserStatus: false,
    updUserStatus: false,
}

const getters = {}

// actions
const actions = {

    // 获取当前登陆用户信息
    async [AUTH_GET_USER_INFO]({commit, state}, action) {
        commit(action.type, false)
        await axios.get('/user/info').then(response => {
            loginCheck(response.data.request_code)
            if (response.data.request_code === 0) {
                commit(action.type, response.data.data)
            } else {
                Message.error(response.data.message)
            }
        })
    },

    // 获取用户列表
    async [AUTH_GET_USER_LIST]({commit, state}, action) {
        commit(action.type, false)

        await axios.get('/user/list', {
            params: {
                page: action.params.page,
                page_size: action.params.page_size
            }
        }).then(response => {
            loginCheck(response.data.request_code)
            if (response.data.request_code === 0) {
                commit(action.type, response.data.data)
            } else {
                Message.error(response.data.message)
            }
        })
    },

    // 获取用户角色列表
    [AUTH_GET_USER_ROLE_LIST]({commit, state}, action) {
        commit(action.type, false)
        axios.get('/role/all').then(response => {
            loginCheck(response.data.request_code)
            if (response.data.request_code === 0) {
                commit(action.type, response.data.data)
            } else {
                Message.error(response.data.message)
            }
        })
    },

    // 添加用户
    async [AUTH_GET_USER_ADD_STATUS]({commit, state}, action) {
        commit(action.type, false)
        await axios.post('/user/create', {
            name: action.params.name,
            mobile: action.params.mobile,
            status: action.params.status,
            role_ids: action.params.role_ids
        }).then(response => {
            if (response.data.request_code === 0) {
                commit(action.type, response.data.data)
            } else {
                Message.error(response.data.message)
            }
        })
    },

    // 删除用户
    async [AUTH_GET_USER_DEL_STATUS]({commit, state}, action) {
        commit(action.type, false)
        await axios.post('/user/delete', {
            id: action.params.id
        }).then(response => {
            if (response.data.request_code === 0) {
                commit(action.type, response.data.data)
            } else {
                Message.error(response.data.message)
            }
        })
    },

    // 更新用户信息
    async [AUTH_GET_USER_UPD_STATUS]({commit, state}, action) {
        commit(action.type, false)
        await axios.post('/user/update', {
            id: action.params.id,
            name: action.params.name,
            mobile: action.params.mobile,
            status: action.params.status,
            role_ids: action.params.role_ids
        }).then(response => {
            if (response.data.request_code === 0) {
                commit(action.type, response.data.data)
            } else {
                Message.error(response.data.message)
            }
        })
    },

    // 获取用户详情
    async [AUTH_GET_USER_DETAIL_BYID]({
                                          commit,
                                          state
                                      }, action) {
        commit(action.type, false)
        let id = action.params.id;
        await axios.get(`/user/detail?id=${id}`).then(response => {
            if (response.data.request_code === 0) {
                commit(action.type, response.data.data)
            } else {
                Message.error(response.data.message)
            }
        })
    },
}

// mutations
const mutations = {
    [AUTH_GET_USER_INFO](state, data) {
        state.userInfo = data
    },
    [AUTH_GET_USER_LIST](state, data) {
        state.userList = data
    },
    [AUTH_GET_USER_ADD_STATUS](state, data) {
        state.addUserStatus = data
    },
    [AUTH_GET_USER_DEL_STATUS](state, data) {
        state.delUserStatus = data
    },
    [AUTH_GET_USER_UPD_STATUS](state, data) {
        state.updUserStatus = data
    },
    [AUTH_GET_USER_DETAIL_BYID](state, data) {
        state.userDetail = data
    },
    [AUTH_GET_USER_ROLE_LIST](state, data) {
        state.userRoleList = data
    }

}

export default {
    state,
    getters,
    actions,
    mutations
}
