<template>
    <div class="main-page">
        <el-card>
            <div class="page-header">
                <span>问题列表</span>
                <span class="fw600">
                    该条件下问题总数：{{ list.total }}个
                </span>
            </div>
            <div class="flex-row flex-js-start flex-al-center mg-t30">
                <el-input class="mg-r10 w200" v-model="keyword" size="small" placeholder="关键词"></el-input>
                <el-button type="primary" size="mini" @click="searchQuestion">筛选</el-button>
            </div>

            <el-table class="mg-t20" :highlight-current-row="true" :stripe="true" :data="list.data"
                      align="left">
                <el-table-column prop="id" label="ID" width="70"></el-table-column>
                <el-table-column prop="title" label="问题" width="300"></el-table-column>
                <el-table-column prop="content" label="回答"></el-table-column>
<!--                <el-table-column label="操作"-->
<!--                                 :width="Array.isArray(userInfo.role_ids) && userInfo.role_ids.includes(roleMap.admin) ? 180 : 80">-->
<!--                    <template slot-scope="scope">-->
<!--                        <el-button size="mini" type="primary" @click="">详情</el-button>-->
<!--                    </template>-->
<!--                </el-table-column>-->
            </el-table>

            <div class="pagination">
                <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="list.total"
                    :page-size="pageSize"
                    :current-page="list.page"
                    @current-change="pageChange"
                ></el-pagination>
            </div>
        </el-card>
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import {
    SPIDER_PET_WENDA_GET_LIST,
} from '@/store/mutations';
import pubStateField from '@/utils/stateField';
import UserSearchInput from '@/components/user/userSearchInput.vue';

export default {
    components: {UserSearchInput},
    computed: {
        ...mapState({
            userInfo: state => state.auth.userInfo,
            list: state => state.spiderPetWenda.list,
        })
    },
    mounted() {
        this.getList();
    },
    watch: {},
    data() {
        return {
            roleMap: pubStateField.roleMap,
            type: '1',
            page: 1,
            pageSize: 20,
            keyword: '',
        };
    },
    methods: {
        ...mapActions({
            SPIDER_PET_WENDA_GET_LIST,
        }),
        // 获取问题列表
        getList() {
            this[SPIDER_PET_WENDA_GET_LIST]({
                type: SPIDER_PET_WENDA_GET_LIST,
                params: {
                    page: this.page,
                    page_size: this.pageSize,
                    keyword: this.keyword,
                }
            });
        },
        // 分页
        pageChange(page) {
            this.page = page;
            this.getList();
        },
        async searchQuestion() {
            this.page = 1;
            this.getList();
        },
    }
};
</script>

<style lang="scss" scoped>
.el-table {
    text-align: left;
    line-height: 40px;

    .cell {
        line-height: 32px;
    }
}
</style>
