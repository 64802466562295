<template>
    <div>
        <el-autocomplete
            :size="size"
            v-model="value.value"
            placeholder="请输入用户名称"
            :fetch-suggestions="getUserList"
            :debounce="500"
            @select="changeValue"
            @input="changeValue"
            :style="{ 'width': width+'px' }"
        ></el-autocomplete>
    </div>
</template>

<script>
export default {
    name: "user-search-input",
    props: {
        size: {
            type: String,
            default: 'small',
            required: false
        },
        value: {
            type: Object,
            default: () => {
                return {id: '', value: ''}
            },
            required: false
        },
        type: {
            type: String,
            default: '',
            required: false
        },
        width: {
            type: Number,
            default: 400,
            required: false
        }
    },
    data() {
        return {
            cancelToken: null,
            timeout: null
        }
    },
    methods: {
        getUserList(userName, callback) {
            axios.get(`/user/list?name=${userName || ''}&page_size=40`)
                .then(response => {
                    if (response.data.request_code === 0) {
                        let userData = [{id: 0, value: ''}]
                        response.data.data.data.forEach(item => {
                            userData.push({
                                id: item.id,
                                value: item.name,
                            })
                        })
                        clearTimeout(this.timeout)
                        this.timeout = setTimeout(() => {
                            callback(userData)
                        }, 1000 * Math.random())
                    }
                })
        },
        changeValue(item) {
            if (!item) {
                this.$emit('input', {id: 0, value: ''})
            } else {
                if (typeof item === 'object') {
                    this.$emit('input', item)
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
