export const ROUTER_WEELV_QUESTIONS_INDEX = 'ROUTER_WEELV_QUESTIONS_INDEX'
export const ROUTER_CATURE_QUESTIONS_INDEX = 'ROUTER_CATURE_QUESTIONS_INDEX'
export const ROUTER_YOUNAIL_QUESTIONS_INDEX = 'ROUTER_YOUNAIL_QUESTIONS_INDEX'
export const ROUTER_YOUNAIL_QUALITY_CONTENT_INDEX = 'ROUTER_YOUNAIL_QUALITY_CONTENT_INDEX'
export const ROUTER_YOUNAIL_COURSE_CONTENT_INDEX = 'ROUTER_YOUNAIL_COURSE_CONTENT_INDEX'

/****************** 用户管理 ******************/
export const AUTH_GET_USER_INFO = 'AUTH_GET_USER_INFO'
export const AUTH_GET_USER_LIST = 'AUTH_GET_USER_LIST'
export const AUTH_GET_USER_ADD_STATUS = 'AUTH_GET_USER_ADD_STATUS'
export const AUTH_GET_USER_DEL_STATUS = 'AUTH_GET_USER_DEL_STATUS'
export const AUTH_GET_USER_UPD_STATUS = 'AUTH_GET_USER_UPD_STATUS'
export const AUTH_GET_USER_DETAIL_BYID = 'AUTH_GET_USER_DETAIL_BYID'
export const AUTH_GET_USER_ROLE_LIST = 'AUTH_GET_USER_ROLE_LIST'

/****************** 内容管理 weelv问答 ******************/
export const WEELV_QUESTIONS_GET_LIST = 'WEELV_QUESTIONS_GET_LIST'
export const WEELV_QUESTIONS_GET_ADD_STATUS = 'WEELV_QUESTIONS_GET_ADD_STATUS'
export const WEELV_QUESTIONS_GET_DEL_STATUS = 'WEELV_QUESTIONS_GET_DEL_STATUS'
export const WEELV_QUESTIONS_GET_UPD_STATUS = 'WEELV_QUESTIONS_GET_UPD_STATUS'
export const WEELV_QUESTIONS_GET_DETAIL_BY_ID = 'WEELV_QUESTIONS_GET_DETAIL_BY_ID'
export const WEELV_QUESTIONS_GET_IMPORT_STATUS = 'WEELV_QUESTIONS_GET_IMPORT_STATUS'
export const WEELV_QUESTIONS_GET_DISTRIBUTION_STATUS = 'WEELV_QUESTIONS_GET_DISTRIBUTION_STATUS'
export const WEELV_QUESTIONS_GET_ADD_TAG_STATUS = 'WEELV_QUESTIONS_GET_ADD_TAG_STATUS'
export const WEELV_QUESTIONS_GET_UPLOAD_IMG_STATUS = 'WEELV_QUESTIONS_GET_UPLOAD_IMG_STATUS'

export const WEELV_QUESTIONS_TAG_LIST = 'WEELV_QUESTIONS_TAG_LIST'
export const WEELV_QUESTIONS_TAG_CREAT = 'WEELV_QUESTIONS_TAG_CREAT'
export const WEELV_QUESTIONS_TAG_UPDATE = 'WEELV_QUESTIONS_TAG_UPDATE'
export const WEELV_QUESTIONS_TAG_DELETE = 'WEELV_QUESTIONS_TAG_DELETE'
export const WEELV_QUESTIONS_TAG_HOT_UPDATE = 'WEELV_QUESTIONS_TAG_HOT_UPDATE'


/****************** 内容管理 cature问答 ******************/
export const CATURE_QUESTIONS_GET_LIST = 'CATURE_QUESTIONS_GET_LIST'
export const CATURE_QUESTIONS_GET_ADD_STATUS = 'CATURE_QUESTIONS_GET_ADD_STATUS'
export const CATURE_QUESTIONS_GET_DEL_STATUS = 'CATURE_QUESTIONS_GET_DEL_STATUS'
export const CATURE_QUESTIONS_GET_UPD_STATUS = 'CATURE_QUESTIONS_GET_UPD_STATUS'
export const CATURE_QUESTIONS_GET_IMPORT_STATUS = 'CATURE_QUESTIONS_GET_IMPORT_STATUS'
export const CATURE_QUESTIONS_GET_DISTRIBUTION_STATUS = 'CATURE_QUESTIONS_GET_DISTRIBUTION_STATUS'
export const CATURE_QUESTIONS_GET_ADD_TAG_STATUS = 'CATURE_QUESTIONS_GET_ADD_TAG_STATUS'
export const CATURE_QUESTIONS_GET_UPLOAD_IMG_STATUS = 'CATURE_QUESTIONS_GET_UPLOAD_IMG_STATUS'

export const CATURE_QUESTIONS_TAG_LIST = 'CATURE_QUESTIONS_TAG_LIST'
export const CATURE_QUESTIONS_TAG_CREAT = 'CATURE_QUESTIONS_TAG_CREAT'
export const CATURE_QUESTIONS_TAG_UPDATE = 'CATURE_QUESTIONS_TAG_UPDATE'
export const CATURE_QUESTIONS_TAG_DELETE = 'CATURE_QUESTIONS_TAG_DELETE'
export const CATURE_QUESTIONS_TAG_HOT_UPDATE = 'CATURE_QUESTIONS_TAG_HOT_UPDATE'

/****************** 内容管理 宠物爬虫内容 ******************/
export const SPIDER_PET_WENDA_GET_LIST = 'SPIDER_PET_WENDA_GET_LIST'
export const SPIDER_PET_WENDA_GET_DETAIL = 'SPIDER_PET_WENDA_GET_DETAIL'


/****************** 内容管理 younail问答 ******************/
export const YOUNAIL_QUESTIONS_GET_LIST = 'YOUNAIL_QUESTIONS_GET_LIST'
export const YOUNAIL_QUESTIONS_GET_DETAIL_BY_ID = 'YOUNAIL_QUESTIONS_GET_DETAIL_BY_ID'
export const YOUNAIL_QUESTIONS_GET_ADD_STATUS = 'YOUNAIL_QUESTIONS_GET_ADD_STATUS'
export const YOUNAIL_QUESTIONS_GET_DEL_STATUS = 'YOUNAIL_QUESTIONS_GET_DEL_STATUS'
export const YOUNAIL_QUESTIONS_GET_UPD_STATUS = 'YOUNAIL_QUESTIONS_GET_UPD_STATUS'
export const YOUNAIL_QUESTIONS_GET_IMPORT_STATUS = 'YOUNAIL_QUESTIONS_GET_IMPORT_STATUS'
export const YOUNAIL_QUESTIONS_GET_DISTRIBUTION_STATUS = 'YOUNAIL_QUESTIONS_GET_DISTRIBUTION_STATUS'
export const YOUNAIL_QUESTIONS_GET_ADD_TAG_STATUS = 'YOUNAIL_QUESTIONS_GET_ADD_TAG_STATUS'
export const YOUNAIL_QUESTIONS_GET_UPLOAD_IMG_STATUS = 'YOUNAIL_QUESTIONS_GET_UPLOAD_IMG_STATUS'
export const YOUNAIL_QUESTIONS_GET_UPLOAD_VIDEO_STATUS = 'YOUNAIL_QUESTIONS_GET_UPLOAD_VIDEO_STATUS'

export const YOUNAIL_QUESTIONS_TAG_LIST = 'YOUNAIL_QUESTIONS_TAG_LIST'
export const YOUNAIL_QUESTIONS_TAG_CREAT = 'YOUNAIL_QUESTIONS_TAG_CREAT'
export const YOUNAIL_QUESTIONS_TAG_UPDATE = 'YOUNAIL_QUESTIONS_TAG_UPDATE'
export const YOUNAIL_QUESTIONS_TAG_DELETE = 'YOUNAIL_QUESTIONS_TAG_DELETE'
export const YOUNAIL_QUESTIONS_TAG_HOT_UPDATE = 'YOUNAIL_QUESTIONS_TAG_HOT_UPDATE'

/****************** 内容管理 younail 精品内容 ******************/
export const YOUNAIL_QUALITY_CONTENT_GET_LIST = 'YOUNAIL_QUALITY_CONTENT_GET_LIST'
export const YOUNAIL_QUALITY_CONTENT_GET_DETAIL_BY_ID = 'YOUNAIL_QUALITY_CONTENT_GET_DETAIL_BY_ID'
export const YOUNAIL_QUALITY_CONTENT_GET_ADD_STATUS = 'YOUNAIL_QUALITY_CONTENT_GET_ADD_STATUS'
export const YOUNAIL_QUALITY_CONTENT_GET_DEL_STATUS = 'YOUNAIL_QUALITY_CONTENT_GET_DEL_STATUS'
export const YOUNAIL_QUALITY_CONTENT_GET_UPD_STATUS = 'YOUNAIL_QUALITY_CONTENT_GET_UPD_STATUS'
export const YOUNAIL_QUALITY_CONTENT_GET_ADD_TAG_STATUS = 'YOUNAIL_QUALITY_CONTENT_GET_ADD_TAG_STATUS'

/****************** 内容管理 younail 教程内容 ******************/
export const YOUNAIL_COURSE_CONTENT_GET_LIST = 'YOUNAIL_COURSE_CONTENT_GET_LIST'
export const YOUNAIL_COURSE_CONTENT_GET_DETAIL_BY_ID = 'YOUNAIL_COURSE_CONTENT_GET_DETAIL_BY_ID'
export const YOUNAIL_COURSE_CONTENT_GET_ADD_STATUS = 'YOUNAIL_COURSE_CONTENT_GET_ADD_STATUS'
export const YOUNAIL_COURSE_CONTENT_GET_DEL_STATUS = 'YOUNAIL_COURSE_CONTENT_GET_DEL_STATUS'
export const YOUNAIL_COURSE_CONTENT_GET_UPD_STATUS = 'YOUNAIL_COURSE_CONTENT_GET_UPD_STATUS'


/****************** 搜狗 xml生成 ******************/
export const SOUGOU_LIZHI_XML_GET_LIST = 'SOUGOU_LIZHI_XML_GET_LIST'
export const SOUGOU_LIZHI_URL_UPLOAD = 'SOUGOU_LIZHI_URL_UPLOAD'
export const SOUGOU_LIZHI_URL_GET_LIST = 'SOUGOU_LIZHI_URL_GET_LIST'
export const SOUGOU_LIZHI_URL_DELETE = 'SOUGOU_LIZHI_URL_DELETE'
export const SOUGOU_LIZHI_URL_CREATE = 'SOUGOU_LIZHI_URL_CREATE'

/****************** 搜狗 xml列表 ******************/
export const SOUGOU_XML_GET_WEELV_LIST = 'SOUGOU_XML_GET_WEELV_LIST'
export const SOUGOU_XML_GET_YOUNAIL_LIST = 'SOUGOU_XML_GET_YOUNAIL_LIST'
