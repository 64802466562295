<template>
    <div class="editor-module">
        <div class="editor-tab" ref="tab"></div>
        <div class="editor-text" ref="editor"></div>
    </div>
</template>

<script>
import E from 'wangeditor';
import pubStateField from '@/utils/stateField';

export default {
    name: 'wang-editor',
    data() {
        return {
            photoHost: pubStateField.photoHost,
            videoHost: pubStateField.videoHost,
            editor: '',
            info_: null,
            isChange: false
        };
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    watch: {
        value(value) {
            if (!this.isChange) {
                this.editor.txt.html(this.value);
            }
            this.isChange = false;
        }
    },
    props: {
        value: {
            type: String,
            default: ''
        },
        model: {
            type: String,
            default: ''
        }
    },
    methods: {
        get() {
            this.editor = new E(this.$refs.tab, this.$refs.editor);
            this.editor.config.menus = [
                'head', // 标题
                'bold', // 粗体
                'fontSize', // 字号
                'fontName',  // 字体
                'italic', // 斜体
                'underline', // 下划线
                'strikeThrough', // 删除线
                'foreColor', // 文字颜色
                'backColor', // 背景颜色
                'link', // 插入链接
                'list',  // 列表
                'justify', // 对齐方式
                'quote',  // 引用
                'emoticon',  // 表情
                'image', // 插入图片
                'table',  // 表格
                'video',  // 插入视频
                'code',  // 插入代码
                'undo',  // 撤销
                'redo'  // 重复
            ];
            this.editor.config.zIndex = 1;

            this.editor.config.uploadImgServer = '/weelv/photo/uploadBatch';
            if (this.model === 'cature') {
                this.editor.config.uploadImgServer = '/cature/photo/uploadBatch';
            }
            if (this.model === 'weelv') {
                this.editor.config.uploadImgServer = '/weelv/photo/uploadBatch';
            }

            this.editor.config.uploadFileName = 'file0';
            this.editor.config.uploadImgMaxLength = 1;
            this.editor.config.uploadImgMaxSize = 10 * 1024 * 1024;
            this.editor.config.showLinkImg = true;

            let that = this;
            this.editor.config.uploadImgHooks = {
                customInsert: function (insertImg, result, editor) {
                    if (result.request_code === 0 && Array.isArray(result.data)) {
                        result.data.forEach(item => {
                            insertImg(that.photoHost[item.storage_source] + '/' + item.file_id)
                        })
                    } else {
                        that.$message.error(result.message || '上传图片失败');
                    }
                }
            }

            // 视频上传
            this.editor.config.uploadVideoServer = '/weelv/video/upload'; // 上传接口
            if (this.model === 'cature') {
                this.editor.config.uploadVideoServer = '/cature/video/upload';
            }
            if (this.model === 'weelv') {
                this.editor.config.uploadVideoServer = '/weelv/video/upload';
            }

            this.editor.config.uploadVideoName = 'file';
            this.editor.config.uploadVideoMaxLength = 1;
            this.editor.config.uploadVideoMaxSize = 512 * 1024 * 1024;
            this.editor.config.uploadVideoHooks = {
                customInsert: function (insertVideo, result, editor) {
                    if (result.request_code === 0) {
                        insertVideo(that.videoHost[result.data.storage_source] + '/' + result.data.file_id)
                        editor.cmd.do('insertHtml', '<p></p>')
                    } else {
                        that.$message.error(result.message || '上传图片失败');
                    }
                }
            }

            this.editor.config.onchange = html => {
                this.isChange = true;
                this.info_ = html;
                this.$emit('change', this.info_);
            };

            this.editor.create();
        },
        setHtml(txt) {
            this.editor.txt.html(txt);
        },
    },
    mounted() {
        this.get();
    }
};
</script>

<style scoped>
.editor-module {
    border: 1px solid #dcdcdc;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    height: 600px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.editor-tab {
    width: 100%;
    padding: 0;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.editor-tab .w-e-menu {
    margin-right: 10px;
}

.editor-text {
    width: 100%;
    height: 600px;
}

.editor-text .w-e-text::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 10px;
}

.editor-text .w-e-text::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
}

.editor-text .w-e-text::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    border-radius: 10px;
}
</style>
