import {
    YOUNAIL_COURSE_CONTENT_GET_LIST,
    YOUNAIL_COURSE_CONTENT_GET_ADD_STATUS,
    YOUNAIL_COURSE_CONTENT_GET_DEL_STATUS,
    YOUNAIL_COURSE_CONTENT_GET_UPD_STATUS,
    YOUNAIL_COURSE_CONTENT_GET_DETAIL_BY_ID,
} from '@/store/mutations'
import {
    loginCheck
} from '@/utils/loginCheck'
import {
    Message,
    Notification
} from 'element-ui'

const state = {
    courseContentList: {},
    addCourseContentStatus: false,
    updCourseContentStatus: false,
    delCourseContentStatus: false,
    courseContentDetail: {},
}

const getters = {}

// actions
const actions = {
    // 获取精品内容列表
    async [YOUNAIL_COURSE_CONTENT_GET_LIST]({commit, state}, action) {
        await axios.get('/younail/courseContent/list', {
            params: {
                page: action.params.page,
                page_size: action.params.page_size,
                course_id: action.params.course_id || '',
                title: action.params.title || '',
                type: action.params.type || '',
                is_hot: action.params.is_hot || '',
                status: action.params.status || '',
            }
        }).then(response => {
            loginCheck(response.data.request_code)
            if (response.data.request_code === 0) {
                commit(action.type, response.data.data)
            } else {
                Message.error(response.data.message)
            }
        })
    },

    // 创建精品内容
    async [YOUNAIL_COURSE_CONTENT_GET_ADD_STATUS]({commit, state}, action) {
        commit(action.type, false)

        await axios.post('/younail/courseContent/create', {
            title: action.params.title || '',
            list_image_cover: action.params.list_image_cover || '',
            image_cover: action.params.image_cover || '',
            video_file_id: action.params.video_file_id || '',
            attribute_ids: action.params.attribute_ids || '',
            question_ids: action.params.question_ids || '',
            sort: action.params.sort || 0,
            is_hot: action.params.is_hot || 0,
            type: action.params.type || 2,
            status: action.params.status || 2,
            origin_content: action.params.origin_content || '',
            content: action.params.content || '',
            tag_ids: action.params.tag_ids || '',
        }).then(response => {
            loginCheck(response.data.request_code)
            if (response.data.request_code === 0) {
                commit(action.type, response.data.data)
            } else {
                Message.error(response.data.message)
            }
        })
    },

    // 编辑精品内容
    async [YOUNAIL_COURSE_CONTENT_GET_UPD_STATUS]({commit, state}, action) {
        commit(action.type, false)

        await axios.post('/younail/courseContent/update', {
            id: action.params.id,
            title: action.params.title || '',
            list_image_cover: action.params.list_image_cover || '',
            image_cover: action.params.image_cover || '',
            video_file_id: action.params.video_file_id || '',
            attribute_ids: action.params.attribute_ids || '',
            question_ids: action.params.question_ids || '',
            sort: action.params.sort || 0,
            is_hot: action.params.is_hot || 0,
            type: action.params.type || 2,
            status: action.params.status || 2,
            origin_content: action.params.origin_content || '',
            content: action.params.content || '',
            tag_ids: action.params.tag_ids || '',
        }).then(response => {
            loginCheck(response.data.request_code)
            if (response.data.request_code === 0) {
                commit(action.type, response.data.data)
            } else {
                Message.error(response.data.message)
            }
        })
    },

    // 删除精品内容
    async [YOUNAIL_COURSE_CONTENT_GET_DEL_STATUS]({commit, state}, action) {
        commit(action.type, false)
        await axios.post(`/younail/courseContent/delete`, {
            id: action.params.id
        }).then(response => {
            if (response.data.request_code === 0) {
                commit(action.type, response.data.data)
            } else {
                Message.error(response.data.message)
            }
        })
    },

    // 获取精品内容详情
    [YOUNAIL_COURSE_CONTENT_GET_DETAIL_BY_ID]({commit, state}, action) {
        let id = action.params.id || ''
        axios.get(`/younail/courseContent/detail?id=${id}`).then(response => {
            if (response.data.request_code === 0) {
                commit(action.type, response.data.data)
            } else {
                Message.error(response.data.message)
            }
        })
    },
}

// mutations
const mutations = {
    [YOUNAIL_COURSE_CONTENT_GET_LIST](state, data) {
        state.courseContentList = data
    },
    [YOUNAIL_COURSE_CONTENT_GET_ADD_STATUS](state, data) {
        state.addCourseContentStatus = data
    },
    [YOUNAIL_COURSE_CONTENT_GET_DEL_STATUS](state, data) {
        state.delCourseContentStatus = data
    },
    [YOUNAIL_COURSE_CONTENT_GET_UPD_STATUS](state, data) {
        state.updCourseContentStatus = data
    },
    [YOUNAIL_COURSE_CONTENT_GET_DETAIL_BY_ID](state, data) {
        state.courseContentDetail = data
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
