<template>
    <div class="main-page">
        <el-card>
            <div class="page-header">
				<span v-if="editType === 'edit'">
					<span class="f16">教程内容详情</span>
					<span class="f12 mg-l10">教程内容ID{{ detailParams.id }}</span>
				</span>
                <span v-else><span class="f16">创建教程内容</span></span>
                <el-button size="mini" type="primary" class="add-new-btn" @click="toCourseContentList">返回</el-button>
            </div>
            <el-form class="mg-t30" :label-width="'100px'">
                <el-form-item label="类型">
                    <el-select v-model="detailParams.type" class="selection" size="mini" placeholder="类型">
                        <el-option :label="item" :value="index" v-for="(item, index) in typeMap"></el-option>
                    </el-select>

                </el-form-item>

                <el-form-item label="标题">
                    <el-input class="w400" size="small" v-model="detailParams.title" clearable
                              placeholder="输入标题不能超过128个字符"></el-input>
                </el-form-item>

                <el-form-item label="列表 封面图">
                    <div class="flex-row flex-js-start">
                        <el-upload
                            action=""
                            :auto-upload="true"
                            :multiple="false"
                            :http-request="uploadListImageCoverRelation"
                            :file-list="detailParams.list_image_cover_file"
                            :show-file-list="false"
                            ref="uploadListImageCover"
                        >
                            <el-button type="primary" size="small">上传</el-button>
                        </el-upload>
                        <span class="f12 mg-l10 col999999">点击可删除图片</span>
                    </div>
                    <div class="album" v-if="detailParams.list_image_cover !== ''">
                        <img
                            :src="`${photoHost[1]}/${detailParams.list_image_cover}?x-oss-process=image/resize,w_200`"
                            @click="deleteListImageCover"/>
                    </div>
                </el-form-item>
                <el-form-item label="封面图">
                    <div class="flex-row flex-js-start">
                        <el-upload
                            action=""
                            :auto-upload="true"
                            :multiple="false"
                            :http-request="uploadImageCoverRelation"
                            :file-list="detailParams.image_cover_file"
                            :show-file-list="false"
                            ref="uploadImageCover"
                        >
                            <el-button type="primary" size="small">上传</el-button>
                        </el-upload>
                        <span class="f12 mg-l10 col999999">点击可删除图片</span>
                    </div>
                    <div class="album" v-if="detailParams.image_cover !== ''">
                        <img
                            :src="`${photoHost[1]}/${detailParams.image_cover}?x-oss-process=image/resize,w_200`"
                            @click="deleteImageCover"/>
                    </div>
                </el-form-item>
                <el-form-item label="封面 视频">
                    <div class="flex-row flex-js-start">
                        <el-upload
                            action=""
                            :auto-upload="true"
                            :multiple="false"
                            :http-request="uploadVideoFileFunction"
                            :file-list="detailParams.video_file"
                            :show-file-list="false"
                            ref="uploadVideoFileId"
                        >
                            <el-button type="primary" size="small">上传</el-button>
                        </el-upload>
                        <span class="f12 mg-l10 col999999">点击可删除视频</span>
                    </div>
                    <div class="album" v-if="detailParams.video_file_id !== ''">
                        <video width="400" :src="`${videoHost[1]}/${detailParams.video_file_id}`" controls="controls"
                               @click="deleteVideoFileId"></video>
                    </div>
                </el-form-item>
                <el-form-item label="内容编辑">
                    <weelv-editor v-model="detailParams.origin_content" channel="courseContent"
                                  @getStructuralContent="getStructuralContent"
                                  :wordsNumber="detailParams.wordsNumber"></weelv-editor>
                </el-form-item>
                <el-form-item label="关联款式">
                    <el-input class="w400" size="small" v-model="detailParams.attribute_ids" clearable
                              placeholder="请输入款式ID 多个用英文 , 隔开例：1,2"></el-input>
                </el-form-item>
                <el-form-item label="关联问答">
                    <el-input class="w400" size="small" v-model="detailParams.question_ids" clearable
                              placeholder="请输入问答ID 多个用英文 , 隔开例：1,2"></el-input>
                </el-form-item>
                <el-form-item label="排序" required>
                    <el-input-number v-model="detailParams.sort" :min="0" :max="999"></el-input-number>
                    <span class="col999999">倒叙展示，值越大展示在越前</span>
                </el-form-item>
                <el-form-item label="热门状态">
                    <el-switch :active-value="1" :inactive-value="0" v-model="detailParams.is_hot"></el-switch>
                </el-form-item>
                <el-form-item label="上线状态">
                    <el-switch :active-value="1" :inactive-value="2" v-model="detailParams.status"></el-switch>
                </el-form-item>
            </el-form>

            <div class="flex-row flex-js-spb">
                <el-button type="success" :disabled="saveDisabledMode"
                           @click="editType === 'add' ? addDetail() : updateDetail()">保 存
                </el-button>
            </div>
        </el-card>
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import {
    YOUNAIL_COURSE_CONTENT_GET_DETAIL_BY_ID,
    YOUNAIL_COURSE_CONTENT_GET_ADD_STATUS,
    YOUNAIL_COURSE_CONTENT_GET_UPD_STATUS,
    AUTH_GET_USER_INFO,

    YOUNAIL_QUESTIONS_GET_UPLOAD_IMG_STATUS,
    YOUNAIL_QUESTIONS_GET_UPLOAD_VIDEO_STATUS
} from '@/store/mutations';
import WeelvEditor from '@/components/weelvEditor/Editor';
import UserSearchInput from '@/components/user/userSearchInput.vue';
import pubStateField from "@/utils/stateField";

export default {
    components: {
        WeelvEditor,
        UserSearchInput,
    },
    computed: {
        ...mapState({
            userInfo: state => state.auth.userInfo,

            courseContentDetail: state => state.younailCourseContent.courseContentDetail,
            addCourseContentStatus: state => state.younailCourseContent.addCourseContentStatus,
            updCourseContentStatus: state => state.younailCourseContent.updCourseContentStatus,

            uploadImgFile: state => state.younailQuestion.uploadImgFile,
            uploadVideoFile: state => state.younailQuestion.uploadVideoFile
        })
    },
    mounted() {
        // 判断生成还是修改
        this.detailParams.id = this.$route.query.course_id;
        if (this.detailParams.id !== '') {
            this.editType = 'edit';
            this.getCourseContentDetail();
        } else {
            this.editType = 'add';
        }
    },
    watch: {
        courseContentDetail(value) {
            if (value) {
                this.detailParams.id = value.id;
                this.detailParams.title = value.title;
                this.detailParams.list_image_cover = value.list_image_cover;
                this.detailParams.image_cover = value.image_cover;
                this.detailParams.video_file_id = value.video_file_id;
                this.detailParams.type = value.type.toString();
                this.detailParams.status = value.status;
                this.detailParams.attribute_ids = value.attribute_ids;
                this.detailParams.question_ids = value.question_ids;
                this.detailParams.sort = value.sort;
                this.detailParams.is_hot = value.is_hot;

                if (value.structural_content) {
                    this.detailParams.origin_content = value.structural_content.origin_content;
                    this.detailParams.content = value.structural_content.content;

                }
            }
        },
    },
    data() {
        return {
            photoHost: pubStateField.photoHost,
            videoHost: pubStateField.videoHost,
            roleMap: pubStateField.roleMap,
            typeMap: pubStateField.younailField.courseTypeMap,

            editType: '',
            tagInputValue: '',

            detailParams: {
                id: '',
                title: '',
                type: '',
                status: 2,

                tagList: [],
                origin_content: '',
                content: [],
                attribute_ids: '',
                question_ids: '',
                is_hot: 0,
                sort: 0,

                list_image_cover_file: [],
                list_image_cover: '',

                image_cover_file: [],
                image_cover: '',

                video_file: [],
                video_file_id: ''
            },
            saveDisabledMode: false,
        };
    },
    methods: {
        ...mapActions({
            YOUNAIL_COURSE_CONTENT_GET_DETAIL_BY_ID,
            YOUNAIL_COURSE_CONTENT_GET_ADD_STATUS,
            YOUNAIL_COURSE_CONTENT_GET_UPD_STATUS,
            AUTH_GET_USER_INFO,
            YOUNAIL_QUESTIONS_GET_UPLOAD_IMG_STATUS,
            YOUNAIL_QUESTIONS_GET_UPLOAD_VIDEO_STATUS,
        }),
        // 获取教程内容详情
        getCourseContentDetail() {
            this[YOUNAIL_COURSE_CONTENT_GET_DETAIL_BY_ID]({
                type: YOUNAIL_COURSE_CONTENT_GET_DETAIL_BY_ID,
                params: {
                    id: this.detailParams.id
                }
            });
        },
        // 上传列表封面图
        async uploadListImageCoverRelation() {
            await this[YOUNAIL_QUESTIONS_GET_UPLOAD_IMG_STATUS]({
                type: YOUNAIL_QUESTIONS_GET_UPLOAD_IMG_STATUS,
                params: {
                    files: this.$refs.uploadListImageCover.$refs['upload-inner'].fileList
                }
            });
            this.detailParams.list_image_cover_file = [];

            if (this.uploadImgFile.length > 0) {
                this.detailParams.list_image_cover = this.uploadImgFile[0].file_id;
            }
        },
        // 删除列表封面图片
        deleteListImageCover() {
            this.$confirm('确认删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error'
            })
                .then(async () => {
                    this.detailParams.list_image_cover = '';
                })
                .catch(() => {
                });
        },
        // 上传封面图
        async uploadImageCoverRelation() {
            await this[YOUNAIL_QUESTIONS_GET_UPLOAD_IMG_STATUS]({
                type: YOUNAIL_QUESTIONS_GET_UPLOAD_IMG_STATUS,
                params: {
                    files: this.$refs.uploadImageCover.$refs['upload-inner'].fileList
                }
            });
            this.detailParams.image_cover_file = [];

            if (this.uploadImgFile.length > 0) {
                this.detailParams.image_cover = this.uploadImgFile[0].file_id;
            }
        },
        // 删除封面图片
        deleteImageCover() {
            this.$confirm('确认删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error'
            })
                .then(async () => {
                    this.detailParams.image_cover = '';
                })
                .catch(() => {
                });
        },

        // 上传视频
        async uploadVideoFileFunction() {
            await this[YOUNAIL_QUESTIONS_GET_UPLOAD_VIDEO_STATUS]({
                type: YOUNAIL_QUESTIONS_GET_UPLOAD_VIDEO_STATUS,
                params: {
                    files: this.$refs.uploadVideoFileId.$refs['upload-inner'].fileList
                }
            });
            this.detailParams.video_file = [];

            if (this.uploadVideoFile) {
                this.detailParams.video_file_id = this.uploadVideoFile.file_id;
            }
        },
        // 删除视频
        deleteVideoFileId() {
            this.$confirm('确认删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error'
            })
                .then(async () => {
                    this.detailParams.video_file_id = '';
                })
                .catch(() => {
                });
        },

        getStructuralContent(data) {
            this.detailParams.content = data;
        },
        async addDetail() {
            this.saveDisabledMode = true;

            await this[YOUNAIL_COURSE_CONTENT_GET_ADD_STATUS]({
                type: YOUNAIL_COURSE_CONTENT_GET_ADD_STATUS,
                params: {
                    title: this.detailParams.title,
                    list_image_cover: this.detailParams.list_image_cover,
                    image_cover: this.detailParams.image_cover,
                    video_file_id: this.detailParams.video_file_id,
                    attribute_ids: this.detailParams.attribute_ids,
                    question_ids: this.detailParams.question_ids,
                    sort: this.detailParams.sort,
                    is_hot: this.detailParams.is_hot,
                    type: this.detailParams.type,
                    status: this.detailParams.status,
                    origin_content: this.detailParams.origin_content,
                    content: JSON.stringify(this.detailParams.content),
                }
            });

            if (this.addCourseContentStatus) {
                this.$message.success('创建教程内容成功');
                this.$router.push({
                    name: 'younailCourseContentDetail',
                    query: {course_id: this.addCourseContentStatus.id}
                });
            }
            this.saveDisabledMode = false;
        },

        async updateDetail() {
            this.saveDisabledMode = true;

            await this[YOUNAIL_COURSE_CONTENT_GET_UPD_STATUS]({
                type: YOUNAIL_COURSE_CONTENT_GET_UPD_STATUS,
                params: {
                    id: this.detailParams.id,
                    title: this.detailParams.title,
                    list_image_cover: this.detailParams.list_image_cover,
                    image_cover: this.detailParams.image_cover,
                    video_file_id: this.detailParams.video_file_id,
                    attribute_ids: this.detailParams.attribute_ids,
                    question_ids: this.detailParams.question_ids,
                    sort: this.detailParams.sort,
                    is_hot: this.detailParams.is_hot,
                    type: this.detailParams.type,
                    status: this.detailParams.status,
                    origin_content: this.detailParams.origin_content,
                    content: JSON.stringify(this.detailParams.content),
                }
            });

            if (this.updCourseContentStatus) {
                this.$message.success('更新教程内容成功');
                this.getCourseContentDetail();
            }
            this.saveDisabledMode = false;
        },
        toCourseContentList() {
            this.$router.push({path: '/younailCourseContentList', query: {}});
        }
    }
};
</script>

<style lang="scss" scoped>
.page-header {
    padding-bottom: 15px;
    border-bottom: 1px solid #dcdfe6;
}
</style>
