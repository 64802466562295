<template>
    <div class="main-page">
        <el-card>
            <div class="page-header">
                <div>
                    <span>问题列表</span>
                    <span style="margin-left: 50px;font-size: 14px;font-weight: 700;">
                        该条件下问题总数：{{ questionList.total }}个
                    </span>
                </div>
                <div>
                    <el-button size="mini" type="primary" class="add-new-btn" @click="toQuestionDetail('')">
                        新建问题
                    </el-button>
                </div>
            </div>
            <div class="flex-row flex-js-start flex-al-center mg-t30">
                <el-input class="mg-r10 w80" v-model="question_id" size="small" placeholder="id"></el-input>
                <el-input class="mg-r10 w320" v-model="title" size="small" placeholder="请输入问题标题"></el-input>
                <el-select v-model="content_status" class="selection mg-r10 w120" size="mini" placeholder="回答状态"
                           @change="onSelectChange">
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="(item, index) in contentStatusMap" :label="item" :value="index"></el-option>
                </el-select>
                <el-select v-model="baidu_qa" class="selection mg-r10 w120" size="mini" placeholder="是否提交"
                           @change="onSelectChange">
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="(item, index) in baiduQaMap" :label="item" :value="index"></el-option>
                </el-select>
                <el-select v-model="baidu_feedback_status" class="selection mg-r10 w120" size="mini" placeholder="百度反馈"
                           @change="onSelectChange">
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="(item, index) in baiduFeedbackStatusMap" :label="item" :value="index"></el-option>
                </el-select>
                <el-select v-model="audit_status" class="selection mg-r10 w120" size="mini" placeholder="审核状态"
                           @change="onSelectChange">
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="(item, index) in auditStatusMap" :label="item" :value="index"></el-option>
                </el-select>
                <user-search-input class="mg-r10" :width="120" v-model="user_info"
                                   v-if="Array.isArray(userInfo.role_ids) && userInfo.role_ids.includes(roleMap.admin)"></user-search-input>
                <!--                <el-date-picker class="w120 mg-r10" v-model="month" type="month" value-format="yyyy-MM" size="mini" placeholder="选择月"></el-date-picker>-->
                <el-button type="primary" size="mini" @click="searchQuestion">筛选</el-button>
            </div>

            <el-table class="mg-t20" :highlight-current-row="true" :stripe="true" :data="questionList.data"
                      align="left">
                <el-table-column prop="id" label="问题ID" width="70"></el-table-column>
                <el-table-column prop="title" label="问题标题"></el-table-column>
                <el-table-column prop="domain" label="百度领域"></el-table-column>
                <el-table-column prop="domain" label="回答状态">
                    <template slot-scope="scope">
                        <el-tag :type="{0: 'danger', 1: 'primary', 2: 'success'}[scope.row.content_status]">
                            {{ contentStatusMap[scope.row.content_status] }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="baidu_qa" label="是否提交">
                    <template slot-scope="scope">
                        <el-tag :type="{1: 'success', 2: 'danger', 3: 'primary'}[scope.row.baidu_qa]">
                            {{ baiduQaMap[scope.row.baidu_qa] }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="timeliness" label="百度反馈" width="100">
                    <template slot-scope="scope">
                        <el-tag :type="{4: 'danger', 6: 'danger', 8: 'success'}[scope.row.baidu_feedback_status]">
                            {{ baiduFeedbackStatusMap[scope.row.baidu_feedback_status] }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="timeliness" label="审核状态" width="100">
                    <template slot-scope="scope">
                        <el-tag :type="{2: 'danger', 4: 'success'}[scope.row.audit_status]">
                            {{ auditStatusMap[scope.row.audit_status] }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column v-if="Array.isArray(userInfo.role_ids) && userInfo.role_ids.includes(roleMap.admin)"
                                 prop="user_name" label="运营人员">
                    <template slot-scope="scope">
                        <span v-if="scope.row.user_info">{{ scope.row.user_info.name }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作"
                                 :width="Array.isArray(userInfo.role_ids) && userInfo.role_ids.includes(roleMap.admin) ? 180 : 80">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary" @click="toQuestionDetail(scope.row.id)">详情</el-button>
                        <el-button v-if="Array.isArray(userInfo.role_ids) && userInfo.role_ids.includes(roleMap.admin)"
                                   size="mini" type="danger" @click="deleteQuestion(scope.row.id)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <div class="pagination">
                <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="questionList.total"
                    :page-size="pageSize"
                    :current-page="questionList.page"
                    @current-change="pageChange"
                ></el-pagination>
            </div>
        </el-card>

        <!-- <el-dialog :close-on-click-modal="false" title="分配问题" :visible.sync="distributionModal">
          <el-form :model="distributionModalParams">
            <el-form-item label="兼职" :label-width="'80px'">
              <el-select v-model="distributionModalParams.account_id" class="selection" size="mini" placeholder="兼职">
                <el-option label="无" value=""></el-option>
                <el-option :label="item.name" :value="item.user_id" v-for="item in userList"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="问题ID" :label-width="'80px'">
              <el-input type="textarea" size="small" placeholder="问题ID使用英文逗号(,)分割" v-model="distributionModalParams.question_ids"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button size="small" @click="distributionModal = false">取 消</el-button>
            <el-button size="small" type="primary" @click="distributionQuestions">保 存</el-button>
          </div>
        </el-dialog> -->
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import {
    CATURE_QUESTIONS_GET_LIST,
    CATURE_QUESTIONS_GET_DEL_STATUS,
    ROUTER_CATURE_QUESTIONS_INDEX
} from '@/store/mutations';
import pubStateField from '@/utils/stateField';
import UserSearchInput from '@/components/user/userSearchInput.vue';

export default {
    components: {UserSearchInput},
    computed: {
        ...mapState({
            userInfo: state => state.auth.userInfo,
            routerParams: state => state.routerParams.catureQuestionIndex,
            questionList: state => state.catureQuestion.questionList,
            delQuestionStatus: state => state.catureQuestion.delQuestionStatus
        })
    },
    mounted() {
        this.getQuestionList();
    },
    watch: {
        routerParams: {
            handler() {
                this.page = this.routerParams.page;
                this.pageSize = this.routerParams.pageSize;
                this.title = this.routerParams.title;
                this.content_status = this.routerParams.content_status;
                this.baidu_qa = this.routerParams.baidu_qa;
                this.baidu_feedback_status = this.routerParams.baidu_feedback_status;
                this.audit_status = this.routerParams.audit_status;
                this.user_info.id = this.routerParams.user_info_id;
                this.user_info.value = this.routerParams.user_info_value;
                this.month = this.routerParams.month;
            },
            deep: true,
            immediate: true
        },

        question_id(value) {
            if (value === '') {
                this.question_id = null;
                this.getQuestionList();
            }
        },
        title(value) {
            if (value === '') {
                this.title = null;
                this.getQuestionList();
            }
        },
        type(value) {
            if (value === '') {
                this.type = null;
                this.getQuestionList();
            }
        },
        content_status(value) {
            if (value === '') {
                this.content_status = null;
                this.getQuestionList();
            }
        },
        baidu_qa(value) {
            if (value === '') {
                this.baidu_qa = null;
                this.getQuestionList();
            }
        },
        baidu_feedback_status(value) {
            if (value === '') {
                this.baidu_qa = null;
                this.getQuestionList();
            }
        },
        audit_status(value) {
            if (value === '') {
                this.audit_status = null;
                this.getQuestionList();
            }
        },
        month(value) {
            if (value === '') {
                this.month = null;
                this.getQuestionList();
            }
        },
        distributionModal(value) {
            if (!value) {
                this.distributionModalParams = {
                    account_id: '',
                    question_ids: ''
                };
            }
        }
    },
    data() {
        return {
            photoHost: pubStateField.photoHost,
            roleMap: pubStateField.roleMap,
            domainMap: pubStateField.catureField.domainMap,
            typeMap: pubStateField.catureField.typeMap,
            baiduQaMap: pubStateField.catureField.baiduQaMap,
            contentStatusMap: pubStateField.catureField.contentStatusMap,
            auditStatusMap: pubStateField.catureField.auditStatusMap,
            baiduSubmitStatusMap: pubStateField.catureField.baiduSubmitStatusMap,
            baiduFeedbackStatusMap: pubStateField.catureField.baiduFeedbackStatusMap,
            baiduStatusMap: pubStateField.catureField.baiduStatusMap,
            syncStatusMap: pubStateField.catureField.syncStatusMap,

            type: '1',
            page: 1,
            pageSize: 20,
            question_id: '',
            title: '',
            content_status: null,
            baidu_feedback_status: null,
            baidu_qa: null,
            audit_status: null,
            user_info: {id: '', value: ''},
            distributionModal: false,
            distributionModalParams: {
                account_id: '',
                question_ids: ''
            },
            month: '',
        };
    },
    methods: {
        ...mapActions({
            CATURE_QUESTIONS_GET_LIST,
            CATURE_QUESTIONS_GET_DEL_STATUS,
            ROUTER_CATURE_QUESTIONS_INDEX
        }),
        // 获取问题列表
        getQuestionList() {
            this[CATURE_QUESTIONS_GET_LIST]({
                type: CATURE_QUESTIONS_GET_LIST,
                params: {
                    page: this.page,
                    page_size: this.pageSize,
                    question_id: this.question_id,
                    title: this.title,
                    content_status: this.content_status,
                    baidu_qa: this.baidu_qa,
                    baidu_feedback_status: this.baidu_feedback_status,
                    audit_status: this.audit_status,
                    user_id: this.user_info.id,
                    month: this.month,
                }
            });

            this[ROUTER_CATURE_QUESTIONS_INDEX]({
                type: ROUTER_CATURE_QUESTIONS_INDEX,
                params: {
                    page: this.page,
                    page_size: this.pageSize,
                    question_id: this.question_id,
                    title: this.title,
                    content_status: this.content_status,
                    baidu_qa: this.baidu_qa,
                    baidu_feedback_status: this.baidu_feedback_status,
                    audit_status: this.audit_status,
                    user_info_id: this.user_info.id,
                    user_info_value: this.user_info.value,
                    month: this.month,
                }
            });
        },
        // 删除问题
        async deleteQuestion(question_id) {
            this.$confirm('确认删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error'
            })
                .then(async () => {
                    await this[CATURE_QUESTIONS_GET_DEL_STATUS]({
                        type: CATURE_QUESTIONS_GET_DEL_STATUS,
                        params: {
                            id: question_id
                        }
                    });
                    if (this.delQuestionStatus) {
                        this.$message.success('删除成功');
                        this.getQuestionList();
                    }
                })
                .catch(() => {
                });
        },

        // 跳转问题详情
        toQuestionDetail(question_id) {
            this.$router.push({name: 'catureQuestionsAnswer', query: {question_id: question_id}});
        },
        // 分页
        pageChange(page) {
            this.page = page;
            this.getQuestionList();
        },
        //导出问题
        exportQuestion() {
            let url = `/question/export?question_id=${this.question_id || ''}&title=${this.title || ''}&mddid=${this.mdd.mddid || ''}&tag_id=${this.tag.tag_id || ''}&status=${this
                .status || ''}&request_status=${this.request_status || ''}&account_id=${this.account_id || ''}&need_describe=${this.need_describe || ''}&type=${this.type ||
            ''}&month=${this.month || ''}`;
            window.open(url, '_blank'); // 新窗口打开外链接
        },
        async searchQuestion() {
            this.page = 1;
            this.getQuestionList();
        },
        async onSelectChange() {
            this.page = 1;
            this.getQuestionList();
        },
    }
};
</script>

<style lang="scss" scoped>
.el-table {
    text-align: left;
    line-height: 40px;

    .cell {
        line-height: 32px;
    }
}
</style>
