<template>
	<div class="login">
		<el-card class="login-box">
			<div slot="header" class="title">
				<span class="f24">{{ title }}</span>
			</div>
			<el-input size="small" class="email-input" v-model="phone" placeholder="请输入手机号">
				<template slot="prepend">
					<i class="el-icon-mobile-phone"></i>
				</template>
			</el-input>
			<div class="login-box-line mg-t15">
				<el-input size="small" class="code-input" v-model="password" placeholder="请输入验证码">
					<template slot="prepend">
						<i class="el-icon-lock" style="width:12px"></i>
					</template>
				</el-input>
				<el-button class="code-btn" size="small" type="primary" @click="getCode" :disable="btnDisable">验证码</el-button>
			</div>
			<el-button class="login-btn mg-t15" size="small" type="primary" @click="login">登录</el-button>
		</el-card>
	</div>
</template>

<script>
export default {
	mounted() {
		if (this.$route.query.success && this.$route.query.message) {
			if (this.$route.query.success === '1') {
				this.$message.success(this.$route.query.message);
			} else if (this.$route.query.success === '0') {
				this.$message.error(this.$route.query.message);
			}
		}
	},
	data() {
		return {
			title: '请登陆 wee 内容管理系统',
			phone: '',
			password: '',
			btnDisable: false,
		};
	},
	methods: {
		getCode() {
			axios.post('/auth/sendLoginCode', {
				mobile: this.phone
			}).then(response => {
				if (response.data.request_code === 0) {
					this.$message({
						message: '验证码发送成功',
						type: 'success'
					});
					this.btnDisable = true;
				} else {
					this.$message.error(response.data.message);
				}
			});
		},
		login() {
			axios.post('/auth/login', {
				mobile: this.phone,
				code: this.password
			}).then(response => {
				if (response.data.request_code === 0) {
					this.$message.success('登陆成功');
					this.$router.push({ name: 'home' })
					window.location.reload()
				} else {
					this.$message.error(response.data.message);
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/login.scss';
</style>
