<template>
    <div class="main-page">
        <el-card>
            <div class="page-header">
				<span v-if="editType === 'edit'">
					<span class="f16">精选内容详情</span>
					<span class="f12 mg-l10">精选内容ID{{ detailParams.id }}</span>
				</span>
                <span v-else><span class="f16">创建精选内容</span></span>
                <el-button size="mini" type="primary" class="add-new-btn" @click="toQualityContentList">返回</el-button>
            </div>
            <el-form class="mg-t30" :label-width="'100px'">
                <el-form-item label="标题">
                    <el-input class="w400" size="small" v-model="detailParams.title" clearable
                              placeholder="输入标题不能超过128个字符"></el-input>
                </el-form-item>

                <el-form-item label="副标题">
                    <el-input class="w400" size="small" v-model="detailParams.sub_title" clearable
                              placeholder="输入标题不能超过64个字符"></el-input>
                </el-form-item>
                <el-form-item label="封面图">
                    <div class="flex-row flex-js-start">
                        <el-upload
                            action=""
                            :auto-upload="true"
                            :multiple="false"
                            :http-request="uploadImageCoverRelation"
                            :file-list="detailParams.image_cover_file"
                            :show-file-list="false"
                            ref="uploadImageCover"
                        >
                            <el-button type="primary" size="small">上传</el-button>
                        </el-upload>
                        <span class="f12 mg-l10 col999999">点击可删除图片</span>
                    </div>
                    <div class="album" v-if="detailParams.image_cover !== ''">
                        <img
                            :src="`${photoHost[1]}/${detailParams.image_cover}?x-oss-process=image/resize,h_200`"
                            @click="deleteImageCover"/>
                    </div>
                </el-form-item>
                <el-form-item label="封面 视频">
                    <div class="flex-row flex-js-start">
                        <el-upload
                            action=""
                            :auto-upload="true"
                            :multiple="false"
                            :http-request="uploadVideoFileFunction"
                            :file-list="detailParams.video_file"
                            :show-file-list="false"
                            ref="uploadVideoFileId"
                        >
                            <el-button type="primary" size="small">上传</el-button>
                        </el-upload>
                        <span class="f12 mg-l10 col999999">点击可删除视频</span>
                    </div>
                    <div class="album" v-if="detailParams.video_file_id !== ''">
                        <video :src="`${videoHost[1]}/${detailParams.video_file_id}`" controls="controls"
                               @click="deleteVideoFileId"></video>
                    </div>
                </el-form-item>
                <el-form-item label="标签">
                    <div class="flex-column">
                        <el-input
                            class="w400"
                            v-model="tagInputValue"
                            size="small"
                            @focus="tagInputConfirm"
                            @keyup.enter.native="tagInputConfirm"
                            placeholder="输入标签名称,回车增加一个标签"
                        ></el-input>
                        <div class="flex-row flex-js-start">
                            <el-tag
                                class="mg-r10"
                                size="small"
                                closable
                                :disable-transitions="false"
                                v-for="(item, index) in detailParams.tagList"
                                :key="index"
                                @close="deleteTag(index)"
                            >
                                {{ item.name }}
                            </el-tag>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="内容编辑">
                    <weelv-editor v-model="detailParams.origin_content" channel="qualityContent"
                                  @getStructuralContent="getStructuralContent"
                                  :wordsNumber="detailParams.wordsNumber"></weelv-editor>
                </el-form-item>
                <el-form-item label="是否推荐">
                    <el-switch :active-value="1" :inactive-value="0" v-model="detailParams.is_recommend"></el-switch>
                </el-form-item>
                <el-form-item label="上线状态">
                    <el-switch :active-value="1" :inactive-value="2" v-model="detailParams.status"></el-switch>
                </el-form-item>
            </el-form>

            <div class="flex-row flex-js-spb">
                <el-button type="success" :disabled="saveDisabledMode"
                           @click="editType === 'add' ? addDetail() : updateDetail()">保 存
                </el-button>
            </div>
        </el-card>
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import {
    YOUNAIL_QUALITY_CONTENT_GET_DETAIL_BY_ID,
    YOUNAIL_QUALITY_CONTENT_GET_ADD_TAG_STATUS,
    YOUNAIL_QUALITY_CONTENT_GET_ADD_STATUS,
    YOUNAIL_QUALITY_CONTENT_GET_UPD_STATUS,
    AUTH_GET_USER_INFO,

    YOUNAIL_QUESTIONS_GET_UPLOAD_IMG_STATUS,
    YOUNAIL_QUESTIONS_GET_UPLOAD_VIDEO_STATUS
} from '@/store/mutations';
import WeelvEditor from '@/components/weelvEditor/Editor';
import UserSearchInput from '@/components/user/userSearchInput.vue';
import pubStateField from "@/utils/stateField";

export default {
    components: {
        WeelvEditor,
        UserSearchInput,
    },
    computed: {
        ...mapState({
            userInfo: state => state.auth.userInfo,

            qualityContentDetail: state => state.younailQualityContent.qualityContentDetail,
            addQualityContentStatus: state => state.younailQualityContent.addQualityContentStatus,
            updQualityContentStatus: state => state.younailQualityContent.updQualityContentStatus,
            tagAddTagStatus: state => state.younailQualityContent.tagAddTagStatus,

            uploadImgFile: state => state.younailQuestion.uploadImgFile,
            uploadVideoFile: state => state.younailQuestion.uploadVideoFile
        })
    },
    mounted() {
        // 判断生成还是修改
        this.detailParams.id = this.$route.query.content_id;
        if (this.detailParams.id !== '') {
            this.editType = 'edit';
            this.getQualityContentDetail();
        } else {
            this.editType = 'add';
        }
    },
    watch: {
        qualityContentDetail(value) {
            if (value) {
                this.detailParams.id = value.id;
                this.detailParams.title = value.title;
                this.detailParams.sub_title = value.sub_title;
                this.detailParams.image_cover = value.image_cover;
                this.detailParams.video_file_id = value.video_file_id;
                this.detailParams.is_recommend = value.is_recommend;
                this.detailParams.status = value.status;

                this.detailParams.tagList = [];
                if (value.tags) {
                    value.tags.forEach(item => {
                        let tagObj = {};
                        tagObj.id = item.id;
                        tagObj.name = item.name;
                        this.detailParams.tagList.push(tagObj);
                    });
                }

                if (value.structural_content) {
                    this.detailParams.origin_content = value.structural_content.origin_content;
                    this.detailParams.content = value.structural_content.content;

                }
            }
        },
    },
    data() {
        return {
            photoHost: pubStateField.photoHost,
            videoHost: pubStateField.videoHost,
            roleMap: pubStateField.roleMap,

            editType: '',
            tagInputValue: '',

            detailParams: {
                id: '',
                title: '',
                sub_title: '',
                is_recommend: 0,
                status: 2,

                tagList: [],
                origin_content: '',
                content: [],

                image_cover_file: [],
                image_cover: '',

                video_file: [],
                video_file_id: ''
            },
            saveDisabledMode: false,
        };
    },
    methods: {
        ...mapActions({
            YOUNAIL_QUALITY_CONTENT_GET_DETAIL_BY_ID,
            YOUNAIL_QUALITY_CONTENT_GET_ADD_TAG_STATUS,
            YOUNAIL_QUALITY_CONTENT_GET_ADD_STATUS,
            YOUNAIL_QUALITY_CONTENT_GET_UPD_STATUS,
            AUTH_GET_USER_INFO,
            YOUNAIL_QUESTIONS_GET_UPLOAD_IMG_STATUS,
            YOUNAIL_QUESTIONS_GET_UPLOAD_VIDEO_STATUS,
        }),
        // 获取精选内容详情
        getQualityContentDetail() {
            this[YOUNAIL_QUALITY_CONTENT_GET_DETAIL_BY_ID]({
                type: YOUNAIL_QUALITY_CONTENT_GET_DETAIL_BY_ID,
                params: {
                    id: this.detailParams.id
                }
            });
        },
        // 添加标签
        async tagInputConfirm() {
            if (this.tagInputValue) {
                await this[YOUNAIL_QUALITY_CONTENT_GET_ADD_TAG_STATUS]({
                    type: YOUNAIL_QUALITY_CONTENT_GET_ADD_TAG_STATUS,
                    params: {
                        name: this.tagInputValue
                    }
                });
                if (this.tagAddTagStatus) {
                    let tagIdList = this.detailParams.tagList.map(this.returnTagList);
                    // 判断是否已经包含此id
                    if (tagIdList.indexOf(this.tagAddTagStatus.id) < 0) {
                        let obj = {};
                        obj.id = this.tagAddTagStatus.id;
                        obj.name = this.tagAddTagStatus.name;
                        this.detailParams.tagList.push(obj);
                    }
                    this.tagInputValue = '';
                }
            }
        },
        // 删除标签
        deleteTag(x) {
            this.detailParams.tagList.splice(x, 1);
        },
        // 返回新数组
        returnTagList(value) {
            return value.id;
        },
        // 上传封面图
        async uploadImageCoverRelation() {
            await this[YOUNAIL_QUESTIONS_GET_UPLOAD_IMG_STATUS]({
                type: YOUNAIL_QUESTIONS_GET_UPLOAD_IMG_STATUS,
                params: {
                    files: this.$refs.uploadImageCover.$refs['upload-inner'].fileList
                }
            });
            this.detailParams.image_cover_file = [];

            if (this.uploadImgFile.length > 0) {
                this.detailParams.image_cover = this.uploadImgFile[0].file_id;
            }
        },
        // 删除封面图片
        deleteImageCover() {
            this.$confirm('确认删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error'
            })
                .then(async () => {
                    this.detailParams.image_cover = '';
                })
                .catch(() => {
                });
        },

        // 上传视频
        async uploadVideoFileFunction() {
            await this[YOUNAIL_QUESTIONS_GET_UPLOAD_VIDEO_STATUS]({
                type: YOUNAIL_QUESTIONS_GET_UPLOAD_VIDEO_STATUS,
                params: {
                    files: this.$refs.uploadVideoFileId.$refs['upload-inner'].fileList
                }
            });
            this.detailParams.video_file = [];

            if (this.uploadVideoFile) {
                this.detailParams.video_file_id = this.uploadVideoFile.file_id;
            }
        },
        // 删除视频
        deleteVideoFileId() {
            this.$confirm('确认删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error'
            })
                .then(async () => {
                    this.detailParams.video_file_id = '';
                })
                .catch(() => {
                });
        },

        getStructuralContent(data) {
            this.detailParams.content = data;
        },
        async addDetail() {
            this.saveDisabledMode = true;

            let tagIds = [];
            this.detailParams.tagList.forEach(item => {
                tagIds.push(item.id);
            });

            await this[YOUNAIL_QUALITY_CONTENT_GET_ADD_STATUS]({
                type: YOUNAIL_QUALITY_CONTENT_GET_ADD_STATUS,
                params: {
                    title: this.detailParams.title,
                    sub_title: this.detailParams.sub_title,
                    image_cover: this.detailParams.image_cover,
                    video_file_id: this.detailParams.video_file_id,
                    is_recommend: this.detailParams.is_recommend,
                    status: this.detailParams.status,
                    tag_ids: tagIds.join(','),
                    origin_content: this.detailParams.origin_content,
                    content: JSON.stringify(this.detailParams.content),
                }
            });

            if (this.addQualityContentStatus) {
                this.$message.success('创建精选内容成功');
                this.$router.push({
                    name: 'younailQualityContentDetail',
                    query: {content_id: this.addQualityContentStatus.id}
                });
            }
            this.saveDisabledMode = false;
        },

        async updateDetail() {
            this.saveDisabledMode = true;

            let tagIds = [];
            this.detailParams.tagList.forEach(item => {
                tagIds.push(item.id);
            });

            await this[YOUNAIL_QUALITY_CONTENT_GET_UPD_STATUS]({
                type: YOUNAIL_QUALITY_CONTENT_GET_UPD_STATUS,
                params: {
                    id: this.detailParams.id,
                    title: this.detailParams.title,
                    sub_title: this.detailParams.sub_title,
                    image_cover: this.detailParams.image_cover,
                    video_file_id: this.detailParams.video_file_id,
                    is_recommend: this.detailParams.is_recommend,
                    status: this.detailParams.status,
                    tag_ids: tagIds.join(','),
                    origin_content: this.detailParams.origin_content,
                    content: JSON.stringify(this.detailParams.content),
                }
            });

            if (this.updQualityContentStatus) {
                this.$message.success('更新精选内容成功');
                this.getQualityContentDetail();
            }
            this.saveDisabledMode = false;
        },
        toQualityContentList() {
            this.$router.push({path: '/younailQualityContentList', query: {}});
        }
    }
};
</script>

<style lang="scss" scoped>
.page-header {
    padding-bottom: 15px;
    border-bottom: 1px solid #dcdfe6;
}
</style>
