<template>
    <div class="main-page">
        <el-card>
            <div class="page-header">
				<span v-if="editType === 'edit'">
					<span class="f16">问题详情</span>
					<span class="f12 mg-l10">问题ID{{ answerParams.id }}</span>

					<span class="f12 col499E80FF" v-if="answerParams.type in typeMap">{{
                            typeMap[answerParams.type]
                        }}</span>

					<span class="f12 col499E80FF"
                          v-if="answerParams.baidu_feedback_status in baiduFeedbackStatusMap && answerParams.baidu_feedback_status !== 0">
						<el-tag size="small">{{ baiduFeedbackStatusMap[answerParams.baidu_feedback_status] }}</el-tag>
						<span>{{ answerParams.baidu_feedback }}</span>
					</span>
				</span>
                <span v-else><span class="f16">创建问题</span></span>
                <el-button size="mini" type="primary" class="add-new-btn" @click="toQuestionList">返回</el-button>
            </div>
            <el-form class="mg-t30" :label-width="'100px'">
                <el-form-item label="问题名称">
                    <el-input class="w400" size="small" v-model="answerParams.title" clearable></el-input>
                </el-form-item>
                <el-form-item label="是否提交">
                    <el-radio label="1" v-model="answerParams.baidu_qa">需要提交</el-radio>
                    <el-radio label="2" v-model="answerParams.baidu_qa">无需提交</el-radio>
                    <el-radio label="3" v-model="answerParams.baidu_qa">暂不提交</el-radio>
                </el-form-item>
                <el-form-item label="专业问答领域" v-if="['1', '3'].includes(answerParams.baidu_qa)">
                    <el-select v-model="answerParams.domain" placeholder="请选择">
                        <el-option v-for="item in domainMap" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="headline" v-if="['1', '3'].includes(answerParams.baidu_qa)">
                    <div class="flex-column">
                        <el-input class="w400" size="small" v-model="answerParams.headline" clearable
                                  placeholder="请输入百度headline名称"></el-input>
                        <el-input
                            class="w400"
                            size="small"
                            v-for="(item, index) in answerParams.extra_headline"
                            v-model="answerParams.extra_headline[index]"
                            :key="index"
                            clearable
                            placeholder="请输入百度扩展headline名称"
                        ></el-input>
                        <div class="flex-row flex-js-start">
                            <el-button type="primary" size="small" @click="addHeadline">+添加拓展headline</el-button>
                            <el-button size="small" @click="deleteHeadline">删除</el-button>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="运营人员"
                              v-if="Array.isArray(userInfo.role_ids) && userInfo.role_ids.includes(roleMap.admin)">
                  <user-search-input :width="400" v-model="answerParams.user_info"></user-search-input>
                </el-form-item>
                <el-form-item label="标签">
                    <div class="flex-column">
                        <el-input
                            class="w400"
                            v-model="tagInputValue"
                            size="small"
                            @focus="tagInputConfirm"
                            @keyup.enter.native="tagInputConfirm"
                            placeholder="输入标签名称,回车增加一个标签"
                        ></el-input>
                        <div class="flex-row flex-js-start">
                            <el-tag
                                class="mg-r10"
                                size="small"
                                closable
                                :disable-transitions="false"
                                v-for="(item, index) in answerParams.tagList"
                                :key="index"
                                @close="deleteTag(index)"
                            >
                                {{ item.name }}
                            </el-tag>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="摘要">
                    <div class="flex-row flex-js-start">
                        <el-input
                            type="textarea"
                            class="w400"
                            size="small"
                            placeholder="长度不能超过512个字符"
                            v-model="answerParams.description"
                            maxlength="512"
                            :autosize="{ minRows: 7 }"
                            show-word-limit
                        ></el-input>
                        <div class="w280 lh-24 mg-l20 flex-column">
                            <span>段落之间若需要换行，需使用百度规则:</span>
                            <span>若无序分段开头使用 <&list>;</span>
                            <span>若有序分段开头使用 <&order>;</span>
                            <span>若存在层级开头使用<&abs>;</span>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="提交封面图">
                    <div class="flex-row flex-js-start">
                        <el-upload
                            action=""
                            :auto-upload="true"
                            :multiple="false"
                            :http-request="uploadBaiduPhotoRelation"
                            :file-list="answerParams.baiduPhotoFile"
                            :show-file-list="false"
                            ref="uploadBaiduPhoto"
                        >
                            <el-button type="primary" size="small">上传（750*370）</el-button>
                        </el-upload>
                        <span class="f12 mg-l10 col999999">点击可删除图片</span>
                    </div>
                    <div
                        class="album"
                        @click="deleteBaiduImg"
                        v-if="answerParams.baiduPhotoFileInfo && answerParams.baiduPhotoFileInfo.file_id && answerParams.baiduPhotoFileInfo.file_id !== ''"
                    >
                        <img
                            :src="`${photoHost[1]}/${answerParams.baiduPhotoFileInfo.file_id}?x-oss-process=image/resize,h_200`"/>
                    </div>
                </el-form-item>
                <el-form-item label="默认封面图">
                    <div class="flex-row flex-js-start">
                        <el-upload
                            action=""
                            :auto-upload="true"
                            :multiple="false"
                            :http-request="uploadDefaultPhotoRelation"
                            :file-list="answerParams.defaultPhotoFile"
                            :show-file-list="false"
                            ref="uploadDefaultPhoto"
                        >
                            <el-button type="primary" size="small">上传</el-button>
                        </el-upload>
                        <span class="f12 mg-l10 col999999">点击可删除图片</span>
                    </div>
                    <div class="album" v-if="answerParams.defaultPhotoFileId !== ''">
                        <img
                            :src="`${photoHost[1]}/${answerParams.defaultPhotoFileId}?x-oss-process=image/resize,h_200`"
                            @click="deleteDefaultImg"/>
                    </div>
                </el-form-item>
                <el-form-item label="时效性问题">
                    <el-switch :active-value="1" :inactive-value="0" v-model="answerParams.timeliness"></el-switch>
                </el-form-item>
                <el-form-item label="资源有效期" v-if="answerParams.timeliness === 1">
                    <el-date-picker class="w400" v-model="answerParams.expiration_date" type="date"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择日期"></el-date-picker>
                </el-form-item>

                <el-form-item label="短答案">
                    <div class="flex-row flex-js-start">
                        <el-input
                            type="textarea"
                            class="w400"
                            size="small"
                            placeholder="长度不能超过15个字符"
                            v-model="answerParams.short_content"
                            maxlength="15"
                            :autosize="{ minRows: 2 }"
                            show-word-limit
                        ></el-input>
                    </div>
                </el-form-item>
                <!--                <el-form-item label="质量分">-->
                <!--                    <el-input class="w400" size="small" clearable placeholder=""></el-input>-->
                <!--                </el-form-item>-->
                <el-form-item label="内容编辑">
                    <weelv-editor v-model="answerParams.origin_content" channel="question"
                                  @getStructuralContent="getStructuralContent"
                                  :wordsNumber="answerParams.wordsNumber"></weelv-editor>
                </el-form-item>
                <el-form-item label="是否推荐">
                    <el-switch :active-value="1" :inactive-value="0" v-model="answerParams.is_recommend"></el-switch>
                </el-form-item>
                <el-form-item label="关联款式">
                    <el-input class="w400" size="small" v-model="answerParams.attribute_ids" clearable
                              placeholder="请输入款式ID 多个用英文 , 隔开例：1,2"></el-input>
                </el-form-item>
                <el-form-item label="上线状态">
                    <el-switch :active-value="1" :inactive-value="2" v-model="answerParams.status"></el-switch>
                </el-form-item>
            </el-form>

            <div
                v-if="
					Array.isArray(userInfo.role_ids) && (userInfo.role_ids.includes(roleMap.admin) || (userInfo.role_ids.includes(roleMap.younail_normal) && ['2', '3'].includes(answerParams.audit_status)))
				"
            >
                <div class="page-header mg-t20"><span class="f16">内容审核</span></div>
                <el-form class="mg-t30" :label-width="'100px'">
                    <el-form-item label="审核状态">
                        <template slot-scope="scope">
                            <el-tag
                                :type="{ 0: 'primary', 1: 'primary', 2: 'danger', 3: 'primary', 4: 'success' }[answerParams.audit_status]">
                                {{ auditStatusMap[answerParams.audit_status] }}
                            </el-tag>
                        </template>
                    </el-form-item>
                    <el-form-item label="审核结果">
                        <el-select v-model="answerParams.audit_status_result" placeholder="请选择">
                            <el-option v-for="(item, index) in userAuditStatusMap" :key="index" :label="item"
                                       :value="index"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="审核反馈" v-if="['2', '3'].includes(answerParams.audit_status_result)">
                        <el-input
                            size="small"
                            v-model="answerParams.checked_desc"
                            style="width: 400px;"
                            :autosize="{ minRows: 6 }"
                            type="textarea"
                            placeholder="暂无反馈"
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>

            <div class="flex-row flex-js-spb">
                <el-button type="success" :disabled="saveDisabledMode"
                           @click="editType === 'add' ? addAnswer() : updateAnswer()">保 存
                </el-button>
                <el-button type="primary" @click="nextQuestion">下一篇</el-button>
            </div>
        </el-card>
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import {
  AUTH_GET_USER_INFO,
  YOUNAIL_QUESTIONS_GET_ADD_STATUS,
  YOUNAIL_QUESTIONS_GET_ADD_TAG_STATUS,
  YOUNAIL_QUESTIONS_GET_DETAIL_BY_ID,
  YOUNAIL_QUESTIONS_GET_UPD_STATUS,
  YOUNAIL_QUESTIONS_GET_UPLOAD_IMG_STATUS
} from '@/store/mutations';
import WeelvEditor from '@/components/weelvEditor/Editor';
import UserSearchInput from '@/components/user/userSearchInput.vue';
import {deepCopy} from '@/utils/deepCopy';
import stateField from '@/utils/stateField';
import pubStateField from '@/utils/stateField';

export default {
    components: {
        WeelvEditor,
        UserSearchInput,
    },
    computed: {
        ...mapState({
            userInfo: state => state.auth.userInfo,

            questionDetail: state => state.younailQuestion.questionDetail,
            addQuestionStatus: state => state.younailQuestion.addQuestionStatus,
            updQuestionStatus: state => state.younailQuestion.updQuestionStatus,
            tagAddTagStatus: state => state.younailQuestion.tagAddTagStatus,
            uploadImgFile: state => state.younailQuestion.uploadImgFile,
            routerParams: state => state.routerParams.younailQuestionIndex,
        })
    },
    mounted() {
        // 判断生成还是修改
        this.answerParams.id = this.$route.query.question_id;
        if (this.answerParams.id !== '') {
            this.editType = 'edit';
            this.getQuestionDetail();
        } else {
            this.editType = 'add';
        }
        this.setUserAuditStatusMap();
    },
    watch: {
        questionDetail(value) {
            if (value) {
                this.answerParams.id = value.id;
                this.answerParams.title = value.title;
                this.answerParams.domain = value.domain;
                this.answerParams.baidu_qa = value.baidu_qa.toString();
                this.answerParams.headline = value.headline;
                this.answerParams.extra_headline = [];
                if (Array.isArray(value.extra_headline)) {
                    value.extra_headline.forEach(item => {
                        this.answerParams.extra_headline.push(item);
                    });
                }

                if (value.user_info) {
                    this.answerParams.user_info.id = value.user_info.id;
                    this.answerParams.user_info.value = value.user_info.name;
                }

                this.answerParams.tagList = [];
                if (value.tag_relation) {
                    value.tag_relation.forEach(item => {
                        if (item.tag_info) {
                            let tagObj = {};
                            tagObj.id = item.tag_info.id;
                            tagObj.name = item.tag_info.name;
                            this.answerParams.tagList.push(tagObj);
                        }
                    });
                }

                this.answerParams.description = value.description;
                this.answerParams.baiduPhotoFileInfo.file_id = value.image_info.file_id;
                this.answerParams.defaultPhotoFileId = value.image_cover;
                this.answerParams.short_content = value.short_content;
                this.answerParams.origin_content = value.origin_content;
                this.answerParams.content = value.struct_content;

                this.answerParams.timeliness = value.timeliness;
                this.answerParams.expiration_date = value.expiration_date;
                this.answerParams.type = value.type.toString();
                this.answerParams.content_status = value.content_status;
                this.answerParams.audit_status = value.audit_status.toString();
                this.answerParams.audit_status_result = value.audit_status.toString();
                this.answerParams.baidu_submit_status = value.baidu_submit_status;
                this.answerParams.baidu_feedback_status = value.baidu_feedback_status;
                this.answerParams.baidu_feedback = value.baidu_feedback;
                this.answerParams.baidu_status = value.baidu_status;
                this.answerParams.sync_status = value.sync_status;
                this.answerParams.is_recommend = value.is_recommend;
                this.answerParams.status = value.status;
                this.answerParams.attribute_ids = value.attribute_ids;

                this.next_question_id = value.next_id;
            }
        },
    },
    data() {
        return {
            photoHost: pubStateField.photoHost,
            roleMap: pubStateField.roleMap,
            domainMap: stateField.younailField.domainMap,
            typeMap: stateField.younailField.typeMap,
            contentStatusMap: stateField.younailField.contentStatusMap,
            auditStatusMap: stateField.younailField.auditStatusMap,
            baiduSubmitStatusMap: stateField.younailField.baiduSubmitStatusMap,
            baiduFeedbackStatusMap: stateField.younailField.baiduFeedbackStatusMap,
            baiduStatusMap: stateField.younailField.baiduStatusMap,
            syncStatusMap: stateField.younailField.syncStatusMap,

            editType: '',
            tagInputValue: '',
            userAuditStatusMap: {},

            answerParams: {
              id: '',
              title: '',
              domain: '',
              baidu_qa: '2',
              headline: '',
              description: '',
              timeliness: 0,
              expiration_date: '',
              extra_headline: [],
              user_info: {id: '', value: ''},
              tagList: [],
              checked_desc: '',
              baidu_feedback: '',
              origin_content: '',
                short_content: '',
                content: [],
                type: 0,
                content_status: 0,
                audit_status: '1',
                audit_status_result: '1',
                baidu_submit_status: 0,
                baidu_feedback_status: 0,
                baidu_status: 0,
                sync_status: 0,
                is_recommend: 0,
                status: 2,
                attribute_ids: '',

                baiduPhotoFile: [],
                baiduPhotoFileInfo: {},
                defaultPhotoFile: [],
                defaultPhotoFileId: ''
            },
            saveDisabledMode: false,

            next_question_id: ''
        };
    },
    methods: {
        ...mapActions({
            YOUNAIL_QUESTIONS_GET_DETAIL_BY_ID,
            YOUNAIL_QUESTIONS_GET_ADD_TAG_STATUS,
            YOUNAIL_QUESTIONS_GET_UPLOAD_IMG_STATUS,
            YOUNAIL_QUESTIONS_GET_ADD_STATUS,
            YOUNAIL_QUESTIONS_GET_UPD_STATUS,
            AUTH_GET_USER_INFO
        }),
        // 获取问题详情
        getQuestionDetail() {
            this[YOUNAIL_QUESTIONS_GET_DETAIL_BY_ID]({
                type: YOUNAIL_QUESTIONS_GET_DETAIL_BY_ID,
                params: {
                    id: this.answerParams.id,
                    title: this.routerParams.title,
                    question_id: this.routerParams.question_id,
                    content_status: this.routerParams.content_status,
                    baidu_qa: this.routerParams.baidu_qa,
                    baidu_feedback_status: this.routerParams.baidu_feedback_status,
                    audit_status: this.routerParams.audit_status,
                    user_id: this.routerParams.user_id,
                    month: this.routerParams.month,
                }
            });
        },
        // 添加拓展headline
        addHeadline() {
            if (this.answerParams.extra_headline.length < 5) {
                this.answerParams.extra_headline.push('');
            } else {
                this.$alert('百度扩展headline最多5条');
            }
        },
        // 删除拓展headline
        deleteHeadline() {
            this.answerParams.extra_headline.pop();
        },
        // 添加标签
        async tagInputConfirm() {
            if (this.tagInputValue) {
                await this[YOUNAIL_QUESTIONS_GET_ADD_TAG_STATUS]({
                    type: YOUNAIL_QUESTIONS_GET_ADD_TAG_STATUS,
                    params: {
                        name: this.tagInputValue
                    }
                });
                if (this.tagAddTagStatus) {
                    let tagIdList = this.answerParams.tagList.map(this.returnTagList);
                    // 判断是否已经包含此id
                    if (tagIdList.indexOf(this.tagAddTagStatus.id) < 0) {
                        let obj = {};
                        obj.id = this.tagAddTagStatus.id;
                        obj.name = this.tagAddTagStatus.name;
                        this.answerParams.tagList.push(obj);
                    }
                    this.tagInputValue = '';
                }
            }
        },
        // 删除标签
        deleteTag(x) {
            this.answerParams.tagList.splice(x, 1);
        },
        // 返回新数组
        returnTagList(value) {
            return value.id;
        },
        // 上传百度提交图片
        async uploadBaiduPhotoRelation() {
            await this[YOUNAIL_QUESTIONS_GET_UPLOAD_IMG_STATUS]({
                type: YOUNAIL_QUESTIONS_GET_UPLOAD_IMG_STATUS,
                params: {
                    files: this.$refs.uploadBaiduPhoto.$refs['upload-inner'].fileList,
                    width: 750,
                    height: 370
                }
            });
            this.answerParams.baiduPhotoFile = [];

            if (this.uploadImgFile.length > 0) {
                this.answerParams.baiduPhotoFileInfo.file_id = this.uploadImgFile[0].file_id;
            }
        },
        // 删除百度提交图片
        deleteBaiduImg() {
            this.$confirm('确认删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error'
            })
                .then(async () => {
                    this.answerParams.baiduPhotoFileInfo = {};
                })
                .catch(() => {
                });
        },

        // 上传默认封面图
        async uploadDefaultPhotoRelation() {
            await this[YOUNAIL_QUESTIONS_GET_UPLOAD_IMG_STATUS]({
                type: YOUNAIL_QUESTIONS_GET_UPLOAD_IMG_STATUS,
                params: {
                    files: this.$refs.uploadDefaultPhoto.$refs['upload-inner'].fileList
                }
            });
            this.answerParams.defaultPhotoFile = [];

            if (this.uploadImgFile.length > 0) {
                this.answerParams.defaultPhotoFileId = this.uploadImgFile[0].file_id;
            }
        },
        // 删除默认封面图片
        deleteDefaultImg() {
            this.$confirm('确认删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error'
            })
                .then(async () => {
                    this.answerParams.defaultPhotoFileId = '';
                })
                .catch(() => {
                });
        },

        getStructuralContent(data) {
            this.answerParams.content = data;
        },
        async addAnswer() {
            this.saveDisabledMode = true;

            let tagIds = [];
            this.answerParams.tagList.forEach(item => {
                tagIds.push(item.id);
            });

            await this[YOUNAIL_QUESTIONS_GET_ADD_STATUS]({
                type: YOUNAIL_QUESTIONS_GET_ADD_STATUS,
                params: {
                    title: this.answerParams.title,
                    domain: this.answerParams.domain,
                    baidu_qa: this.answerParams.baidu_qa,
                    headline: this.answerParams.headline,
                    extra_headline: JSON.stringify(this.answerParams.extra_headline),
                    description: this.answerParams.description,
                    timeliness: this.answerParams.timeliness,
                    expiration_date: this.answerParams.expiration_date,
                    user_id: this.answerParams.user_info.id,
                    tag_ids: tagIds.join(','),
                    checked_desc: this.answerParams.checked_desc,
                    short_content: this.answerParams.short_content,
                    origin_content: this.answerParams.origin_content,
                    content: JSON.stringify(this.answerParams.content),
                    is_recommend: this.answerParams.is_recommend,
                    status: this.answerParams.status,
                    attribute_ids: this.answerParams.attribute_ids,
                    audit_status: this.answerParams.audit_status_result,
                    baidu_submit_status: this.answerParams.baidu_submit_status,
                    baidu_feedback_status: this.answerParams.baidu_feedback_status,
                    image_cover: this.answerParams.defaultPhotoFileId,
                    image_info: JSON.stringify(this.answerParams.baiduPhotoFileInfo)
                }
            });

            if (this.addQuestionStatus) {
                this.$message.success('创建问题成功');
                this.$router.push({name: 'younailQuestionsAnswer', query: {question_id: this.addQuestionStatus.id}});
            }
            this.saveDisabledMode = false;
        },

        async updateAnswer() {
            this.saveDisabledMode = true;

            let tagIds = [];
            this.answerParams.tagList.forEach(item => {
                tagIds.push(item.id);
            });

            await this[YOUNAIL_QUESTIONS_GET_UPD_STATUS]({
                type: YOUNAIL_QUESTIONS_GET_UPD_STATUS,
                params: {
                    id: this.answerParams.id,
                    title: this.answerParams.title,
                    domain: this.answerParams.domain,
                    baidu_qa: this.answerParams.baidu_qa,
                    headline: this.answerParams.headline,
                    description: this.answerParams.description,
                    timeliness: this.answerParams.timeliness,
                    expiration_date: this.answerParams.expiration_date,
                    extra_headline: JSON.stringify(this.answerParams.extra_headline),
                    user_id: this.answerParams.user_info.id,
                    tag_ids: tagIds.join(','),
                    checked_desc: this.answerParams.checked_desc,
                    short_content: this.answerParams.short_content,
                    origin_content: this.answerParams.origin_content,
                    content: JSON.stringify(this.answerParams.content),
                    is_recommend: this.answerParams.is_recommend,
                    status: this.answerParams.status,
                    attribute_ids: this.answerParams.attribute_ids,
                    audit_status: this.answerParams.audit_status_result,
                    baidu_submit_status: this.answerParams.baidu_submit_status,
                    baidu_feedback_status: this.answerParams.baidu_feedback_status,
                    image_cover: this.answerParams.defaultPhotoFileId,
                    image_info: JSON.stringify(this.answerParams.baiduPhotoFileInfo)
                }
            });

            if (this.updQuestionStatus) {
                this.$message.success('更新问题成功');
                this.getQuestionDetail();
            }
            this.saveDisabledMode = false;
        },

        //获取下一个问题详情
        nextQuestion() {
            this.$router.push({
                name: 'younailQuestionsAnswer',
                query: {
                    question_id: this.next_question_id
                }
            });
        },
        toQuestionList() {
            this.$router.push({path: '/younailQuestionsList', query: {}});
        },
        async setUserAuditStatusMap() {
            await this[AUTH_GET_USER_INFO]({type: AUTH_GET_USER_INFO});
            if (this.userInfo) {
                if (Array.isArray(this.userInfo.role_ids) && this.userInfo.role_ids.includes(this.roleMap.admin)) {
                    this.userAuditStatusMap = this.auditStatusMap;
                } else {
                    //非管理员
                    let userAuditStatusArray = [2, 3];

                    let singleAuditStatusMap = deepCopy(this.auditStatusMap);
                    let obj = {};
                    userAuditStatusArray.forEach(function (val) {
                        obj[val] = singleAuditStatusMap[val];
                    });
                    this.userAuditStatusMap = obj;
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.page-header {
    padding-bottom: 15px;
    border-bottom: 1px solid #dcdfe6;
}
</style>
