<template>
    <div class="editor-container">
        <div class="toolbox">
            <span title="设为段落标题" @click="insertInputTxt('!#', 'normal')">H2</span>
            <span title="设为段落子标题" @click="insertInputTxt('!##', 'normal')">H3</span>
            <span title="加粗" @click="insertInputTxt('', 'bold')">B</span>
            <!--            <span title="插入标题描述" class="add-describe-btn" @click="insertInputTxt('!~~', 'normal')"></span>-->
            <!--            <span title="插入注释" class="add-annotation-btn" @click="insertInputTxt('!~', 'normal')"></span>-->
            <span title="插入超链接" class="add-hyperlink-btn" @click="showHyperlinkModal"></span>
            <span title="插入图片" class="add-image-btn" @click="photoModal = true"></span>
            <span title="插入视频" class="add-video-btn" @click="videoModal = true"></span>
            <!--            <span title="插入POI" class="add-poi-btn" @click="poiModal = true"></span>-->
            <!--            <span title="插入目的地" class="add-mdd-btn" @click="tourMddModal = true"></span>-->
            <!-- <span title="插入订制产品" class="add-custom_product-btn" @click="customProductModal = true"></span>
            <span title="插入当地游产品" class="add-tour_product-btn" @click="tourProductModal = true"></span> -->
            <span title="插入花袖产品" class="add-younail-product-btn" @click="younailProductModal = true"></span>
        </div>
        <div class="main">
            <div class="left-input">
                <textarea :id="editorId" placeholder="请输入内容" rows="30" v-model="content"
                          @paste="handlePaste"></textarea>
            </div>
            <div class="right-html">
                <div v-for="item in structure">
                    <div class="title" v-if="item.type === 'title'">{{ item.text }}</div>
                    <div class="sub-title" v-if="item.type === 'sub_title'">{{ item.text }}</div>
                    <div class="paragraph" v-if="item.type === 'paragraph'">
                        <span v-for="x in item.list" :style="{ fontWeight: x.style === 'bold' ? 700 : 300 }"
                              v-html="getParagraph(x)"></span>
                    </div>
                    <div v-if="item.type === 'image'">
                        <img :src="`${photoHost[item.storage_source]}/${item.file_id}?x-oss-process=image/resize,h_300`"
                             style="max-width: 100%;"/>
                    </div>
                    <div class="image-album" v-if="item.type === 'image_album'">
                        <div><img v-for="x in item.list"
                                  :src="`${photoHost[x.storage_source]}/${x.file_id}?x-oss-process=image/resize,h_200`"/>
                        </div>
                        <div>
                            <el-button type="warning" size="mini" @click="editPhotoAlbum(item)">编辑图集</el-button>
                        </div>
                    </div>
                    <div class="video" v-if="item.type === 'video'">
                        <video :src="videoHost[item.storage_source] + '/' + item.file_id" controls="controls"></video>
                    </div>
                    <div class="annotation" v-if="item.type === 'annotation'">{{ item.text }}</div>
                    <div class="poi" v-if="item.type === 'poi'">
                        <span>{{ item.poi_name }}</span>
                        <br/>
                        <span v-if="item.title !== ''">标题：{{ item.title }}</span>
                        <br/>
                        <span v-if="item.poi_desc !== ''">描述：{{ item.poi_desc }}</span>
                    </div>
                    <div class="custom-product" v-if="item.type === 'custom_product'">{{ item.name }}</div>
                    <div class="tour-product" v-if="item.type === 'tour_product'">
                        <span>{{ item.name }}</span>
                        <br/>
                        <span v-if="item.product_desc !== ''">描述：{{ item.product_desc }}</span>
                    </div>
                    <div class="mdd" v-if="item.type === 'mdd'">
                        <span>{{ item.name }}</span>
                        <br/>
                        <span v-if="item.title !== ''">标题：{{ item.title }}</span>
                        <br/>
                        <span v-if="item.mdd_desc !== ''">描述：{{ item.mdd_desc }}</span>
                    </div>
                    <div class="describe" v-if="item.type === 'describe'">{{ item.text }}</div>
                    <div class="younail-product" v-if="item.type ==='younail_products'">
                        <div v-for="(val,key) in item.products">
                            <div class="younail-product-item-bg">
                                <PreveImg v-if="val.file_id && val.file_id !== ''" :image_cover="val.file_id"
                                          :isshow="false"></PreveImg>
                                <span class="younail-product-item-name">{{ val.name }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="show-font-sum">{{ wordsNumber }}</div>
        </div>

        <el-dialog title="上传图片" :visible.sync="photoModal" append-to-body>
            <el-form :model="photoModalParams" label-width="50">
                <el-form-item>
                    <el-upload action="" :auto-upload="false" :multiple="true" :limit="10"
                               :file-list="photoModalParams.file" ref="upload">
                        <el-button type="primary" size="mini">上传图片(最多10张)</el-button>
                    </el-upload>
                </el-form-item>
                <el-form-item label="类型">
                    <el-radio v-model="photoModalParams.type" :label="0">普通</el-radio>
                    <el-radio v-model="photoModalParams.type" :label="1">图集</el-radio>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="photoModal = false">取 消</el-button>
                <el-button size="small" type="primary" @click="addPhoto" :disabled="photoUploadDisabled">保 存
                </el-button>
            </div>
        </el-dialog>

        <el-dialog title="上传视频" :visible.sync="videoModal">
            <el-form :model="videoModalParams" label-width="50">
                <el-form-item>
                    <el-upload action="" :auto-upload="false" :multiple="false" :limit="1"
                               :file-list="videoModalParams.file" ref="uploadVideo">
                        <el-button type="primary" size="mini">上传视频</el-button>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="videoModal = false">取 消</el-button>
                <el-button size="small" type="primary" @click="addVideo" :disabled="videoUploadDisabled">保 存
                </el-button>
            </div>
        </el-dialog>

        <el-dialog title="插入超链接" :visible.sync="hyperlinkModal">
            <el-form :model="hyperlinkModalParams" label-width="100">
                <el-form-item label="类型">
                    <el-select v-model="hyperlinkModalParams.type" size="small">
                        <el-option label="普通链接" value="normal"></el-option>
                        <el-option label="官网链接" value="official_website"></el-option>
                        <el-option label="小程序链接" value="micro_app"></el-option>
                        <!-- <el-option label="文章" value="article"></el-option>
                        <el-option label="兴趣点" value="interest_point"></el-option>
                        <el-option label="POI" value="poi"></el-option>
                        <el-option label="问题墙" value="question"></el-option>
                        <el-option label="购买会员" value="member"></el-option> -->
                    </el-select>
                </el-form-item>
                <el-form-item label="标题"
                              v-if="['normal', 'official_website', 'article', 'member', 'poi', 'interest_point', 'question'].includes(hyperlinkModalParams.type)">
                    <el-input v-model="hyperlinkModalParams.title"></el-input>
                </el-form-item>
                <el-form-item label="链接" v-if="['normal', 'official_website'].includes(hyperlinkModalParams.type)">
                    <el-input v-model="hyperlinkModalParams.link"></el-input>
                </el-form-item>
                <el-form-item label="文章ID" v-if="['article'].includes(hyperlinkModalParams.type)">
                    <article-input-search v-model="hyperlinkModalParams.article"></article-input-search>
                </el-form-item>
                <el-form-item label="选择图标"
                              v-if="['interest_point', 'poi', 'question', 'member'].includes(hyperlinkModalParams.type)">
                    <el-select v-model="hyperlinkModalParams.icon" size="small">
                        <el-option label="普通" value="normal"></el-option>
                        <el-option label="视频" value="video"></el-option>
                        <el-option label="热门" value="hot"></el-option>
                        <el-option label="会员" value="member"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="POI ID" v-if="['poi'].includes(hyperlinkModalParams.type)">
                    <poi-input-search v-model="hyperlinkModalParams.poi"></poi-input-search>
                </el-form-item>
                <el-form-item label="兴趣点ID" v-if="['interest_point'].includes(hyperlinkModalParams.type)">
                    <interest-point-input-search
                        v-model="hyperlinkModalParams.interest_point"></interest-point-input-search>
                </el-form-item>
                <el-form-item label="问题墙ID" v-if="['question'].includes(hyperlinkModalParams.type)">
                    <question-input-search-title v-model="hyperlinkModalParams.question"></question-input-search-title>
                </el-form-item>
                <el-form-item label="跳转类型" v-if="['micro_app'].includes(hyperlinkModalParams.type)">
                    <el-select v-model="hyperlinkModalParams.micro_app_url_type" size="small">
                        <el-option label="普通" value="normal"></el-option>
                        <el-option label="tabbar" value="tabbar"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="跳转小程序路径" v-if="['micro_app'].includes(hyperlinkModalParams.type)">
                    <el-input v-model="hyperlinkModalParams.micro_app_url"></el-input>
                </el-form-item>
                <el-form-item label="图片" v-if="['micro_app'].includes(hyperlinkModalParams.type)">
                    <el-upload
                        class="mg-r10"
                        action=""
                        :auto-upload="true"
                        size="small"
                        :multiple="false"
                        :limit="1"
                        :http-request="uploadHyperlinkPhotoRelation"
                        :file-list="hyperlinkModalParams.fileList"
                        :show-file-list="false"
                        ref="uploadHyperlinkPhoto"
                    >
                        <el-button type="primary" size="medium">图片上传</el-button>
                    </el-upload>
                    <PreveImg v-if="hyperlinkModalParams.file_id && hyperlinkModalParams.file_id !== ''"
                              :image_cover="hyperlinkModalParams.file_id"
                              @change="uploadHyperlinkPhotoCallBack"></PreveImg>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="hyperlinkModal = false">取 消</el-button>
                <el-button size="small" type="primary" @click="addHyperlink">保 存</el-button>
            </div>
        </el-dialog>

        <el-dialog title="编辑图集" :visible.sync="photoAlbumModal">
            <el-form :model="photoAlbumModalParams" label-width="50">
                <el-form-item>
                    <el-upload action="" :auto-upload="false" :multiple="true" :limit="10"
                               :file-list="photoAlbumModalParams.file" ref="editUploadPhotoAlbum">
                        <el-button type="primary" size="mini">上传图片(最多10张)</el-button>
                    </el-upload>
                </el-form-item>
                <div>可拖拽排序，点击图片可进行删除操作（会有弹窗提示）</div>
                <div class="edit-photo-album-list" @dragover="dragImageOver" @drop="dropImage">
                    <img
                        :id="item.file_id"
                        draggable="true"
                        @dragstart="dragImageStart(item.file_id)"
                        v-for="item in photoAlbumModalParams.list"
                        :key="item.file_id"
                        :src="`${photoHost[item.storage_source]}/${item.file_id}?x-oss-process=image/resize,h_200`"
                        @click="photoAlbumHandle(item.file_id)"
                    />
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="photoAlbumModal = false">取 消</el-button>
                <el-button size="small" type="primary" @click="savePhotoAlbum" :disabled="photoUploadDisabled">保 存
                </el-button>
            </div>
        </el-dialog>

        <el-dialog title="请选择要进行的操作" :visible.sync="photoAlbumHandleModal" width="30%" center>
			<span slot="footer" class="dialog-footer">
				<el-button @click="photoAlbumHandleModal = false">取 消</el-button>
				<el-button type="danger" @click="deletePhotoAlbumItem">删 除</el-button>
			</span>
        </el-dialog>

        <el-dialog title="插入POI" :visible.sync="poiModal">
            <el-form :model="poiModalParams" label-width="100px">
                <el-form-item label="类型">
                    <el-select v-model="poiModalParams.type" size="small">
                        <el-option label="酒店" :value="1"></el-option>
                        <el-option label="餐厅" :value="2"></el-option>
                        <el-option label="景点" :value="3"></el-option>
                        <el-option label="购物" :value="4"></el-option>
                        <el-option label="交通" :value="5"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="POI ID">
                    <poi-search-input :type="poiModalParams.type" v-model="poiModalParams.poi"></poi-search-input>
                </el-form-item>
                <el-form-item label="标题">
                    <el-input v-model="poiModalParams.title" size="small" placeholder="请输入标题"></el-input>
                </el-form-item>
                <el-form-item label="描述">
                    <el-input v-model="poiModalParams.poi_desc" size="small" type="textarea" :rows="2"
                              placeholder="请输入内容"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="poiModal = false">取 消</el-button>
                <el-button size="small" type="primary" @click="addPoi">保 存</el-button>
            </div>
        </el-dialog>

        <!-- <el-dialog title="插入订制产品" :visible.sync="customProductModal">
            <el-form :model="customProductModalParams">
                <el-form-item
                    label="订制产品ID"
                    :label-width="'100px'"
                >
                    <custom-input-search :width="300" v-model="customProductModalParams.product"></custom-input-search>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="customProductModal = false">取 消</el-button>
                <el-button size="small" type="primary" @click="addCustomProduct">保 存</el-button>
            </div>
        </el-dialog> -->

        <!-- <el-dialog title="插入当地游" :visible.sync="tourProductModal">
            <el-form :model="tourProductModalParams">
                <el-form-item
                    label="产品ID"
                    :label-width="'100px'"
                >
                    <product-input-search-v2 :width="300" v-model="tourProductModalParams.product"></product-input-search-v2>
                </el-form-item>
                <el-form-item
                    label="产品描述"
                    :label-width="'100px'"
                >
                    <el-input
                            type="textarea"
                            :rows="2"
                            placeholder="请输入内容"
                            v-model="tourProductModalParams.product_desc">
                    </el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="tourProductModal = false">取 消</el-button>
                <el-button size="small" type="primary" @click="addTourProduct">保 存</el-button>
            </div>
        </el-dialog> -->

        <el-dialog title="插入目的地" :visible.sync="tourMddModal">
            <el-form :model="mddModalParams" :label-width="'100px'">
                <el-form-item label="目的地ID">
                    <mdd-search-input :width="300" v-model="mddModalParams.mdd"></mdd-search-input>
                </el-form-item>
                <el-form-item label="标题">
                    <el-input v-model="mddModalParams.title" size="small" placeholder="请输入标题"></el-input>
                </el-form-item>
                <el-form-item label="目的地描述">
                    <el-input type="textarea" :rows="2" placeholder="请输入内容" size="small"
                              v-model="mddModalParams.mdd_desc"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="tourMddModal = false">取 消</el-button>
                <el-button size="small" type="primary" @click="addMdd">保 存</el-button>
            </div>
        </el-dialog>
        <el-dialog title="插入花袖商品" :visible.sync="younailProductModal">
            <el-form :model="younailProductModalParams" :label-width="'100px'">
                <el-form-item label="类型">
                    <el-radio v-model="younailProductModalParams.type" :label="0">普通</el-radio>
                    <el-radio v-model="younailProductModalParams.type" :label="1">图集</el-radio>
                </el-form-item>
                <div class="younail-product-item" v-for="(item, index) in younailProductModalParams.products"
                     :key="index"
                     @click="selectYounailProduct(index)">
                    <div>
                        <el-form-item label="款式信息">
                            <YounailSearchInput
                                url="/younail/product/attribute/list?page=1&page_size=20&product_type_id=5,6,7,11,12&name="
                                :width="300"
                                :value="item.product_attribute" @select="selectedAttribute"></YounailSearchInput>
                        </el-form-item>
                        <el-form-item label="图片">
                            <el-upload
                                class="mg-r10"
                                action=""
                                :auto-upload="true"
                                :multiple="false"
                                :limit="1"
                                :http-request="uploadYounailProductPhotoRelation"
                                :file-list="younailProductModalParams.fileList"
                                :show-file-list="false"
                                ref="uploadYounailProduct"
                            >
                                <el-button type="primary" size="medium">图片上传</el-button>
                            </el-upload>
                            <PreveImg v-if="item.file_id && item.file_id !== ''" :image_cover="item.file_id"
                                      @change="imgCallBack"></PreveImg>
                        </el-form-item>
                    </div>
                    <div>
                        <el-button type="danger" size="medium" @click.stop="delYounailProduct(index)">删除</el-button>
                    </div>
                </div>
                <div class="flex-row flex-al-center">
                    <el-button type="primary" size="medium" @click="addYounailProductItem">+新增商品</el-button>
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="younailProductModal = false">取 消</el-button>
                <el-button size="small" type="primary" @click="addYounailProduct">保 存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import MddSearchInput from '@/components/mdd/mddSearchInput';
import PoiSearchInput from '@/components/poi/poiSearchInput';
// import CustomInputSearch from '@/components/custom/InputSearch'
// import ProductInputSearch from '@/components/product/InputSearch'
// import ProductInputSearchV2 from '@/components/product/InputSearchV2'
// import InterestPointInputSearch from '@/components/interestPoint/InputSearch'
// import QuestionInputSearchTitle from '@/components/question/InputSearchTitle'
// import ArticleInputSearch from '@/components/content/InputSearch'
import YounailSearchInput from '@/components/search/younailsearchinput.vue';
import PreveImg from '@/components/preveimg/preve.vue';

import {loginCheck} from '@/utils/loginCheck';
import {deepCopy} from '@/utils/deepCopy';

const photoHost =
    process.env.NODE_ENV !== 'production' || location.host.includes('-test')
        ? {1: 'http://t-aliyunzjk-wee-images.oss-cn-zhangjiakou.aliyuncs.com'}
        : {1: 'http://p-aliyunzjk-wee-images.oss-cn-zhangjiakou.aliyuncs.com'};
const videoHost =
    process.env.NODE_ENV !== 'production' || location.host.includes('-test')
        ? {1: 'http://t-aliyunzjk-wee-videos.oss-cn-zhangjiakou.aliyuncs.com'}
        : {1: 'http://p-aliyunzjk-wee-videos.oss-cn-zhangjiakou.aliyuncs.com'};

export default {
    name: 'weelv-editor',
    components: {
        MddSearchInput,
        PoiSearchInput,
        YounailSearchInput,
        PreveImg
        // ArticleInputSearch,
        // PoiSearchInput,
        // CustomInputSearch,
        // InterestPointInputSearch,
        // QuestionInputSearchTitle,
        // ProductInputSearch,
        // ProductInputSearchV2,
        // MddInputSearch
    },
    props: {
        value: {
            type: String,
            default: '',
            required: true
        },
        editorId: {
            type: String,
            default: 'editor',
            required: false
        },
        channel: {
            type: String,
            default: 'content',
            required: false
        },
    },
    data() {
        return {
            photoHost: photoHost,
            videoHost: videoHost,
            content: '',
            structure: [],
            wordsNumber: 0,
            photoModal: false,
            photoModalParams: {
                file: [],
                type: 0
            },
            photoUploadDisabled: false,
            photoAlbumModal: false,
            photoAlbumModalParams: {
                file: [],
                list: [],
                original_list: []
            },
            photoAlbumHandleModal: false,
            photoAlbumHandleModalFileId: '',
            videoUploadDisabled: false,
            videoModal: false,
            videoModalParams: {
                file: []
            },
            hyperlinkModal: false,
            hyperlinkModalParams: {
                link: 'http://',
                title: '',
                type: 'normal',
                article: {article_id: '', title: ''},
                icon: '',
                poi: {poi_id: '', name: '', data_type: ''},
                interest_point: {id: '', title: ''},
                question: {id: '', title: '', mddid: ''},
                fileList: [],
                file_id: '',

                micro_app_url_type: 'normal',
                micro_app_url: '',
            },
            poiModal: false,
            poiModalParams: {
                type: 3,
                poi: {poi_id: '', name: '', data_type: ''},
                title: '',
                poi_desc: ''
            },
            customProductModal: false,
            customProductModalParams: {
                product: {custom_product_id: '', value: ''}
            },
            tourProductModal: false,
            tourProductModalParams: {
                product: {product_id: '', name: '', spu_id: ''},
                product_desc: ''
            },
            tourMddModal: false,
            mddModalParams: {
                mdd: {mddid: '', value: ''},
                title: '',
                mdd_desc: ''
            },
            younailProductModal: false,
            younailProductIndex: 0,
            younailProductModalParams: {
                type: 0,
                products: [
                    {
                        product_attribute: {id: '', name: '', product_id: ''},
                        file_id: '',
                    }
                ],
                singleProduct: {
                    product_attribute: {id: '', name: '', product_id: ''},
                    file_id: '',
                },
                file: [],
            }
        };
    },
    watch: {
        content() {
            this.formatContent();
            this.getStructuralContent();
            this.getWordsNumber();

            this.$emit('input', this.content);
        },
        photoModal(value) {
            if (!value) {
                this.photoModalParams.file = [];
                this.photoModalParams.type = 0;
            }
        },
        videoModal(value) {
            if (!value) {
                this.videoModalParams.file = [];
            }
        },
        hyperlinkModal(value) {
            if (!value) {
                this.hyperlinkModalParams.link = 'http://';
                this.hyperlinkModalParams.title = '';
                this.hyperlinkModalParams.type = 'normal';
                this.hyperlinkModalParams.article = {article_id: '', title: ''};
                this.hyperlinkModalParams.poi = {poi_id: '', name: '', data_type: ''};
                this.hyperlinkModalParams.interest_point = {id: '', title: ''};
                this.hyperlinkModalParams.question = {id: '', title: '', mddid: ''};
                this.hyperlinkModalParams.question = {id: '', title: '', mddid: ''};
                this.hyperlinkModalParams.file_id = '';
                this.hyperlinkModalParams.micro_app_url_type = 'normal';
                this.hyperlinkModalParams.micro_app_url = '';
            }
        },
        value() {
            this.content = this.value;
        },
        photoAlbumModal(value) {
            if (!value) {
                this.photoAlbumModalParams.file = [];
                this.photoAlbumModalParams.list = [];
                this.photoAlbumModalParams.original_list = [];
            }
        },
        younailProductModal(value) {
            if (!value) {
                this.younailProductModalParams = {
                    type: 0,
                    products: [
                        {
                            product_attribute: {id: '', name: '', product_id: ''},
                            file_id: '',
                        }
                    ],
                    singleProduct: {
                        product_attribute: {id: '', name: '', product_id: ''},
                        file_id: '',
                    },
                    file: [],
                };
            }
        },
    },
    methods: {
        formatContent() {
            this.structure = [];
            if (this.content.includes('\n')) {
                this.content.split('\n').forEach(item => {
                    if (item.substring(0, 3) === '!##') {
                        this.formatSubTitle(item);
                    } else if (item.substring(0, 2) === '!#') {
                        this.formatTitle(item);
                    } else if (item.substring(0, 2) === '!@') {
                        this.formatImage(item);
                    } else if (item.substring(0, 2) === '!$') {
                        this.formatImageAlbum(item);
                    } else if (item.substring(0, 2) === '!&') {
                        this.formatVideo(item);
                    } else if (item.substring(0, 3) === '!~~') {
                        this.formatDescribe(item);
                    } else if (item.substring(0, 2) === '!~') {
                        this.formatAnnotation(item);
                    } else if (item.substring(0, 3) === '!^&') {
                        this.formatYounailProduct(item);
                    } else if (item.substring(0, 2) === '!^') {
                        this.formatPoi(item);
                    } else if (item.substring(0, 3) === '!%^') {
                        this.formatCustomProduct(item);
                    } else if (item.substring(0, 3) === '!%@') {
                        this.formatTourProduct(item);
                    } else if (item.substring(0, 3) === '!%*') {
                        this.formatMdd(item);
                    } else {
                        this.formatParagraph(item);
                    }
                });
            } else {
                if (this.content.substring(0, 3) === '!##') {
                    this.formatSubTitle(this.content);
                } else if (this.content.substring(0, 2) === '!#') {
                    this.formatTitle(this.content);
                } else if (this.content.substring(0, 2) === '!@') {
                    this.formatImage(this.content);
                } else if (this.content.substring(0, 2) === '!$') {
                    this.formatImageAlbum(this.content);
                } else if (this.content.substring(0, 2) === '!&') {
                    this.formatVideo(this.content);
                } else if (this.content.substring(0, 3) === '!~~') {
                    this.formatDescribe(this.content);
                } else if (this.content.substring(0, 2) === '!~') {
                    this.formatAnnotation(this.content);
                } else if (this.content.substring(0, 3) === '!^&') {
                    this.formatYounailProduct(this.content);
                } else if (this.content.substring(0, 2) === '!^') {
                    this.formatPoi(this.content);
                } else if (this.content.substring(0, 3) === '!%^') {
                    this.formatCustomProduct(this.content);
                } else if (this.content.substring(0, 3) === '!%@') {
                    this.formatTourProduct(this.content);
                } else if (this.content.substring(0, 3) === '!%*') {
                    this.formatMdd(this.content);
                } else {
                    this.formatParagraph(this.content);
                }
            }

            return this.structure;
        },
        formatTitle(item) {
            this.structure.push({
                type: 'title',
                text: item.substring(2)
            });
        },
        formatSubTitle(item) {
            this.structure.push({
                type: 'sub_title',
                text: item.substring(3)
            });
        },
        formatParagraph(item) {
            let reg = /\!\*(.*?)\*\!/g;
            let weightArr = item.match(reg);

            let str = [];
            let next = '';

            if (weightArr && weightArr.length > 0) {
                weightArr.forEach((x, key) => {
                    if (key === 0) {
                        var itemArr = item.split(x, 1);
                        let split = x;

                        split = split.replace('!*', '');
                        split = split.replace('*!', '');

                        //hyperlink
                        if (itemArr[0].includes('!%[')) {
                            let hyperReg = /\!\%\[(.*?)\]/g;
                            let hyperlinkArr = itemArr[0].match(hyperReg);

                            let hyperNext = '';

                            if (hyperlinkArr && hyperlinkArr.length > 0) {
                                hyperlinkArr.forEach((xh, hkey) => {
                                    if (hkey === 0) {
                                        let text = itemArr[0].split(xh, 1);

                                        let obj = xh.replace('!%[', '');
                                        obj = obj.replace(']', '');
                                        obj = JSON.parse(obj);

                                        str.push({
                                            type: 'text',
                                            text: text[0]
                                        });

                                        let hyperlink = {
                                            type: 'hyperlink',
                                            link_type: obj.type
                                        };

                                        if (['normal', 'official_website'].includes(obj.type)) {
                                            hyperlink.title = obj.title;
                                            hyperlink.link = obj.link;
                                        } else if (['article'].includes(obj.type)) {
                                            hyperlink.title = obj.title;
                                            hyperlink.article_id = obj.article_id;
                                        } else if (['interest_point'].includes(obj.type)) {
                                            hyperlink.interest_point_id = obj.interest_point_id;
                                            hyperlink.interest_point_title = obj.interest_point_title;
                                            hyperlink.show_title = obj.show_title;
                                            hyperlink.icon = obj.icon;
                                        } else if (['poi'].includes(obj.type)) {
                                            hyperlink.poi_id = obj.poi_id;
                                            hyperlink.poi_name = obj.poi_name;
                                            hyperlink.data_type = obj.data_type;
                                            hyperlink.show_title = obj.show_title;
                                            hyperlink.icon = obj.icon;
                                        } else if (['question'].includes(obj.type)) {
                                            hyperlink.question_id = obj.question_id;
                                            hyperlink.question_title = obj.question_title;
                                            hyperlink.show_title = obj.show_title;
                                            hyperlink.mddid = obj.mddid;
                                            hyperlink.icon = obj.icon;
                                        } else if (['member'].includes(obj.type)) {
                                            hyperlink.title = obj.title;
                                            hyperlink.icon = obj.icon;
                                        } else if (['micro_app'].includes(obj.type)) {
                                            hyperlink.file_id = obj.file_id;
                                            hyperlink.micro_app_url_type = obj.micro_app_url_type;
                                            hyperlink.micro_app_url = obj.micro_app_url;
                                        }

                                        str.push(hyperlink);

                                        hyperNext = itemArr[0].replace(text[0], '');
                                        hyperNext = hyperNext.replace(xh, '');
                                    } else {
                                        let text = hyperNext.split(xh, 1);

                                        let obj = xh.replace('!%[', '');
                                        obj = obj.replace(']', '');
                                        obj = JSON.parse(obj);

                                        str.push({
                                            type: 'text',
                                            text: text[0]
                                        });

                                        let hyperlink = {
                                            type: 'hyperlink',
                                            link_type: obj.type
                                        };
                                        if (['normal', 'official_website'].includes(obj.type)) {
                                            hyperlink.title = obj.title;
                                            hyperlink.link = obj.link;
                                        } else if (['article'].includes(obj.type)) {
                                            hyperlink.title = obj.title;
                                            hyperlink.article_id = obj.article_id;
                                        } else if (['interest_point'].includes(obj.type)) {
                                            hyperlink.interest_point_id = obj.interest_point_id;
                                            hyperlink.interest_point_title = obj.interest_point_title;
                                            hyperlink.show_title = obj.show_title;
                                            hyperlink.icon = obj.icon;
                                        } else if (['poi'].includes(obj.type)) {
                                            hyperlink.poi_id = obj.poi_id;
                                            hyperlink.poi_name = obj.poi_name;
                                            hyperlink.data_type = obj.data_type;
                                            hyperlink.show_title = obj.show_title;
                                            hyperlink.icon = obj.icon;
                                        } else if (['question'].includes(obj.type)) {
                                            hyperlink.question_id = obj.question_id;
                                            hyperlink.question_title = obj.question_title;
                                            hyperlink.show_title = obj.show_title;
                                            hyperlink.icon = obj.icon;
                                        } else if (['member'].includes(obj.type)) {
                                            hyperlink.title = obj.title;
                                            hyperlink.icon = obj.icon;
                                        } else if (['micro_app'].includes(obj.type)) {
                                            hyperlink.file_id = obj.file_id;
                                            hyperlink.micro_app_url_type = obj.micro_app_url_type;
                                            hyperlink.micro_app_url = obj.micro_app_url;
                                        }

                                        str.push(hyperlink);

                                        hyperNext = hyperNext.replace(text[0], '');
                                        hyperNext = hyperNext.replace(xh, '');
                                    }

                                    if (hkey === hyperlinkArr.length - 1) {
                                        str.push({type: 'text', text: hyperNext});
                                    }
                                });
                            }

                            str.push({type: 'text', text: split, style: 'bold'});
                        } else {
                            str.push({type: 'text', text: itemArr[0]});
                            str.push({type: 'text', text: split, style: 'bold'});
                        }

                        next = item.replace(itemArr[0], '');
                        next = next.replace(x, '');
                    } else {
                        var itemArr = next.split(x, 1);
                        let split = x;

                        split = split.replace('!*', '');
                        split = split.replace('*!', '');

                        //hyperlink
                        if (itemArr[0].includes('!%[')) {
                            let hyperReg = /\!\%\[(.*?)\]/g;
                            let hyperlinkArr = itemArr[0].match(hyperReg);

                            let hyperNext = '';

                            if (hyperlinkArr && hyperlinkArr.length > 0) {
                                hyperlinkArr.forEach((xh, hkey) => {
                                    if (hkey === 0) {
                                        let text = itemArr[0].split(xh, 1);

                                        let obj = xh.replace('!%[', '');
                                        obj = obj.replace(']', '');
                                        obj = JSON.parse(obj);

                                        str.push({
                                            type: 'text',
                                            text: text[0]
                                        });

                                        let hyperlink = {
                                            type: 'hyperlink',
                                            link_type: obj.type
                                        };
                                        if (['normal', 'official_website'].includes(obj.type)) {
                                            hyperlink.title = obj.title;
                                            hyperlink.link = obj.link;
                                        } else if (['article'].includes(obj.type)) {
                                            hyperlink.title = obj.title;
                                            hyperlink.article_id = obj.article_id;
                                        } else if (['interest_point'].includes(obj.type)) {
                                            hyperlink.interest_point_id = obj.interest_point_id;
                                            hyperlink.interest_point_title = obj.interest_point_title;
                                            hyperlink.show_title = obj.show_title;
                                            hyperlink.icon = obj.icon;
                                        } else if (['poi'].includes(obj.type)) {
                                            hyperlink.poi_id = obj.poi_id;
                                            hyperlink.poi_name = obj.poi_name;
                                            hyperlink.data_type = obj.data_type;
                                            hyperlink.show_title = obj.show_title;
                                            hyperlink.icon = obj.icon;
                                        } else if (['question'].includes(obj.type)) {
                                            hyperlink.question_id = obj.question_id;
                                            hyperlink.question_title = obj.question_title;
                                            hyperlink.show_title = obj.show_title;
                                            hyperlink.mddid = obj.mddid;
                                            hyperlink.icon = obj.icon;
                                        } else if (['member'].includes(obj.type)) {
                                            hyperlink.title = obj.title;
                                            hyperlink.icon = obj.icon;
                                        } else if (['micro_app'].includes(obj.type)) {
                                            hyperlink.file_id = obj.file_id;
                                            hyperlink.micro_app_url_type = obj.micro_app_url_type;
                                            hyperlink.micro_app_url = obj.micro_app_url;
                                        }

                                        str.push(hyperlink);

                                        hyperNext = itemArr[0].replace(text[0], '');
                                        hyperNext = hyperNext.replace(xh, '');
                                    } else {
                                        let text = hyperNext.split(xh, 1);

                                        let obj = xh.replace('!%[', '');
                                        obj = obj.replace(']', '');
                                        obj = JSON.parse(obj);

                                        str.push({
                                            type: 'text',
                                            text: text[0]
                                        });

                                        let hyperlink = {
                                            type: 'hyperlink',
                                            link_type: obj.type
                                        };
                                        if (['normal', 'official_website'].includes(obj.type)) {
                                            hyperlink.title = obj.title;
                                            hyperlink.link = obj.link;
                                        } else if (['article'].includes(obj.type)) {
                                            hyperlink.title = obj.title;
                                            hyperlink.article_id = obj.article_id;
                                        } else if (['interest_point'].includes(obj.type)) {
                                            hyperlink.interest_point_id = obj.interest_point_id;
                                            hyperlink.interest_point_title = obj.interest_point_title;
                                            hyperlink.show_title = obj.show_title;
                                            hyperlink.icon = obj.icon;
                                        } else if (['poi'].includes(obj.type)) {
                                            hyperlink.poi_id = obj.poi_id;
                                            hyperlink.poi_name = obj.poi_name;
                                            hyperlink.data_type = obj.data_type;
                                            hyperlink.show_title = obj.show_title;
                                            hyperlink.icon = obj.icon;
                                        } else if (['question'].includes(obj.type)) {
                                            hyperlink.question_id = obj.question_id;
                                            hyperlink.question_title = obj.question_title;
                                            hyperlink.show_title = obj.show_title;
                                            hyperlink.icon = obj.icon;
                                        } else if (['member'].includes(obj.type)) {
                                            hyperlink.title = obj.title;
                                            hyperlink.icon = obj.icon;
                                        } else if (['micro_app'].includes(obj.type)) {
                                            hyperlink.file_id = obj.file_id;
                                            hyperlink.micro_app_url_type = obj.micro_app_url_type;
                                            hyperlink.micro_app_url = obj.micro_app_url;
                                        }

                                        str.push(hyperlink);

                                        hyperNext = hyperNext.replace(text[0], '');
                                        hyperNext = hyperNext.replace(xh, '');
                                    }

                                    if (hkey === hyperlinkArr.length - 1) {
                                        str.push({type: 'text', text: hyperNext});
                                    }
                                });
                            }

                            str.push({type: 'text', text: split, style: 'bold'});
                        } else {
                            str.push({type: 'text', text: itemArr[0]});
                            str.push({type: 'text', text: split, style: 'bold'});
                        }

                        next = next.replace(itemArr[0], '');
                        next = next.replace(x, '');
                    }

                    if (key === weightArr.length - 1) {
                        //hyperlink
                        if (next.includes('!%[')) {
                            let hyperReg = /\!\%\[(.*?)\]/g;
                            let hyperlinkArr = next.match(hyperReg);

                            let hyperNext = '';

                            if (hyperlinkArr && hyperlinkArr.length > 0) {
                                hyperlinkArr.forEach((xh, hkey) => {
                                    if (hkey === 0) {
                                        let text = next.split(xh, 1);

                                        let obj = xh.replace('!%[', '');
                                        obj = obj.replace(']', '');
                                        obj = JSON.parse(obj);

                                        str.push({
                                            type: 'text',
                                            text: text[0]
                                        });

                                        let hyperlink = {
                                            type: 'hyperlink',
                                            link_type: obj.type
                                        };
                                        if (['normal', 'official_website'].includes(obj.type)) {
                                            hyperlink.title = obj.title;
                                            hyperlink.link = obj.link;
                                        } else if (['article'].includes(obj.type)) {
                                            hyperlink.title = obj.title;
                                            hyperlink.article_id = obj.article_id;
                                        } else if (['interest_point'].includes(obj.type)) {
                                            hyperlink.interest_point_id = obj.interest_point_id;
                                            hyperlink.interest_point_title = obj.interest_point_title;
                                            hyperlink.show_title = obj.show_title;
                                            hyperlink.icon = obj.icon;
                                        } else if (['poi'].includes(obj.type)) {
                                            hyperlink.poi_id = obj.poi_id;
                                            hyperlink.poi_name = obj.poi_name;
                                            hyperlink.data_type = obj.data_type;
                                            hyperlink.show_title = obj.show_title;
                                            hyperlink.icon = obj.icon;
                                        } else if (['question'].includes(obj.type)) {
                                            hyperlink.question_id = obj.question_id;
                                            hyperlink.question_title = obj.question_title;
                                            hyperlink.show_title = obj.show_title;
                                            hyperlink.mddid = obj.mddid;
                                            hyperlink.icon = obj.icon;
                                        } else if (['member'].includes(obj.type)) {
                                            hyperlink.title = obj.title;
                                            hyperlink.icon = obj.icon;
                                        } else if (['micro_app'].includes(obj.type)) {
                                            hyperlink.file_id = obj.file_id;
                                            hyperlink.micro_app_url_type = obj.micro_app_url_type;
                                            hyperlink.micro_app_url = obj.micro_app_url;
                                        }

                                        str.push(hyperlink);

                                        hyperNext = next.replace(text[0], '');
                                        hyperNext = hyperNext.replace(xh, '');
                                    } else {
                                        let text = hyperNext.split(xh, 1);

                                        let obj = xh.replace('!%[', '');
                                        obj = obj.replace(']', '');
                                        obj = JSON.parse(obj);

                                        str.push({
                                            type: 'text',
                                            text: text[0]
                                        });

                                        let hyperlink = {
                                            type: 'hyperlink',
                                            link_type: obj.type
                                        };
                                        if (['normal', 'official_website'].includes(obj.type)) {
                                            hyperlink.title = obj.title;
                                            hyperlink.link = obj.link;
                                        } else if (['article'].includes(obj.type)) {
                                            hyperlink.title = obj.title;
                                            hyperlink.article_id = obj.article_id;
                                        } else if (['interest_point'].includes(obj.type)) {
                                            hyperlink.interest_point_id = obj.interest_point_id;
                                            hyperlink.interest_point_title = obj.interest_point_title;
                                            hyperlink.icon = obj.icon;
                                        } else if (['poi'].includes(obj.type)) {
                                            hyperlink.poi_id = obj.poi_id;
                                            hyperlink.poi_name = obj.poi_name;
                                            hyperlink.data_type = obj.data_type;
                                            hyperlink.icon = obj.icon;
                                        } else if (['question'].includes(obj.type)) {
                                            hyperlink.question_id = obj.question_id;
                                            hyperlink.question_title = obj.question_title;
                                            hyperlink.icon = obj.icon;
                                        } else if (['member'].includes(obj.type)) {
                                            hyperlink.title = obj.title;
                                            hyperlink.icon = obj.icon;
                                        } else if (['micro_app'].includes(obj.type)) {
                                            hyperlink.file_id = obj.file_id;
                                            hyperlink.micro_app_url_type = obj.micro_app_url_type;
                                            hyperlink.micro_app_url = obj.micro_app_url;
                                        }

                                        str.push(hyperlink);

                                        hyperNext = hyperNext.replace(text[0], '');
                                        hyperNext = hyperNext.replace(xh, '');
                                    }

                                    if (hkey === hyperlinkArr.length - 1) {
                                        str.push({type: 'text', text: hyperNext});
                                    }
                                });
                            }
                        } else {
                            str.push({type: 'text', text: next});
                        }
                    }
                });
            } else {
                if (item.includes('!%')) {
                    let reg = /\!\%\[(.*?)\]/g;
                    let hyperlinkArr = item.match(reg);

                    let next = '';

                    if (hyperlinkArr && hyperlinkArr.length > 0) {
                        hyperlinkArr.forEach((x, key) => {
                            if (key === 0) {
                                let text = item.split(x, 1);

                                let obj = x.replace('!%[', '');
                                obj = obj.replace(']', '');
                                obj = JSON.parse(obj);

                                str.push({
                                    type: 'text',
                                    text: text[0]
                                });

                                let hyperlink = {
                                    type: 'hyperlink',
                                    link_type: obj.type
                                };
                                if (['normal', 'official_website'].includes(obj.type)) {
                                    hyperlink.title = obj.title;
                                    hyperlink.link = obj.link;
                                } else if (['article'].includes(obj.type)) {
                                    hyperlink.title = obj.title;
                                    hyperlink.article_id = obj.article_id;
                                } else if (['interest_point'].includes(obj.type)) {
                                    hyperlink.interest_point_id = obj.interest_point_id;
                                    hyperlink.interest_point_title = obj.interest_point_title;
                                    hyperlink.show_title = obj.show_title;
                                    hyperlink.icon = obj.icon;
                                } else if (['poi'].includes(obj.type)) {
                                    hyperlink.poi_id = obj.poi_id;
                                    hyperlink.poi_name = obj.poi_name;
                                    hyperlink.data_type = obj.data_type;
                                    hyperlink.show_title = obj.show_title;
                                    hyperlink.icon = obj.icon;
                                } else if (['question'].includes(obj.type)) {
                                    hyperlink.question_id = obj.question_id;
                                    hyperlink.question_title = obj.question_title;
                                    hyperlink.show_title = obj.show_title;
                                    hyperlink.mddid = obj.mddid;
                                    hyperlink.icon = obj.icon;
                                } else if (['member'].includes(obj.type)) {
                                    hyperlink.title = obj.title;
                                    hyperlink.icon = obj.icon;
                                } else if (['micro_app'].includes(obj.type)) {
                                    hyperlink.file_id = obj.file_id;
                                    hyperlink.micro_app_url_type = obj.micro_app_url_type;
                                    hyperlink.micro_app_url = obj.micro_app_url;
                                }

                                str.push(hyperlink);

                                next = item.replace(text[0], '');
                                next = next.replace(x, '');
                            } else {
                                let text = next.split(x, 1);

                                let obj = x.replace('!%[', '');
                                obj = obj.replace(']', '');
                                obj = JSON.parse(obj);

                                str.push({
                                    type: 'text',
                                    text: text[0]
                                });

                                let hyperlink = {
                                    type: 'hyperlink',
                                    link_type: obj.type
                                };
                                if (['normal', 'official_website'].includes(obj.type)) {
                                    hyperlink.title = obj.title;
                                    hyperlink.link = obj.link;
                                } else if (['article'].includes(obj.type)) {
                                    hyperlink.title = obj.title;
                                    hyperlink.article_id = obj.article_id;
                                } else if (['interest_point'].includes(obj.type)) {
                                    hyperlink.interest_point_id = obj.interest_point_id;
                                    hyperlink.interest_point_title = obj.interest_point_title;
                                    hyperlink.show_title = obj.show_title;
                                    hyperlink.icon = obj.icon;
                                } else if (['poi'].includes(obj.type)) {
                                    hyperlink.poi_id = obj.poi_id;
                                    hyperlink.poi_name = obj.poi_name;
                                    hyperlink.data_type = obj.data_type;
                                    hyperlink.show_title = obj.show_title;
                                    hyperlink.icon = obj.icon;
                                } else if (['question'].includes(obj.type)) {
                                    hyperlink.question_id = obj.question_id;
                                    hyperlink.question_title = obj.question_title;
                                    hyperlink.show_title = obj.show_title;
                                    hyperlink.mddid = obj.mddid;
                                    hyperlink.icon = obj.icon;
                                } else if (['member'].includes(obj.type)) {
                                    hyperlink.title = obj.title;
                                    hyperlink.icon = obj.icon;
                                } else if (['micro_app'].includes(obj.type)) {
                                    hyperlink.file_id = obj.file_id;
                                    hyperlink.micro_app_url_type = obj.micro_app_url_type;
                                    hyperlink.micro_app_url = obj.micro_app_url;
                                }

                                str.push(hyperlink);

                                next = next.replace(text[0], '');
                                next = next.replace(x, '');
                            }

                            if (key === hyperlinkArr.length - 1) {
                                str.push({type: 'text', text: next});
                            }
                        });
                    }
                } else {
                    str = [{type: 'text', text: item}];
                }
            }

            this.structure.push({
                type: 'paragraph',
                list: str
            });
        },
        formatImage(item) {
            let reg = /\!\@(.*)/;
            let image = reg.exec(item);

            let photos = JSON.parse(image[1]);

            photos.forEach(x => {
                this.structure.push({
                    type: 'image',
                    file_id: x.file_id,
                    storage_source: x.storage_source
                });
            });
        },
        formatImageAlbum(item) {
            let reg = /\!\$(.*)/;
            let image = reg.exec(item);

            let photos = JSON.parse(image[1]);

            let album = [];

            photos.forEach(x => {
                album.push({
                    file_id: x.file_id,
                    storage_source: x.storage_source
                });
            });

            this.structure.push({
                type: 'image_album',
                list: album
            });
        },
        formatVideo(item) {
            let reg = /\!\&(.*)/;
            let video = reg.exec(item);

            video = JSON.parse(video[1]);

            this.structure.push({
                type: 'video',
                file_id: video['file_id'],
                storage_source: video['storage_source']
            });
        },
        formatAnnotation(item) {
            this.structure.push({
                type: 'annotation',
                text: item.substring(2)
            });
        },
        formatPoi(item) {
            let reg = /\!\^(.*)/;
            let poi = reg.exec(item);

            poi = JSON.parse(poi[1]);

            this.structure.push({
                type: 'poi',
                poi_type: poi.type,
                poi_id: poi.poi_id,
                poi_name: poi.name,
                title: poi.title,
                poi_desc: poi.poi_desc
            });
        },
        formatCustomProduct(item) {
            let reg = /\!\%\^(.*)/;
            let product = reg.exec(item);

            product = JSON.parse(product[1]);

            this.structure.push({
                type: 'custom_product',
                name: product.name,
                product_id: product.product_id
            });
        },
        formatTourProduct(item) {
            let reg = /\!\%\@(.*)/;
            let product = reg.exec(item);

            product = JSON.parse(product[1]);
            this.structure.push({
                type: 'tour_product',
                name: product.name,
                product_id: product.product_id,
                spu_id: product.spu_id,
                product_desc: product.product_desc
            });
        },
        formatMdd(item) {
            let reg = /\!\%\*(.*)/;
            let mdd = reg.exec(item);

            mdd = JSON.parse(mdd[1]);
            this.structure.push({
                type: 'mdd',
                name: mdd.value,
                mddid: mdd.mddid,
                title: mdd.title,
                mdd_desc: mdd.mdd_desc
            });
        },
        formatYounailProduct(item) {
            let reg = /!\^&(.*)/;
            let younailProduct = reg.exec(item);

            younailProduct = JSON.parse(younailProduct[1]);
            console.log('formatYounailProduct', younailProduct);
            this.structure.push({
                type: 'younail_products',
                show_type: younailProduct.type,
                products: younailProduct.products,
            });
        },
        formatDescribe(item) {
            this.structure.push({
                type: 'describe',
                text: item.substring(3)
            });
        },
        insertInputTxt(insertTxt, type = 'start') {
            let result;
            let elInput = document.getElementById(this.editorId);
            let startPos = elInput.selectionStart;
            let endPos = elInput.selectionEnd;

            if (startPos === undefined || endPos === undefined) {
                return;
            }
            let txt = elInput.value;
            if (type === 'start') {
                result = insertTxt + txt.substring(0, startPos) + txt.substring(endPos);
            } else if (type === 'bold') {
                result = txt.substring(0, startPos) + '!*' + txt.substring(startPos, endPos) + '*!' + txt.substring(endPos);
            } else {
                result = txt.substring(0, startPos) + insertTxt + txt.substring(endPos);
            }
            elInput.value = result;
            elInput.selectionStart = startPos + insertTxt.length;
            elInput.selectionEnd = startPos + insertTxt.length;
            elInput.focus();

            this.content = elInput.value;
        },
        addPhoto() {
            let url = '/weelv/photo/uploadBatch';
            // if (this.channel === 'content') {
            // 	url = '/content/photo/upload/batch';
            // } else if (this.channel === 'custom') {
            // 	url = '/custom/photo/upload/batch';
            // } else if (this.channel === 'question') {
            // 	url = '/mdd/question/photo/upload/batch';
            // }

            this.photoUploadDisabled = true;

            let formData = new FormData();

            this.$refs.upload.$refs['upload-inner'].fileList.forEach((item, key) => {
                formData.append('file' + key, item.raw);
            });

            axios.post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}}).then(response => {
                loginCheck(response.data.http_code);
                if (response.data.request_code == 0) {
                    let image = JSON.stringify(response.data.data);
                    if (this.photoModalParams.type === 0) {
                        this.insertInputTxt('\n' + '!@' + image, 'normal');
                    } else if (this.photoModalParams.type === 1) {
                        this.insertInputTxt('\n' + '!$' + image, 'normal');
                    }

                    this.photoModal = false;
                } else {
                    this.$message.error(response.data.message);
                }

                this.photoUploadDisabled = false;
            });
            this.photoUploadDisabled = true;
        },
        addVideo() {
            let url = '/weelv/video/upload';
            // if (this.channel === 'content') {
            // 	url = '/content/video/upload';
            // } else if (this.channel === 'custom') {
            // 	url = '/custom/video/upload';
            // } else if (this.channel === 'question') {
            // 	url = '/mdd/question/video/upload';
            // }

            this.videoUploadDisabled = true;

            let formData = new FormData();
            formData.append('file', this.$refs.uploadVideo.$refs['upload-inner'].fileList[0].raw);

            axios.post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}}).then(response => {
                loginCheck(response.data.request_code);
                if (response.data.request_code == 0) {
                    this.insertInputTxt('\n' + '!&' + JSON.stringify(response.data.data), 'normal');

                    this.videoModal = false;
                } else {
                    this.$message.error(response.data.message);
                }

                this.videoUploadDisabled = false;
            });
        },
        editPhotoAlbum(item) {
            this.photoAlbumModal = true;
            this.photoAlbumModalParams.list = deepCopy(item.list);
            this.photoAlbumModalParams.original_list = deepCopy(item.list);
        },
        showHyperlinkModal() {
            this.hyperlinkModal = true;

            let elInput = document.getElementById(this.editorId);
            let startPos = elInput.selectionStart;
            let endPos = elInput.selectionEnd;

            if (startPos === undefined || endPos === undefined) {
                return;
            }

            let txt = elInput.value;

            this.hyperlinkModalParams.title = txt.substring(startPos, endPos);
        },
        addHyperlink() {
            let link = {
                type: this.hyperlinkModalParams.type
            };

            if (['normal', 'official_website'].includes(this.hyperlinkModalParams.type)) {
                link.title = this.hyperlinkModalParams.title;
                link.link = this.hyperlinkModalParams.link;
            }

            if (['micro_app'].includes(this.hyperlinkModalParams.type)) {
                link.file_id = this.hyperlinkModalParams.file_id
                link.micro_app_url_type = this.hyperlinkModalParams.micro_app_url_type;
                link.micro_app_url = this.hyperlinkModalParams.micro_app_url;
            }

            this.insertInputTxt('!%[' + JSON.stringify(link) + ']', 'normal');

            this.hyperlinkModal = false;
        },
        addPoi() {
            let poi = {
                type: this.poiModalParams.type,
                name: this.poiModalParams.poi.name,
                poi_id: this.poiModalParams.poi.poi_id,
                title: this.poiModalParams.title,
                poi_desc: this.poiModalParams.poi_desc
            };

            this.insertInputTxt('!^' + JSON.stringify(poi), 'normal');

            this.poiModal = false;
        },
        addCustomProduct() {
            let poi = {
                name: this.customProductModalParams.product.value,
                product_id: this.customProductModalParams.product.custom_product_id
            };

            this.insertInputTxt('!%^' + JSON.stringify(poi), 'normal');

            this.customProductModal = false;
        },
        addTourProduct() {
            let product = {
                name: this.tourProductModalParams.product.name,
                product_id: this.tourProductModalParams.product.product_id,
                spu_id: this.tourProductModalParams.product.spu_id,
                product_desc: this.tourProductModalParams.product_desc
            };

            this.insertInputTxt('!%@' + JSON.stringify(product), 'normal');

            this.tourProductModalParams = {
                product: {product_id: '', name: '', spu_id: ''},
                product_desc: ''
            };
            this.tourProductModal = false;
        },
        addMdd() {
            let mdd = {
                value: this.mddModalParams.mdd.value,
                mddid: this.mddModalParams.mdd.mddid,
                title: this.mddModalParams.title,
                mdd_desc: this.mddModalParams.mdd_desc
            };

            this.insertInputTxt('!%*' + JSON.stringify(mdd), 'normal');

            this.mddModalParams = {
                mdd: {mddid: '', value: ''},
                title: '',
                mdd_desc: ''
            };
            this.tourMddModal = false;
        },
        addYounailProduct() {
            let younailProducts = [];
            this.younailProductModalParams.products.forEach(function (item, index) {
                if (item.product_attribute.id) {
                    younailProducts.push({
                        product_id: item.product_attribute.product_id || 0,
                        attribute_id: item.product_attribute.id,
                        name: item.product_attribute.value,
                        file_id: item.file_id
                    })
                }
            });
            this.insertInputTxt("\n" + '!^&' + JSON.stringify({
                type: this.younailProductModalParams.type,
                products: younailProducts
            }), 'normal');
            this.younailProductModal = false;
        },
        getParagraph(item) {
            if (item.type === 'text') {
                return item.text;
            } else if (item.type === 'hyperlink') {
                if (['normal', 'official_website'].includes(item.link_type)) {
                    return `<a href="${item.link}" target="_blank">${item.title}</a>`;
                } else if (['article'].includes(item.link_type)) {
                    return `<a href="/#/contentMgt/article/update/${item.article_id}" target="_blank">${item.title}</a>`;
                } else if (['interest_point'].includes(item.link_type)) {
                    return `<a href="${item.interest_point_id}" target="_blank">${item.show_title || item.interest_point_title}</a>`;
                } else if (['poi'].includes(item.link_type)) {
                    return `<a href="${item.poi_id}" target="_blank">${item.show_title || item.poi_name}</a>`;
                } else if (['question'].includes(item.link_type)) {
                    return `<a href="${item.question_id}" target="_blank">${item.show_title || item.question_title}</a>`;
                } else if (['member'].includes(item.link_type)) {
                    return `<a href="/" target="_blank">${item.title}</a>`;
                } else if (['micro_app'].includes(item.link_type)) {
                    return `<div>
                            <img src="${photoHost[1]}/${item.file_id}?x-oss-process=image/resize,h_300" style="max-width: 100%;"/>
                            <br/>
                            <span>跳转类型：${{'normal': '普通', 'tabbar': 'tabbar'}[item.micro_app_url_type]}</span>
                            <br/>
                            <span>地址：${item.micro_app_url}</span>
                        </div>`;
                }
            }
        },
        getStructuralContent() {
            this.$emit('getStructuralContent', this.structure);
        },
        getWordsNumber() {
            let that = this;
            that.wordsNumber = 0;
            this.structure.forEach(function (value) {
                if (value.type) {
                    if (['title', 'sub_title'].includes(value.type) && value.text) {
                        that.wordsNumber += value.text.toString().length;
                    }

                    if (value.type === 'paragraph' && value.list && Array.isArray(value.list)) {
                        value.list.forEach(function (v) {
                            if (v.type && v.type === 'text') {
                                that.wordsNumber += v.text.toString().length;
                            }
                        })
                    }
                }
            })
        },
        dragImageStart(file_id) {
            event.dataTransfer.setData('file_id', file_id);
        },
        dragImageOver(event) {
            event.preventDefault();
        },
        dropImage(event) {
            let list = [];
            this.photoAlbumModalParams.list.forEach(item => {
                if (item.file_id === event.dataTransfer.getData('file_id')) {
                    list.push({
                        file_id: event.toElement.id,
                        storage_source: 1
                    });
                } else if (item.file_id === event.toElement.id) {
                    list.push({
                        file_id: event.dataTransfer.getData('file_id'),
                        storage_source: 1
                    });
                } else {
                    list.push({
                        file_id: item.file_id,
                        storage_source: item.storage_source
                    });
                }
            });

            this.photoAlbumModalParams.list = deepCopy(list);
        },
        async savePhotoAlbum() {
            let url = '/weelv/photo/uploadBatch';

            this.photoUploadDisabled = true;

            let content = '!$' + JSON.stringify(this.photoAlbumModalParams.original_list);

            if (this.$refs.editUploadPhotoAlbum.$refs['upload-inner'].fileList.length > 0) {
                let formData = new FormData();
                this.$refs.editUploadPhotoAlbum.$refs['upload-inner'].fileList.forEach((item, key) => {
                    formData.append('file' + key, item.raw);
                });

                await axios.post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}}).then(response => {
                    loginCheck(response.data.request_code);
                    if (response.data.request_code === 0) {
                        this.photoAlbumModalParams.list = this.photoAlbumModalParams.list.concat(response.data.data);

                        let newList = '!$' + JSON.stringify(this.photoAlbumModalParams.list);

                        this.content = this.content.replace(content, newList);
                        this.photoAlbumModal = false;
                    } else {
                        this.$message.error(response.data.message);
                    }

                    this.photoUploadDisabled = false;
                });
            } else {
                let newList = '!$' + JSON.stringify(this.photoAlbumModalParams.list);

                this.content = this.content.replace(content, newList);
                this.photoAlbumModal = false;
                this.photoUploadDisabled = false;
            }
        },
        photoAlbumHandle(file_id) {
            this.photoAlbumHandleModal = true;
            this.photoAlbumHandleModalFileId = file_id;
        },
        deletePhotoAlbumItem() {
            let list = [];
            this.photoAlbumModalParams.list.forEach(item => {
                if (item.file_id !== this.photoAlbumHandleModalFileId) {
                    list.push(item);
                }
            });

            this.photoAlbumModalParams.list = deepCopy(list);
            this.photoAlbumHandleModal = false;
        },
        handlePaste(event) {
            const items = (event.clipboardData || window.clipboardData).items;
            let file = null;
            if (!items || items.length === 0) {
                this.$message.error('当前浏览器不支持本地');
                return;
            }
            // 搜索剪切板items
            console.log('items.length:', items.length);
            for (let i = 0; i < items.length; i++) {
                console.log('items type:', items[i].type);
                if (items[i].type.indexOf('image') !== -1) {
                    file = items[i].getAsFile();
                    break;
                }
            }
            if (file) {
                let uploadNotify = this.$notify({
                    title: '提示',
                    message: '上传中~~~~~~~',
                    duration: 0
                });

                let url = '/weelv/photo/uploadBatch';

                let formData = new FormData();
                formData.append('file0', file);
                axios.post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}}).then(response => {
                    loginCheck(response.data.request_code);
                    if (response.data.request_code === 0) {
                        let image = JSON.stringify(response.data.data);
                        this.insertInputTxt('\n' + '!@' + image, 'normal');
                        this.$notify({
                            title: '成功',
                            message: '上传成功',
                            type: 'success'
                        });
                    } else {
                        this.$message.error(response.data.message);
                    }
                    uploadNotify.close();
                });
            }

        },
        selectedAttribute(val) {
            let productAttribute = this.younailProductModalParams.products[this.younailProductIndex];

            if (productAttribute.file_id == '') {
                productAttribute.file_id = val.image_cover || '';
            }
            productAttribute.product_attribute = val;

            this.younailProductModalParams.products[this.younailProductIndex] = productAttribute;
        },
        // 选择
        selectYounailProduct(index) {
            this.younailProductIndex = index;
        },
        // 上传图片
        async uploadYounailProductPhotoRelation(paramsinfo) {
            let that = this;
            let fileList = [];
            let obj = {};
            let younailProduct = that.younailProductModalParams.products[this.younailProductIndex];

            obj.raw = paramsinfo.file;
            fileList.push(obj)

            let url = '/weelv/photo/uploadBatch';
            let formData = new FormData();
            fileList.forEach((item, key) => {
                formData.append('file' + key, item.raw);
            });

            axios.post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}}).then(response => {
                loginCheck(response.data.http_code);
                if (response.data.request_code == 0) {
                    this.$message.success('上传成功');
                    response.data.data.forEach(item => {
                        younailProduct.file_id = item.file_id;
                    });
                } else {
                    this.$message.error(response.data.message);
                }
            });

            that.younailProductModalParams.fileList = [];
            that.younailProductModalParams.products[that.younailProductIndex] = younailProduct;
        },
        // 新增商品
        addYounailProductItem() {
            let singleProduct = {};
            singleProduct.product_attribute = {id: '', name: '', product_id: ''};
            singleProduct.file_id = '';
            this.younailProductModalParams.products.push(singleProduct)
        },
        // 删除商品
        delYounailProduct(x) {
            if (this.younailProductModalParams.products.length > 0) {
                this.younailProductModalParams.products.splice(x, 1);
            }
        },
        // 图片回调
        imgCallBack(val) {
            let qual = this.younailProductModalParams.products[this.younailProductIndex];
            qual.file_id = val;
        },
        // 上传图片
        async uploadHyperlinkPhotoRelation(paramsinfo) {
            let that = this;
            let fileList = [];
            let obj = {};

            obj.raw = paramsinfo.file;
            fileList.push(obj)

            let url = '/weelv/photo/uploadBatch';
            let formData = new FormData();
            fileList.forEach((item, key) => {
                formData.append('file' + key, item.raw);
            });

            axios.post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}}).then(response => {
                loginCheck(response.data.http_code);
                if (response.data.request_code == 0) {
                    this.$message.success('上传成功');
                    response.data.data.forEach(item => {
                        that.hyperlinkModalParams.file_id = item.file_id;
                    });
                } else {
                    this.$message.error(response.data.message);
                }
            });
        },
        uploadHyperlinkPhotoCallBack(val) {
            this.hyperlinkModalParams.file_id = val;
        },
    }
};
</script>

<style lang="scss" scoped>
.editor-container {
    width: 100%;

    .toolbox {
        position: relative;
        top: 0;
        border: 1px solid #e3e3e3;
        padding: 5px;

        span {
            font-size: 16px;
            line-height: 20px;
            padding: 1px 2px;
            color: #666;
            font-weight: 700;
            margin-right: 3px;
            border-radius: 2px;
            cursor: pointer;
        }

        span:hover {
            background-color: #e6e6e6;
            border: 1px solid #ddd;
        }
    }

    .main {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        border: 1px solid #e3e3e3;
        word-break: break-all;
        letter-spacing: 1px;

        .show-font-sum {
            position: absolute;
            right: calc(50% + 10px);
            bottom: 0px;
            color: #23C1A2;
            font-weight: 600;
        }

        .left-input {
            width: 50%;
            padding: 5px 10px;
            max-height: 600px;
            overflow-x: scroll;

            textarea {
                border: none;
                width: 100%;
                outline: none;
                resize: none;
                font-size: 16px;
                line-height: 24px;
            }
        }

        .right-html {
            overflow-x: scroll;
            right: 0;
            max-height: 600px;
            width: 50%;
            border-left: 1px solid #e3e3e3;
            font-size: 16px;
            line-height: 24px;
            padding: 5px 10px;

            .title {
                font-size: 20px;
                font-weight: 700;
                margin: 10px;
                text-align: center;
            }

            .sub-title {
                font-size: 18px;
                font-weight: 600;
                margin: 10px;
            }

            .paragraph {
                font-size: 14px;
                margin: 10px 5px;
            }

            .image-album {
                display: flex;
                flex-direction: column;

                div:first-child {
                    display: flex;
                    flex-direction: row;
                    overflow-y: scroll;

                    img {
                        margin: 5px;
                    }
                }
            }

            .video {
                margin: 5px;

                video {
                    max-width: 100%;
                }
            }

            .annotation {
                font-size: 12px;
                margin: 5px;
                color: #999;
            }

            .poi {
                font-size: 12px;
                margin: 5px;
                color: red;
            }

            .custom-product {
                font-size: 12px;
                margin: 5px;
                color: cadetblue;
            }

            .tour-product {
                font-size: 12px;
                margin: 5px;
                color: chocolate;
            }

            .mdd {
                font-size: 12px;
                margin: 5px;
                color: blue;
            }

            .describe {
                font-size: 12px;
                margin: 5px;
                color: grey;
            }
        }
    }

    .edit-photo-album-list {
        overflow-y: scroll;
        display: flex;
        flex-direction: row;

        img {
            margin-right: 10px;
        }
    }
}

.add-image-btn::before {
    width: 16px;
    height: 16px;
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    background: url('~@/assets/images/content/add_image_btn.png') no-repeat center center/cover;
}

.add-video-btn::before {
    width: 16px;
    height: 16px;
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    background: url('~@/assets/images/content/add_video_btn.png') no-repeat center center/cover;
}

.add-hyperlink-btn::before {
    width: 16px;
    height: 16px;
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    background: url('~@/assets/images/content/add_hyperlink_btn.png') no-repeat center center/cover;
}

.add-annotation-btn::before {
    width: 16px;
    height: 16px;
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    background: url('~@/assets/images/content/add_annotation_btn.png') no-repeat center center/cover;
}

.add-poi-btn::before {
    width: 16px;
    height: 16px;
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    background: url('~@/assets/images/content/add_poi_btn.png') no-repeat center center/cover;
}

.add-custom_product-btn::before {
    width: 16px;
    height: 16px;
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    background: url('~@/assets/images/content/add_custom_product_btn.png') no-repeat center center/cover;
}

.add-tour_product-btn::before {
    width: 16px;
    height: 16px;
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    background: url('~@/assets/images/content/add_tour_product_btn.png') no-repeat center center/cover;
}

.add-mdd-btn::before {
    width: 16px;
    height: 16px;
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    background: url('~@/assets/images/content/add_mdd_btn.png') no-repeat center center/cover;
}

.add-younail-product-btn::before {
    width: 16px;
    height: 16px;
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    background: url('~@/assets/images/content/add_younail_product.png') no-repeat center center/cover;
}

.add-describe-btn::before {
    width: 16px;
    height: 16px;
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    background: url('~@/assets/images/content/add_describe_btn.png') no-repeat center center/cover;
}

.younail-product-item {
    display: flex;
    justify-content: space-between;
}

.younail-product-item-bg {
    position: relative;
}

.younail-product-item-name {
    position: absolute;
    bottom: 0;
    left: 40px;
    z-index: 2;
    width: 100%;
    height: 30px;
    color: #ffffff;
}
</style>
