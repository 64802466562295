<template>
    <div class="img-list">
        <div class="preve-img-item mg-r10 mg-t10" v-if="image_cover !== ''">
            <img class="preve-img" :style="{'width':width+'px','height':height+'px' }"
                 :src="`${photoHost[1]}/${image_cover}?x-oss-process=image/resize,w_${width},h_${height}`"/>
            <div class="preve-cover">
                <div class="preve-btn-img" @click="showImg(image_cover)">
                    <i class="el-icon-zoom-in colffffff f22 fw600"></i>
                </div>
                <div class="preve-btn-img mg-l10" v-if="isshow" @click="delImg('')">
                    <i class="el-icon-delete colffffff f22 fw600"></i>
                </div>
            </div>
        </div>
        <div class="w-all flex-row mg-t10" v-if="image_album && image_album.length >0">
            <div class="preve-img-item mg-r10" v-for="(item,index) in image_album" :key="index">
                <img class="preve-img" :style="{'width':width+'px','height':height+'px' }"
                     :src="`${photoHost[1]}/${item}?x-oss-process=image/resize,w_${width},h_${height}`"/>
                <div class="preve-cover">
                    <div class="preve-btn-img" @click="showImg(item)">
                        <i class="el-icon-zoom-in colffffff f22 fw600"></i>
                    </div>
                    <div class="preve-btn-img mg-l10" v-if="isshow" @click="delImg(index)">
                        <i class="el-icon-delete colffffff f22 fw600"></i>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog :append-to-body="true" title="" width="80%" :visible.sync="preveDialogVisible" :center="true">
            <div class="flex-row flex-js-center">
                <img class="preve-dialog-img" :src="`${photoHost[1]}/${preveImgUrl}`"/>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import StateObject from '@/utils/stateField.js';

export default {
    props: {
        image_cover: {
            type: String,
            default: '',
            required: false
        },
        image_album: {
            type: Array,
            default: () => {
                return [];
            },
            required: false
        },
        width: {
            type: String,
            default: '160',
            required: false
        },
        height: {
            type: String,
            default: '120',
            required: false
        },
        isshow: {
            type: Boolean,
            default: true,
            required: false
        },
    },
    watch: {
        image_cover(nv) {
            if (nv) {
                this.imageCover = nv;
            }
        },
        image_album(nv) {
            this.imageAlbum = nv;
        }
    },
    data() {
        return {
            photoHost: StateObject.photoHost,
            preveDialogVisible: false,
            preveImgUrl: '',
            imageCover: '',
            imageAlbum: [],
        };
    },
    methods: {
        // 图片预览
        showImg(url) {
            this.preveDialogVisible = true;
            this.preveImgUrl = url;
        },
        // 产出图片
        delImg(index) {
            let that = this;
            this.$confirm('确认删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error'
            }).then(async () => {
                if (index === '') {
                    that.imageCover = '';
                    this.$emit('change', that.imageCover);
                } else {
                    that.imageAlbum.splice(index, 1);
                    this.$emit('change', that.imageAlbum);
                }
            }).catch(() => {
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.fw600 {
    font-weight: 600 !important;
}


.img-list {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.img-list .preve-img-item {
    height: 120px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}

.img-list .preve-img-item .preve-img {
    height: 120px;
    object-fit: cover;
    display: flex;
    z-index: 1;
}

.img-list .preve-img-item .preve-cover {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.2);
    z-index: 2;
}

.img-list .preve-img-item .preve-btn-img {
    width: 32px;
    height: 32px;
    cursor: pointer;
}
</style>
