import {
    YOUNAIL_QUESTIONS_GET_LIST,
    YOUNAIL_QUESTIONS_GET_ADD_STATUS,
    YOUNAIL_QUESTIONS_GET_DEL_STATUS,
    YOUNAIL_QUESTIONS_GET_UPD_STATUS,
    YOUNAIL_QUESTIONS_GET_DETAIL_BY_ID,
    YOUNAIL_QUESTIONS_GET_ADD_TAG_STATUS,
    YOUNAIL_QUESTIONS_GET_UPLOAD_IMG_STATUS,
    YOUNAIL_QUESTIONS_GET_UPLOAD_VIDEO_STATUS,
    YOUNAIL_QUESTIONS_GET_IMPORT_STATUS,
    YOUNAIL_QUESTIONS_GET_DISTRIBUTION_STATUS,
} from '@/store/mutations'
import {
    loginCheck
} from '@/utils/loginCheck'
import {
    Message,
    Notification
} from 'element-ui'

const state = {
    questionList: {},
    addQuestionStatus: false,
    updQuestionStatus: false,
    delQuestionStatus: false,
    questionDetail: {},
    uploadPhotoResult: false,
    uploadImgFile: [],
    uploadVideoFile: false,
    tagAddTagStatus: false,
    importQuestionStatus: false,
    distributionQuestionStatus: false,
}

const getters = {}

// actions
const actions = {
    // 获取问题列表
    async [YOUNAIL_QUESTIONS_GET_LIST]({commit, state}, action) {
        await axios.get('/younail/question/list', {
            params: {
                page: action.params.page,
                page_size: action.params.page_size,
                question_id: action.params.question_id || '',
                title: action.params.title || '',
                content_status: action.params.content_status || '',
                baidu_qa: action.params.baidu_qa || '',
                baidu_feedback_status: action.params.baidu_feedback_status || '',
                audit_status: action.params.audit_status || '',
                user_id: action.params.user_id,
                month: action.params.month,
            }
        }).then(response => {
            loginCheck(response.data.request_code)
            if (response.data.request_code === 0) {
                commit(action.type, response.data.data)
            } else {
                Message.error(response.data.message)
            }
        })
    },

    // 创建问题
    async [YOUNAIL_QUESTIONS_GET_ADD_STATUS]({commit, state}, action) {
        commit(action.type, false)

        await axios.post('/younail/question/create', {
            title: action.params.title || '',
            domain: action.params.domain || '',
            baidu_qa: action.params.baidu_qa || 0,
            headline: action.params.headline || '',
            extra_headline: action.params.extra_headline || '',
            description: action.params.description || '',
            timeliness: action.params.timeliness || 0,
            expiration_date: action.params.expiration_date || '',
            user_id: action.params.user_id || 0,
            tag_ids: action.params.tag_ids || '',
            mddid: action.params.mddid || 0,
            poi_id: action.params.poi_id || 0,
            checked_desc: action.params.checked_desc || '',
            short_content: action.params.short_content || '',
            origin_content: action.params.origin_content || '',
            content: action.params.content || '',
            is_recommend: action.params.is_recommend || 0,
            status: action.params.status || 2,
            attribute_ids: action.params.attribute_ids || '',
            audit_status: action.params.audit_status || 1,
            baidu_submit_status: action.params.baidu_submit_status || 0,
            baidu_feedback_status: action.params.baidu_feedback_status || 0,
            image_cover: action.params.image_cover || '',
            image_info: action.params.image_info || '',
        }).then(response => {
            loginCheck(response.data.request_code)
            if (response.data.request_code === 0) {
                commit(action.type, response.data.data)
            } else {
                Message.error(response.data.message)
            }
        })
    },

    // 编辑问题
    async [YOUNAIL_QUESTIONS_GET_UPD_STATUS]({commit, state}, action) {
        commit(action.type, false)

        await axios.post('/younail/question/update', {
            id: action.params.id,
            title: action.params.title || '',
            domain: action.params.domain || '',
            baidu_qa: action.params.baidu_qa || 0,
            headline: action.params.headline || '',
            extra_headline: action.params.extra_headline || '',
            description: action.params.description || '',
            timeliness: action.params.timeliness || 0,
            expiration_date: action.params.expiration_date || '',
            user_id: action.params.user_id || 0,
            tag_ids: action.params.tag_ids || '',
            mddid: action.params.mddid || 0,
            poi_id: action.params.poi_id || 0,
            checked_desc: action.params.checked_desc || '',
            short_content: action.params.short_content || '',
            origin_content: action.params.origin_content || '',
            content: action.params.content || '',
            is_recommend: action.params.is_recommend || 0,
            status: action.params.status || 2,
            attribute_ids: action.params.attribute_ids || '',
            audit_status: action.params.audit_status || 1,
            baidu_submit_status: action.params.baidu_submit_status || 0,
            baidu_feedback_status: action.params.baidu_feedback_status || 0,
            image_cover: action.params.image_cover || '',
            image_info: action.params.image_info || '',
        }).then(response => {
            loginCheck(response.data.request_code)
            if (response.data.request_code === 0) {
                commit(action.type, response.data.data)
            } else {
                Message.error(response.data.message)
            }
        })
    },

    // 删除问题
    async [YOUNAIL_QUESTIONS_GET_DEL_STATUS]({commit, state}, action) {
        commit(action.type, false)
        await axios.post(`/younail/question/delete`, {
            id: action.params.id
        }).then(response => {
            if (response.data.request_code === 0) {
                commit(action.type, response.data.data)
            } else {
                Message.error(response.data.message)
            }
        })
    },

    // 获取问题详情
    [YOUNAIL_QUESTIONS_GET_DETAIL_BY_ID]({commit, state}, action) {
        let id = action.params.id || ''
        let title = action.params.title || ''
        let question_id = action.params.question_id || ''
        let content_status = action.params.content_status || ''
        let baidu_qa = action.params.baidu_qa || ''
        let baidu_feedback_status = action.params.baidu_feedback_status || ''
        let audit_status = action.params.audit_status || ''
        let user_id = action.params.user_id || ''
        let month = action.params.month || ''
        axios.get(`/younail/question/detail?id=${id}&title=${title}&question_id=${question_id}&content_status=${content_status}&baidu_qa=${baidu_qa}&baidu_feedback_status=${baidu_feedback_status}&audit_status=${audit_status}&user_id=${user_id}&month=${month}`).then(response => {
            if (response.data.request_code === 0) {
                commit(action.type, response.data.data)
            } else {
                Message.error(response.data.message)
            }
        })
    },
    // 获取标签列表
    async [YOUNAIL_QUESTIONS_GET_ADD_TAG_STATUS]({commit, state}, action) {
        commit(action.type, false)
        let name = action.params.name
        await axios.get(`/younail/tag/findByName?name=${name}`).then(response => {
            if (response.data.request_code === 0) {
                commit(action.type, response.data.data)
            } else {
                Message.error(response.data.message)
            }
        })
    },
    // 上传图片
    async [YOUNAIL_QUESTIONS_GET_UPLOAD_IMG_STATUS]({commit, state}, action) {
        commit(action.type, false)
        let formData = new FormData()
        formData.append('file0', action.params.files[0].raw)
        formData.append('width', action.params.width || '')
        formData.append('height', action.params.height || '')
        await axios.post('/younail/photo/uploadBatch', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            if (response.data.request_code === 0) {
                commit(action.type, response.data.data)
            } else {
                Message.error(response.data.message)
            }
        })
    },
    // 上传视频
    async [YOUNAIL_QUESTIONS_GET_UPLOAD_VIDEO_STATUS]({commit, state}, action) {
        commit(action.type, false)
        let formData = new FormData()
        formData.append('file', action.params.files[0].raw)
        await axios.post('/younail/video/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            if (response.data.request_code === 0) {
                commit(action.type, response.data.data)
            } else {
                Message.error(response.data.message)
            }
        })
    },
    async [YOUNAIL_QUESTIONS_GET_IMPORT_STATUS]({commit, state}, action) {
        commit(action.type, false)

        let formData = new FormData()
        formData.append('file', action.params.file)

        await axios.post('/younail/question/import', formData, {headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => {
                loginCheck(response.data.request_code)
                if (response.data.request_code === 0) {
                    commit(action.type, response.data.data)
                } else {
                    Notification.error(response.data.message)
                }
            })
    },
    async [YOUNAIL_QUESTIONS_GET_DISTRIBUTION_STATUS]({commit, state}, action) {
        commit(action.type, false)

        await axios.post('/younail/question/distribution', {
            user_id: action.params.user_id || '',
            question_ids: action.params.question_ids || '',
        }).then(response => {
            loginCheck(response.data.request_code)
            if (response.data.request_code === 0) {
                commit(action.type, response.data.data)
            } else {
                Notification.error(response.data.message)
            }
        })
    },
}

// mutations
const mutations = {
    [YOUNAIL_QUESTIONS_GET_LIST](state, data) {
        state.questionList = data
    },
    [YOUNAIL_QUESTIONS_GET_ADD_STATUS](state, data) {
        state.addQuestionStatus = data
    },
    [YOUNAIL_QUESTIONS_GET_DEL_STATUS](state, data) {
        state.delQuestionStatus = data
    },
    [YOUNAIL_QUESTIONS_GET_UPD_STATUS](state, data) {
        state.updQuestionStatus = data
    },
    [YOUNAIL_QUESTIONS_GET_DETAIL_BY_ID](state, data) {
        state.questionDetail = data
    },
    [YOUNAIL_QUESTIONS_GET_ADD_TAG_STATUS](state, data) {
        state.tagAddTagStatus = data
    },
    [YOUNAIL_QUESTIONS_GET_UPLOAD_IMG_STATUS](state, data) {
        state.uploadImgFile = data
    },
    [YOUNAIL_QUESTIONS_GET_UPLOAD_VIDEO_STATUS](state, data) {
        state.uploadVideoFile = data
    },
    [YOUNAIL_QUESTIONS_GET_IMPORT_STATUS](state, data) {
        state.importQuestionStatus = data
    },
    [YOUNAIL_QUESTIONS_GET_DISTRIBUTION_STATUS](state, data) {
        state.distributionQuestionStatus = data
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
