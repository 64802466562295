<template>
    <div class="content-tag-index">
        <el-card>
            <div slot="header">
                <span>标签列表</span>
                <el-button size="mini" type="primary" class="add-new-btn" @click="showAddModal">新建标签</el-button>
            </div>

            <div>
                <el-table
                    :data="list.data"
                >
                    <el-table-column
                        prop="id"
                        label="标签ID"
                    ></el-table-column>
                    <el-table-column
                        prop="name"
                        label="标签名称"
                    ></el-table-column>
                    <el-table-column
                        prop="is_hot"
                        label="热门标签"
                    >
                        <template slot-scope="scope">
                            <el-tag :type="scope.row.is_hot?'danger':''" @click="changeHot(scope.row.id,scope.row.is_hot)">{{ { 0: '冷门', 1: '热门'}[scope.row.is_hot] }}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="操作"
                    >
                        <template slot-scope="scope">
                            <el-button size="mini" type="primary" @click="showEditModal(scope.row)">编辑</el-button>
                            <el-button size="mini" type="danger" @click="deleteTag(scope.row.id)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <div class="pagination">
                    <el-pagination
                        background
                        layout="prev, pager, next"
                        :total="list.total"
                        :page-size="pageSize"
                        :current-page="list.page"
                        @current-change="pageChange"
                        @prev-click="pageChange"
                        @next-click="pageChange"
                    ></el-pagination>
                </div>
            </div>
        </el-card>

        <el-dialog :title="editModalTitle" :visible.sync="editModal">
            <el-form :model="editModalParams">
                <el-form-item label="标签名称" :label-width="'80px'">
                    <el-input size="small" placeholder="长度不能超过16个字符" v-model="editModalParams.name"></el-input>
                </el-form-item>
                <el-form-item label="是否热门" :label-width="'80px'">
                    <el-select
                        v-model="editModalParams.is_hot"
                        size="small"
                        placeholder="请选择是否热门"
                    >
                        <el-option label="冷门" value="0"></el-option>
                        <el-option label="热门" value="1"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="editModal = false">取 消</el-button>
                <el-button size="small" type="primary" @click="editModalTitle === '新建标签' ? addTag() : updateTag()">保 存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import {
        WEELV_QUESTIONS_TAG_LIST,
        WEELV_QUESTIONS_TAG_CREAT,
        WEELV_QUESTIONS_TAG_HOT_UPDATE,
        WEELV_QUESTIONS_TAG_UPDATE,
        WEELV_QUESTIONS_TAG_DELETE
    } from '@/store/mutations'

    export default {
        computed: {
            ...mapState({
                list: state => state.questionTag.tagList,
                addStatus: state => state.questionTag.creteState,
                updateStatus: state => state.questionTag.updateState,
                deleteStatus: state => state.questionTag.deleteState,
                questionStatus: state => state.questionTag.changeStatus,
            })
        },
        mounted() {
            this.getTagList()
        },
        watch: {
            name(value) {
                if (value.length === 0) {
                    this.getTagList()
                }
            },
            type(value) {
                if (value === '') {
                    this.type = null
                }
            },

        },
        data() {
            return {
                page: 1,
                pageSize: 20,
                editModal: false,
                editModalTitle: '',
                editModalParams: {
                    tag_id: '',
                    name: '',
                    is_hot: ''
                }
            }
        },
        methods: {
            ...mapActions({
                WEELV_QUESTIONS_TAG_LIST,
                WEELV_QUESTIONS_TAG_CREAT,
                WEELV_QUESTIONS_TAG_HOT_UPDATE,
                WEELV_QUESTIONS_TAG_UPDATE,
                WEELV_QUESTIONS_TAG_DELETE
            }),
            getTagList() {
                this[WEELV_QUESTIONS_TAG_LIST]({ type: WEELV_QUESTIONS_TAG_LIST,
                    params: {
                        page: this.page,
                        page_size: this.pageSize,
                    }
                })
            },
            pageChange(page) {
                this.page = page
                this.getTagList()
            },
            showAddModal() {
                this.editModal = true
                this.editModalTitle = '新建标签'
                this.editModalParams.name = ''
                this.editModalParams.is_hot = ''
            },
            showEditModal(item) {
                this.editModal = true
                this.editModalTitle = '编辑标签'
                this.editModalParams.tag_id = item.id
                this.editModalParams.name = item.name
                this.editModalParams.is_hot = item.is_hot.toString()
            },
            async changeHot(tag_id, is_hot){
                if (is_hot == 1){
                    is_hot = 0
                } else{
                    is_hot = 1
                }
                await this[WEELV_QUESTIONS_TAG_HOT_UPDATE]({ type: WEELV_QUESTIONS_TAG_HOT_UPDATE,
                    params: {
                        tag_id: tag_id,
                        is_hot: is_hot,
                    }
                })

                if (this.questionStatus) {
                    this.$message.success('更新成功')
                    this.page = this.list.page
                    this.getTagList()
                }
            },
            async addTag() {
                await this[WEELV_QUESTIONS_TAG_CREAT]({ type: WEELV_QUESTIONS_TAG_CREAT,
                    params:{
                        name: this.editModalParams.name,
                        is_hot: this.editModalParams.is_hot
                    }
                })
                if (this.addStatus) {
                    this.$message.success('创建标签成功')
                    this.editModal = false
                    this.getTagList()
                }
            },
            async updateTag() {
                await this[WEELV_QUESTIONS_TAG_UPDATE]({ type: WEELV_QUESTIONS_TAG_UPDATE,
                    params:{
                        tag_id: this.editModalParams.tag_id,
                        name: this.editModalParams.name,
                        is_hot: this.editModalParams.is_hot
                    }
                })
                if (this.updateStatus) {
                    this.$message.success('更新标签成功')
                    this.editModal = false
                    this.getTagList()
                }
            },
            async deleteTag(id) {
                if (confirm('确认删除？')) {
                    await this[WEELV_QUESTIONS_TAG_DELETE]({ type: WEELV_QUESTIONS_TAG_DELETE,
                        params:{
                            tag_id: id
                        }
                    })
                    if (this.deleteStatus) {
                        this.$message.success('删除标签成功')
                        this.getTagList()
                    }
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
