import {
    SPIDER_PET_WENDA_GET_LIST,
    SPIDER_PET_WENDA_GET_DETAIL,
} from '@/store/mutations'

import {
    loginCheck
} from '@/utils/loginCheck'

import {
    Message,
    Notification
} from 'element-ui'

const state = {
    list: {},
    detail: {},
}

const getters = {}

// actions
const actions = {
    async [SPIDER_PET_WENDA_GET_LIST]({commit, state}, action) {
        await axios.get('/spider/petWenda/list', {
            params: {
                page: action.params.page,
                page_size: action.params.page_size,
                keyword: action.params.keyword || '',
            }
        }).then(response => {
            loginCheck(response.data.request_code)
            if (response.data.request_code === 0) {
                commit(action.type, response.data.data)
            } else {
                Message.error(response.data.message)
            }
        })
    },
    async [SPIDER_PET_WENDA_GET_DETAIL]({commit, state}, action) {
        await axios.get('/spider/petWenda/detail', {
            params: {
                id: action.params.id,
            }
        }).then(response => {
            loginCheck(response.data.request_code)
            if (response.data.request_code === 0) {
                commit(action.type, response.data.data)
            } else {
                Message.error(response.data.message)
            }
        })
    },
}


// mutations
const mutations = {
    [SPIDER_PET_WENDA_GET_LIST](state, data) {
        state.list = data
    },
    [SPIDER_PET_WENDA_GET_DETAIL](state, data) {
        state.detail = data
    },

}

export default {
    state,
    getters,
    actions,
    mutations
}
