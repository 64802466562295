import {
  SOUGOU_LIZHI_URL_CREATE,
  SOUGOU_LIZHI_URL_DELETE,
  SOUGOU_LIZHI_URL_GET_LIST,
  SOUGOU_LIZHI_URL_UPLOAD,
  SOUGOU_LIZHI_XML_GET_LIST,
  SOUGOU_XML_GET_WEELV_LIST,
  SOUGOU_XML_GET_YOUNAIL_LIST,
} from '@/store/mutations'

import {loginCheck} from '@/utils/loginCheck'

import {Message} from 'element-ui'

const state = {
  uploadStatus: false,
  xmlList: {},
  urlList: {},
  deleteUrlStatus: false,
  createUrlStatus: false,
  weelvXmlList: {},
  younailXmlList: {},
}

const getters = {}

// actions
const actions = {
    async [SOUGOU_LIZHI_URL_UPLOAD]({commit, state}, action) {
        commit(action.type, false)
        let formData = new FormData()
        formData.append('file', action.params.file.raw)
        await axios.post('/sougou/url/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            loginCheck(response.data.request_code)
            if (response.data.request_code === 0) {
                commit(action.type, response.data.data)
            } else {
                Message.error(response.data.message)
            }
        })
    },
    async [SOUGOU_LIZHI_XML_GET_LIST]({commit, state}, action) {
        commit(action.type, {})
        await axios.get('/sougou/xmlList', {
            params: {}
        }).then(response => {
            loginCheck(response.data.request_code)
            if (response.data.request_code === 0) {
                commit(action.type, response.data.data)
            } else {
                Message.error(response.data.message)
            }
        })
    },
    async [SOUGOU_LIZHI_URL_DELETE]({commit, state}, action) {
        await axios.post('/sougou/url/delete', {
            id: action.params.id,
        }).then(response => {
            loginCheck(response.data.request_code)
            if (response.data.request_code === 0) {
                commit(action.type, response.data.data)
            } else {
                Message.error(response.data.message)
            }
        })
    },
    async [SOUGOU_LIZHI_URL_GET_LIST]({commit, state}, action) {
        await axios.get('/sougou/url/list', {
            params: {
                page: action.params.page,
                page_size: action.params.pageSize,
                url: action.params.url || '',
            }
        }).then(response => {
            loginCheck(response.data.request_code)
            if (response.data.request_code === 0) {
                commit(action.type, response.data.data)
            } else {
                Message.error(response.data.message)
            }
        })
    },
    async [SOUGOU_LIZHI_URL_CREATE]({commit, state}, action) {
        await axios.post('/sougou/url/append', {
          urls: action.params.urls || '',
        }).then(response => {
          loginCheck(response.data.request_code)
          if (response.data.request_code === 0) {
            commit(action.type, response.data.data)
          } else {
            Message.error(response.data.message)
          }
        })
    },
  async [SOUGOU_XML_GET_WEELV_LIST]({commit, state}, action) {
    await axios.get('/sougou/weelvXmlList', {
      params: {
        page: action.params.page,
        page_size: action.params.pageSize,
      }
    }).then(response => {
      loginCheck(response.data.request_code)
      if (response.data.request_code === 0) {
        commit(action.type, response.data.data)
      } else {
        Message.error(response.data.message)
      }
    })
  },
  async [SOUGOU_XML_GET_YOUNAIL_LIST]({commit, state}, action) {
    await axios.get('/sougou/younailXmlList', {
      params: {
        page: action.params.page,
        page_size: action.params.pageSize,
      }
    }).then(response => {
      loginCheck(response.data.request_code)
      if (response.data.request_code === 0) {
        commit(action.type, response.data.data)
      } else {
        Message.error(response.data.message)
      }
    })
  },
}


// mutations
const mutations = {
  [SOUGOU_LIZHI_URL_UPLOAD](state, data) {
    state.uploadStatus = data
  },
  [SOUGOU_LIZHI_XML_GET_LIST](state, data) {
    state.xmlList = data
  },
  [SOUGOU_LIZHI_URL_DELETE](state, data) {
    state.deleteUrlStatus = data
  },
  [SOUGOU_LIZHI_URL_GET_LIST](state, data) {
    state.urlList = data
  },
  [SOUGOU_LIZHI_URL_CREATE](state, data) {
    state.createUrlStatus = data
  },
  [SOUGOU_XML_GET_YOUNAIL_LIST](state, data) {
    state.younailXmlList = data
  },
  [SOUGOU_XML_GET_WEELV_LIST](state, data) {
    state.weelvXmlList = data
  },

}

export default {
    state,
    getters,
    actions,
    mutations
}
