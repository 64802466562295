<template>
    <div class="main-page">
        <el-card>
            <div class="page-header">
                <span>问题列表</span>
                <span class="fw600">
                    该条件下问题总数：{{ questionList.total }}个
                </span>
                <span class="fw600">
                    本月新提交数：{{ questionList.new_push_num }}个
                </span>
            </div>
            <div class="flex-row mg-t30 flex-al-start">
                <el-button size="mini" type="primary" class="add-new-btn" @click="toQuestionDetail('')">
                    新建问题
                </el-button>
                <el-button size="mini" type="primary" class="import-btn mg-r10"
                           v-if="Array.isArray(userInfo.role_ids) && userInfo.role_ids.includes(roleMap.admin)"
                           @click="distributionModal = true">分配问题
                </el-button>
                <!--                <el-button size="mini" type="primary" class="import-btn mg-r10"-->
                <!--                           v-if="Array.isArray(userInfo.role_ids) && userInfo.role_ids.includes(roleMap.admin)"-->
                <!--                           @click="baiduFeedbackStatusBatchUpdateModal = true">更新百度反馈状态-->
                <!--                </el-button>-->
                <el-upload
                    class="import-btn mg-r10 flex-row flex-al-start"
                    v-if="Array.isArray(userInfo.role_ids) && userInfo.role_ids.includes(roleMap.admin)"
                    action=""
                    :auto-upload="true"
                    :multiple="true"
                    :limit="1"
                    :file-list="importFileList"
                    :http-request="importQuestion"
                    ref="importQuestion"
                >
                    <el-button type="primary" size="mini">导入问题</el-button>
                </el-upload>
                <el-link href="/weelv/importQuestionTemplate.xlsx"
                         target="_blank"
                         v-if="Array.isArray(userInfo.role_ids) && userInfo.role_ids.includes(roleMap.admin)"
                >
                    <el-button size="mini">下载导入模版</el-button>
                </el-link>
            </div>
            <div class="flex-row flex-js-start flex-al-center mg-t30 flex-wp">
              <el-input class="mg-r10 mg-t10 w80" v-model="question_id" size="small" placeholder="id"></el-input>
              <el-input class="mg-r10 mg-t10 w200" v-model="title" size="small"
                        placeholder="请输入问题标题"></el-input>
              <poi-search-input class="mg-r10 mg-t10" :width="200" v-model="poiInfo"></poi-search-input>
              <el-select v-model="type" class="selection mg-r10 mg-t10 w120" size="mini" placeholder="内容类型"
                         @change="onSelectChange">
                <el-option label="全部" value=""></el-option>
                <el-option v-for="(item, index) in typeMap" :label="item" :value="index"></el-option>
              </el-select>
              <el-select v-model="content_status" class="selection mg-r10 mg-t10 w120" size="mini"
                         placeholder="回答状态"
                         @change="onSelectChange">
                <el-option label="全部" value=""></el-option>
                <el-option v-for="(item, index) in contentStatusMap" :label="item" :value="index"></el-option>
              </el-select>
              <el-select v-model="baidu_qa" class="selection mg-r10 mg-t10 w120" size="mini" placeholder="是否提交"
                         @change="onSelectChange">
                <el-option label="全部" value=""></el-option>
                <el-option v-for="(item, index) in baiduQaMap" :label="item" :value="index"></el-option>
              </el-select>
              <el-select v-model="baidu_submit_status" class="selection mg-r10 mg-t10 w120" size="mini"
                         placeholder="百度题词状态"
                         @change="onSelectChange">
                <el-option label="全部" value=""></el-option>
                <el-option v-for="(item, index) in baiduSubmitStatusMap" :label="item" :value="index"></el-option>
              </el-select>
              <el-select v-model="baidu_feedback_status" class="selection mg-r10 mg-t10 w120" size="mini"
                         placeholder="百度反馈"
                         @change="onSelectChange">
                <el-option label="全部" value=""></el-option>
                <el-option v-for="(item, index) in baiduFeedbackStatusMap" :label="item" :value="index"></el-option>
              </el-select>
              <el-select v-model="audit_status" class="selection mg-r10 mg-t10 w120" size="mini"
                         placeholder="审核状态"
                         @change="onSelectChange">
                <el-option label="全部" value=""></el-option>
                <el-option v-for="(item, index) in auditStatusMap" :label="item" :value="index"></el-option>
              </el-select>
              <user-search-input class="mg-r10 mg-t10" :width="120" v-model="user_info"
                                 v-if="Array.isArray(userInfo.role_ids) && userInfo.role_ids.includes(roleMap.admin)"></user-search-input>
              <el-date-picker class="w120 mg-r10 mg-t10" v-model="distribution_month" type="month"
                              v-if="Array.isArray(userInfo.role_ids) && userInfo.role_ids.includes(roleMap.admin)"
                              value-format="yyyy-MM"
                              size="mini" placeholder="分配日期"></el-date-picker>
              <el-date-picker class="w120 mg-r10 mg-t10" v-model="month" type="month" value-format="yyyy-MM"
                              v-if="Array.isArray(userInfo.role_ids) && userInfo.role_ids.includes(roleMap.admin)"
                              size="mini" placeholder="百度提交日期"></el-date-picker>

              <el-select v-model="status" class="selection mg-r10 mg-t10 w120" size="mini"
                         placeholder="上线状态"
                         @change="onSelectChange">
                <el-option label="全部" value=""></el-option>
                <el-option v-for="(item, index) in statusMap" :label="item" :value="index"></el-option>
              </el-select>
              <el-button class="mg-t10" type="primary" size="mini" @click="searchQuestion">筛选</el-button>
              <el-button class="mg-t10" size="mini"
                         v-if="Array.isArray(userInfo.role_ids) && userInfo.role_ids.includes(roleMap.admin)"
                         @click="explodeQuestion">下载
              </el-button>
            </div>

          <el-table class="mg-t20" :highlight-current-row="true" :stripe="true" :data="questionList.data"
                    align="left">
            <el-table-column prop="id" label="问题ID" width="70"></el-table-column>
            <el-table-column prop="title" label="问题标题"></el-table-column>
            <el-table-column prop="domain" label="百度领域"></el-table-column>
            <el-table-column prop="domain" label="回答状态">
              <template slot-scope="scope">
                <el-tag :type="{0: 'danger', 1: 'primary', 2: 'success'}[scope.row.content_status]">
                  {{ contentStatusMap[scope.row.content_status] }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="baidu_qa" label="是否提交">
              <template slot-scope="scope">
                <el-tag :type="{1: 'success', 2: 'danger', 3: 'primary'}[scope.row.baidu_qa]">
                  {{ baiduQaMap[scope.row.baidu_qa] }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="baidu_submit_status" label="百度题词">
              <template slot-scope="scope">
                <el-tag
                  :type="{0: 'primary', 1: 'primary', 2: 'primary', 3: 'danger', 4: 'success'}[scope.row.baidu_submit_status]">
                  {{ baiduSubmitStatusMap[scope.row.baidu_submit_status] }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="overview" label="UV">
              <template slot-scope="scope">
                <span>{{ scope.row.overview ? scope.row.overview.uv : 0 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="overview" label="pv">
              <template slot-scope="scope">
                <span>{{ scope.row.overview ? scope.row.overview.pv : 0 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="baidu_feedback_date" label="提交时间" width="100"></el-table-column>
            <el-table-column prop="timeliness" label="百度反馈" width="100">
              <template slot-scope="scope">
                <el-tag :type="{4: 'danger', 6: 'danger', 8: 'success'}[scope.row.baidu_feedback_status]">
                  {{ baiduFeedbackStatusMap[scope.row.baidu_feedback_status] }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="timeliness" label="审核状态" width="100">
              <template slot-scope="scope">
                <el-tag :type="{2: 'danger', 4: 'success'}[scope.row.audit_status]">
                  {{ auditStatusMap[scope.row.audit_status] }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="timeliness" label="上线状态" width="100">
              <template slot-scope="scope">
                <el-tag :type="{2: 'danger', 1: 'success'}[scope.row.status]">
                  {{ statusMap[scope.row.status] }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column v-if="Array.isArray(userInfo.role_ids) && userInfo.role_ids.includes(roleMap.admin)"
                             prop="user_name" label="运营人员">
              <template slot-scope="scope">
                <span v-if="scope.row.user_info">{{ scope.row.user_info.name }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作"
                             :width="Array.isArray(userInfo.role_ids) && userInfo.role_ids.includes(roleMap.admin) ? 180 : 80">
              <template slot-scope="scope">
                <el-button size="mini" type="primary" @click="toQuestionDetail(scope.row.id)">详情</el-button>
                <el-button v-if="Array.isArray(userInfo.role_ids) && userInfo.role_ids.includes(roleMap.admin)"
                           size="mini" type="danger" @click="deleteQuestion(scope.row.id)">删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>

          <div class="pagination">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="questionList.total"
              :page-size="pageSize"
              :current-page="questionList.page"
              @current-change="pageChange"
            ></el-pagination>
          </div>
        </el-card>

        <el-dialog :close-on-click-modal="false" title="分配问题" :visible.sync="distributionModal">
            <el-form :model="distributionModalParams" label-width="100px">
                <el-form-item label="运营人员">
                    <user-search-input :width="400" v-model="distributionModalParams.user_info"></user-search-input>
                </el-form-item>
                <el-form-item label="问题ID">
                    <el-input type="textarea" size="small"
                              class="w400"
                              placeholder="问题ID使用英文逗号(,)分割"
                              v-model="distributionModalParams.question_ids"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="distributionModal = false">取 消</el-button>
                <el-button size="small" type="primary" @click="distributionQuestions">保 存</el-button>
            </div>
        </el-dialog>

        <el-dialog :close-on-click-modal="false" title="批量更新百度反馈状态"
                   :visible.sync="baiduFeedbackStatusBatchUpdateModal">
            <el-form :model="baiduFeedbackStatusBatchUpdateParams" label-width="140px">
                <el-form-item label="百度反馈状态">
                    <el-select v-model="baiduFeedbackStatusBatchUpdateParams.baidu_feedback_status"
                               class="selection w200" size="mini"
                               placeholder="百度反馈">
                        <el-option label="校验失败" value="4"></el-option>
                        <el-option label="审核未通过" value="6"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="反馈描述">
                    <el-select v-model="baiduFeedbackStatusBatchUpdateParams.baidu_feedback"
                               class="selection w200" size="mini"
                               placeholder="百度反馈">
                        <el-option label="校验失败" value="4"></el-option>
                        <el-option label="审核未通过" value="6"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="baiduFeedbackStatusBatchUpdateModal = false">取 消</el-button>
                <el-button size="small" type="primary" @click="distributionQuestions">保 存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import {
  ROUTER_WEELV_QUESTIONS_INDEX,
  WEELV_QUESTIONS_GET_DEL_STATUS,
  WEELV_QUESTIONS_GET_DISTRIBUTION_STATUS,
  WEELV_QUESTIONS_GET_IMPORT_STATUS,
  WEELV_QUESTIONS_GET_LIST
} from '@/store/mutations';
import pubStateField from '@/utils/stateField';
import UserSearchInput from '@/components/user/userSearchInput.vue';
import PoiSearchInput from '@/components/poi/poiSearchInput.vue';

export default {
    components: {UserSearchInput, PoiSearchInput},
    computed: {
        ...mapState({
            userInfo: state => state.auth.userInfo,
            routerParams: state => state.routerParams.weelvQuestionIndex,
            questionList: state => state.weelvQuestion.questionList,
            delQuestionStatus: state => state.weelvQuestion.delQuestionStatus,
            importQuestionStatus: state => state.weelvQuestion.importQuestionStatus,
            distributionQuestionStatus: state => state.weelvQuestion.distributionQuestionStatus
        })
    },
    mounted() {
        this.getQuestionList();
    },
    watch: {
        routerParams: {
            handler() {
                this.page = this.routerParams.page;
                this.pageSize = this.routerParams.pageSize;
                this.title = this.routerParams.title;
                this.content_status = this.routerParams.content_status;
                this.baidu_qa = this.routerParams.baidu_qa;
                this.baidu_feedback_status = this.routerParams.baidu_feedback_status;
                this.audit_status = this.routerParams.audit_status;
                this.distribution_month = this.routerParams.distribution_month;
                this.user_info.id = this.routerParams.user_info_id;
                this.user_info.value = this.routerParams.user_info_value;
                this.month = this.routerParams.month;
            },
          deep: true,
          immediate: true
        },

      question_id(value) {
        if (value === '') {
          this.question_id = null;
          this.getQuestionList();
        }
      },
      title(value) {
        if (value === '') {
          this.title = null;
          this.getQuestionList();
        }
      },
      type(value) {
        if (value === '') {
          this.type = null;
          this.getQuestionList();
        }
      },
      content_status(value) {
        if (value === '') {
          this.content_status = null;
          this.getQuestionList();
        }
      },
      status(value) {
        if (value === '') {
          this.status = null;
          this.getQuestionList();
        }
      },
      baidu_qa(value) {
        if (value === '') {
          this.baidu_qa = null;
          this.getQuestionList();
        }
      },
      baidu_feedback_status(value) {
        if (value === '') {
          this.baidu_feedback_status = null;
          this.getQuestionList();
        }
      },
      baidu_submit_status(value) {
        if (value === '') {
          this.baidu_submit_status = null;
          this.getQuestionList();
        }
      },
      audit_status(value) {
        if (value === '') {
          this.audit_status = null;
          this.getQuestionList();
        }
      },
      month(value) {
        if (value === '') {
          this.month = null;
          this.getQuestionList();
        }
      },
      distribution_month(value) {
        if (value === '') {
          this.distribution_month = null;
          this.getQuestionList();
        }
      }
    },
    data() {
        return {
          photoHost: pubStateField.photoHost,
          roleMap: pubStateField.roleMap,
          domainMap: pubStateField.weelvField.domainMap,
          typeMap: pubStateField.weelvField.typeMap,
          baiduQaMap: pubStateField.weelvField.baiduQaMap,
          contentStatusMap: pubStateField.weelvField.contentStatusMap,
          auditStatusMap: pubStateField.weelvField.auditStatusMap,
          statusMap: pubStateField.weelvField.statusMap,
          baiduSubmitStatusMap: pubStateField.weelvField.baiduSubmitStatusMap,
          baiduFeedbackStatusMap: pubStateField.weelvField.baiduFeedbackStatusMap,
          baiduStatusMap: pubStateField.weelvField.baiduStatusMap,
          syncStatusMap: pubStateField.weelvField.syncStatusMap,

          type: null,
          page: 1,
          pageSize: 20,
          question_id: '',
          title: '',
          status: null,
          content_status: null,
          baidu_qa: null,
          baidu_submit_status: null,
          baidu_feedback_status: null,
          audit_status: null,
          distribution_month: '',
          user_info: {id: '', value: ''},
          poiInfo: {poi_id: '', value: ''},
          distributionModal: false,
          distributionModalParams: {
            user_info: {id: '', value: ''},
            question_ids: ''
          },
          baiduFeedbackStatusBatchUpdateModal: false,
          baiduFeedbackStatusBatchUpdateParams: {
            baidu_feedback_status: null,
            baidu_feedback: null,
          },
          month: '',
          importFileList: [],
        };
    },
    methods: {
        ...mapActions({
            WEELV_QUESTIONS_GET_LIST,
            WEELV_QUESTIONS_GET_DEL_STATUS,
            ROUTER_WEELV_QUESTIONS_INDEX,
            WEELV_QUESTIONS_GET_IMPORT_STATUS,
            WEELV_QUESTIONS_GET_DISTRIBUTION_STATUS
        }),
        // 获取问题列表
        getQuestionList() {
            this[WEELV_QUESTIONS_GET_LIST]({
                type: WEELV_QUESTIONS_GET_LIST,
                params: {
                  page: this.page,
                  page_size: this.pageSize,
                  question_id: this.question_id,
                  title: this.title,
                  poi_id: this.poiInfo.poi_id,
                  type: this.type,
                  content_status: this.content_status,
                  baidu_qa: this.baidu_qa,
                  baidu_submit_status: this.baidu_submit_status,
                  baidu_feedback_status: this.baidu_feedback_status,
                  audit_status: this.audit_status,
                  distribution_month: this.distribution_month,
                  user_id: this.user_info.id,
                  month: this.month,
                  status: this.status,
                }
            });

            this[ROUTER_WEELV_QUESTIONS_INDEX]({
                type: ROUTER_WEELV_QUESTIONS_INDEX,
                params: {
                  page: this.page,
                  page_size: this.pageSize,
                  question_id: this.question_id,
                  title: this.title,
                  content_status: this.content_status,
                  baidu_qa: this.baidu_qa,
                  baidu_feedback_status: this.baidu_feedback_status,
                  audit_status: this.audit_status,
                  distribution_month: this.distribution_month,
                  user_info_id: this.user_info.id,
                  user_info_value: this.user_info.value,
                  month: this.month,
                  status: this.status,
                }
            });
        },
        // 删除问题
        async deleteQuestion(question_id) {
            this.$confirm('确认删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error'
            })
                .then(async () => {
                    await this[WEELV_QUESTIONS_GET_DEL_STATUS]({
                        type: WEELV_QUESTIONS_GET_DEL_STATUS,
                        params: {
                            id: question_id
                        }
                    });
                    if (this.delQuestionStatus) {
                        this.$message.success('删除成功');
                        this.getQuestionList();
                    }
                })
                .catch(() => {
                });
        },

        // 跳转问题详情
        toQuestionDetail(question_id) {
            this.$router.push({name: 'weelvQuestionsAnswer', query: {question_id: question_id}});
        },
        // 分页
        pageChange(page) {
            this.page = page;
            this.getQuestionList();
        },
        async searchQuestion() {
            this.page = 1;
            this.getQuestionList();
        },
        async onSelectChange() {
            this.page = 1;
            this.getQuestionList();
        },
        async distributionQuestions() {
            await this[WEELV_QUESTIONS_GET_DISTRIBUTION_STATUS]({
                type: WEELV_QUESTIONS_GET_DISTRIBUTION_STATUS,
                params: {
                    user_id: this.distributionModalParams.user_info.id,
                    question_ids: this.distributionModalParams.question_ids
                }
            });

            if (this.distributionQuestionStatus) {
                this.$message.success('问题分配成功');
                this.distributionModal = false;
                this.getQuestionList();
            }
        },
        async importQuestion() {
            await this[WEELV_QUESTIONS_GET_IMPORT_STATUS]({
                type: WEELV_QUESTIONS_GET_IMPORT_STATUS,
                params: {
                    file: this.$refs.importQuestion.$refs['upload-inner'].fileList[0].raw
                }
            });

            if (this.importQuestionStatus) {
                this.$message.success('导入成功');
                this.importFileList = [];
                this.getQuestionList();
            }
        },
        explodeQuestion() {
            let url = `/weelv/question/export?question_id=${this.question_id || ''}&title=${this.title || ''}&poi_id=${this.poiInfo.poi_id || ''}&content_status=${this.content_status || ''}&baidu_qa=${this.baidu_qa || ''}&baidu_feedback_status=${this.baidu_feedback_status || ''}&baidu_submit_status=${this.baidu_submit_status || ''}&audit_status=${this.audit_status || ''}&user_id=${this.user_info.id || ''}&type=${this.type || ''}&month=${this.month || ''}&distribution_month=${this.distribution_month || ''}`;
            window.open(url, '_blank'); // 新窗口打开外链接
        }
    }
};
</script>

<style lang="scss" scoped>
.el-table {
    text-align: left;
    line-height: 40px;

    .cell {
        line-height: 32px;
    }
}
</style>
