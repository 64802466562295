<template>
    <div>
        <el-autocomplete :size="size" v-model="value.value" placeholder="请输入POI名称" :fetch-suggestions="getPoiList"
                         @select="changeValue" @input="changeValue" :debounce="500"
                         :style="{ width: width + 'px' }">
            <template slot-scope="{ item }">
                <div class="title">{{ item.value }}</div>
                <div class="status" v-if="item.status">
                    <el-tag size="mini" :type="{ 0: 'danger', 1: 'success', 2: 'info' }[item.status]">{{
                            {
                                0: '删除',
                                1: '已上线',
                                2: '已下线'
                            }[item.status]
                        }}
                    </el-tag>
                </div>
                <div class="mdds">{{ item.mdd_name ? item.mdd_name : '' }}</div>
            </template>
        </el-autocomplete>
    </div>
</template>

<script>
export default {
    name: 'poi-search-input',
    props: {
        size: {
            type: String,
            default: 'small',
            required: false
        },
        value: {
            type: Object,
            default: () => {
                return {poi_id: '', value: '', data_type: '', mdd_name: ''};
            },
            required: false
        },
        width: {
            type: Number,
            default: 200,
            required: false
        }
    },
    data() {
        return {
            timeout: null
        };
    },
    methods: {
        getPoiList(name, callback) {
            axios.get(`/weelv/poi/list?name=${name || ''}&page_size=100`)
                .then(response => {
                    if (response.data.request_code === 0) {
                        let poiData = [{value: '无', poi_id: 0, mdd_name: '', data_type: ''}];
                        response.data.data.data.forEach(item => {
                            poiData.push({
                                value: item.name,
                                poi_id: item.id,
                                mdd_name: item.mdd_name,
                                data_type: item.type,
                                status: item.status
                            });
                        });

                        clearTimeout(this.timeout);
                        this.timeout = setTimeout(() => {
                            callback(poiData);
                        }, 1000 * Math.random());
                    }
                });
        },
        changeValue(item) {
            if (!item) {
                this.$emit('input', {poi_id: 0, value: '', mdd_name: '', data_type: ''})
            } else {
                if (typeof item === 'object') {
                    this.$emit('input', item)
                }
            }
        }
    }
};
</script>

<style scoped>
.title {
    text-overflow: ellipsis;
    overflow: hidden;
    float: left;
    width: calc(100% - 50px);
}

.mdds {
    font-size: 12px;
    color: #b4b4b4;
    line-height: normal;
    margin-top: -10px;
    padding-bottom: 7px;
    overflow: hidden;
    float: left;
}

.status {
    font-size: 12px;
    padding-bottom: 7px;
    width: 50px;
    float: right;
}
</style>
