<template>
    <div class="main-page">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="xml列表" name="xml">
                <el-card>
                    <div class="page-header">
                        <span>xml列表</span>
                        <div>
                            <el-tag class="mg-r10" :type="{0:'danger',2:'success'}[xmlList.latest_file.status]">
                                {{ xmlStatusMap[xmlList.latest_file.status] }}
                            </el-tag>
                            <el-button size="mini" type="primary" class="add-new-btn" @click="getXmlList">
                                刷 新
                            </el-button>
                        </div>
                        <div class="flex-row flex-js-start">
                            <el-upload
                                action=""
                                :auto-upload="true"
                                :multiple="false"
                                :http-request="uploadUrl"
                                :file-list="uploadFileList"
                                :show-file-list="false"
                                ref="uploadFile"
                            >
                                <el-button type="primary" size="small">上传url</el-button>
                            </el-upload>
                        </div>
                    </div>
                    <el-table class="mg-t30" :data="xmlList.xml_list">
                        <el-table-column prop="xml_id" label="ID"></el-table-column>
                        <el-table-column prop="item_count" label="url数量"></el-table-column>
                        <el-table-column prop="url" label="地址"></el-table-column>
                    </el-table>
                </el-card>
            </el-tab-pane>
            <el-tab-pane label="url管理" name="url">
                <el-card>
                    <div class="page-header">
                        <span>url列表</span>
                        <div class="flex-row flex-js-start">
                            <el-button size="mini" type="primary" class="add-new-btn" @click="showModal('add','')">
                                添加url
                            </el-button>
                        </div>
                    </div>
                    <div class="flex-row flex-js-start flex-al-center mg-t30">
                        <el-input class="mg-r10 w320" v-model="url" size="small" placeholder="请输入url"></el-input>
                        <el-button type="primary" size="mini" @click="searchUrlList">筛选</el-button>
                    </div>
                    <el-table class="mg-t30" :data="urlList.data">
                        <el-table-column prop="id" label="ID"></el-table-column>
                        <el-table-column prop="xml_id" label="xml"></el-table-column>
                        <el-table-column prop="url" label="url"></el-table-column>
                        <el-table-column prop="ctime" label="添加时间"></el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <el-button size="mini" type="danger" @click="deleteUrl(scope.row.id)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="pagination">
                        <el-pagination
                            background
                            layout="prev, pager, next"
                            :total="urlList.total"
                            :page-size="pageSize"
                            :current-page="urlList.page"
                            @current-change="pageChange"
                        ></el-pagination>
                    </div>
                </el-card>
            </el-tab-pane>
        </el-tabs>

        <el-dialog width="60%" :title="editModalTitle" :visible.sync="editModal">
            <el-form :model="editModalParams" :label-width="'80px'">
                <el-form-item label="urls">
                    <el-input class="w460" size="small" type="textarea"
                              placeholder="多个url通过回车分割，单次最多可添加50个"
                              :autosize="{ minRows: 20 }"
                              v-model="editModalParams.urls"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="editModal = false">取 消</el-button>
                <el-button size="small" type="primary" @click="addUrls()">保
                    存
                </el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import {
    SOUGOU_LIZHI_URL_UPLOAD,
    SOUGOU_LIZHI_XML_GET_LIST,
    SOUGOU_LIZHI_URL_GET_LIST,
    SOUGOU_LIZHI_URL_DELETE,
    SOUGOU_LIZHI_URL_CREATE,
} from '@/store/mutations'

export default {
    computed: {
        ...mapState({
            xmlList: state => state.sougouIndex.xmlList,
            urlList: state => state.sougouIndex.urlList,
            uploadStatus: state => state.sougouIndex.uploadStatus,
            deleteUrlStatus: state => state.sougouIndex.deleteUrlStatus,
            createUrlStatus: state => state.sougouIndex.createUrlStatus,
        })
    },
    mounted() {
        this.getXmlList();
        this.getUrlList();
    },
    watch: {},
    data() {
        return {
            activeName: 'xml',
            uploadFileList: [],
            page: 1,
            pageSize: 20,
            url: '',
            editModal: false,
            editModalTitle: '添加url',
            editModalParams: {
                urls: '',
            },
            xmlStatusMap: {
                0: '待生成',
                1: '生成中',
                2: '已生成',
            }
        }
    },
    methods: {
        ...mapActions({
            SOUGOU_LIZHI_URL_UPLOAD,
            SOUGOU_LIZHI_XML_GET_LIST,
            SOUGOU_LIZHI_URL_GET_LIST,
            SOUGOU_LIZHI_URL_DELETE,
            SOUGOU_LIZHI_URL_CREATE,
        }),

        handleClick() {
            if (this.activeName == 'xml') {
                this.getXmlList();
            } else if (this.activeName == 'url') {
                this.getUrlList();
            }
        },

        // 获取列表
        getXmlList() {
            this[SOUGOU_LIZHI_XML_GET_LIST]({
                type: SOUGOU_LIZHI_XML_GET_LIST,
                params: {}
            });
        },

        // 获取url列表
        getUrlList() {
            this[SOUGOU_LIZHI_URL_GET_LIST]({
                type: SOUGOU_LIZHI_URL_GET_LIST,
                params: {
                    page: this.page,
                    pageSize: this.pageSize,
                    url: this.url
                }
            });
        },
        searchUrlList() {
            this.page = 1;
            this.getUrlList();
        },
        pageChange(page) {
            this.page = page
            this.getUrlList();
        },

        async uploadUrl() {
            await this[SOUGOU_LIZHI_URL_UPLOAD]({
                type: SOUGOU_LIZHI_URL_UPLOAD,
                params: {
                    file: this.$refs.uploadFile.$refs['upload-inner'].fileList[0],
                }
            })
            if (this.uploadStatus) {
                this.$message.success('上传成功')
                this.editModal = false
                this.getXmlList()
            }
        },
        deleteUrl(id) {
            this.$confirm('确认删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error'
            })
                .then(async () => {
                    await this[SOUGOU_LIZHI_URL_DELETE]({
                        type: SOUGOU_LIZHI_URL_DELETE,
                        params: {
                            id: id
                        }
                    })
                    if (this.deleteUrlStatus) {
                        this.$message.success('删除成功')
                        this.getUrlList()
                        this.getXmlList()
                    }
                })
                .catch(() => {
                });
        },
        showModal(type) {
            this.editModal = true;
            if (type == 'add') {
                this.editModalTitle = '添加url';
                this.editModalParams.urls = '';
            }
        },
        async addUrls() {
            await this[SOUGOU_LIZHI_URL_CREATE]({
                type: SOUGOU_LIZHI_URL_CREATE,
                params: {
                    urls: this.editModalParams.urls,
                }
            })
            if (this.createUrlStatus) {
                this.$message.success('创建成功')
                this.editModal = false
                this.getUrlList()
            }
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
