<template>
	<header class="header">
		<div class="title">
			<img class="logo" src="@/assets/images/icon-logo.png" />
			<span class="f18 fw600 mg-l10">内容管理中台</span>
		</div>
		<div class="user" @click="logout()" v-if="userInfo">
			<span>{{ userInfo.name }}</span>
		</div>
	</header>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'Header',
	computed: {
		...mapState({
			userInfo: state => state.auth.userInfo
		})
	},
	data() {
		return {
			logoutModal: false
		};
	},
	methods: {
		logout() {
			this.$router.push('/login');
			location.reload();
		}
	}
};
</script>

<style lang="scss">
@import '@/assets/css/common/header.scss';
</style>
