<template>
    <div class="home">
        <el-card class="user-info">
            <div>
                <img class="head-portrait" src="@/assets/images/boy.png" />
                <div class="detail">
					<span class="desc mg-t10">你好：{{ userInfo.name }}</span>
                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    export default {
        computed: {
            ...mapState({
                userInfo: state => state.auth.userInfo
            })
        },
        mounted() {

        },
        data() {
            return {
                days: 0
            }
        },
        watch: {
            userInfo() {
                // if (this.userInfo.ctime) {
                //     let ctime = this.userInfo.ctime.split(' ')[0]

                //     let cdate = new Date(ctime)
                //     let ndate = new Date()
                //     this.days = Math.ceil((ndate.getTime() - cdate.getTime()) / (1000 * 60 * 60 * 24))
                // }
            }
        },
        methods: {
            ...mapActions({ })
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/assets/css/home.scss";
</style>
