<template>
    <div class="main-page">
        <el-card>
            <div class="page-header">
                <div>
                    <span>教程内容列表</span>
                    <span style="margin-left: 50px;font-size: 14px;font-weight: 700;">
                        该条件下教程内容总数：{{ courseContentList.total }}个
                    </span>
                </div>
                <div>
                    <el-button size="mini" type="primary" class="add-new-btn" @click="toCourseContentDetail('')">
                        新建教程内容
                    </el-button>
                </div>
            </div>
            <div class="flex-row flex-js-start flex-al-center mg-t30">
                <el-input class="mg-r10 w80" v-model="course_id" size="small" placeholder="id"></el-input>
                <el-input class="mg-r10 w320" v-model="title" size="small" placeholder="请输入教程内容标题"></el-input>
                <el-select v-model="type" class="selection mg-r10 w120" size="mini" placeholder="类型"
                           @change="onSelectChange">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="测量" value="1"></el-option>
                    <el-option label="穿戴教程" value="2"></el-option>
                    <el-option label="常见问题" value="3"></el-option>
                </el-select>
                <el-select v-model="is_hot" class="selection mg-r10 w120" size="mini" placeholder="热门状态"
                           @change="onSelectChange">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="热门" value="1"></el-option>
                    <el-option label="非热门" value="2"></el-option>
                </el-select>
                <el-select v-model="status" class="selection mg-r10 w120" size="mini" placeholder="上线状态"
                           @change="onSelectChange">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="已上线" value="1"></el-option>
                    <el-option label="已下线" value="2"></el-option>
                </el-select>
                <el-button type="primary" size="mini" @click="searchCourseContent">筛选</el-button>
            </div>

            <el-table class="mg-t20" :highlight-current-row="true" :stripe="true" :data="courseContentList.data"
                      align="left">
                <el-table-column prop="id" label="ID" width="70"></el-table-column>
                <el-table-column prop="title" label="标题"></el-table-column>
                <el-table-column prop="list_image_cover" label="列表封面图">
                    <template slot-scope="scope">
                        <PreveImg v-if="scope.row.list_image_cover && scope.row.list_image_cover !== ''"
                                  :image_cover="scope.row.list_image_cover"
                                  :isshow="false"></PreveImg>
                        <el-tag v-else type="danger">暂无封面图</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="image_cover" label="封面图">
                    <template slot-scope="scope">
                        <PreveImg v-if="scope.row.image_cover && scope.row.image_cover !== ''"
                                  :image_cover="scope.row.image_cover"
                                  :isshow="false"></PreveImg>
                        <el-tag v-else type="danger">暂无封面图</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="video_file_id" label="视频">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.video_file_id" type="success">已有视频</el-tag>
                        <el-tag v-else type="danger">暂无视频</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="type" label="类型">
                    <template slot-scope="scope">
                        <el-tag>
                            {{ typeMap[scope.row.type] }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="is_hot" label="上线状态">
                    <template slot-scope="scope">
                        <el-tag :type="{1: 'success'}[scope.row.is_hot] || ''">
                            {{ {0: '非热门', 1: '热门'}[scope.row.is_hot] }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="上线状态">
                    <template slot-scope="scope">
                        <el-tag :type="{1: 'success', 2: 'danger'}[scope.row.status]">
                            {{ {1: '已上线', 2: '已下线'}[scope.row.status] }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作"
                                 :width="Array.isArray(userInfo.role_ids) && userInfo.role_ids.includes(roleMap.admin) ? 180 : 80">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary" @click="toCourseContentDetail(scope.row.id)">详情
                        </el-button>
                        <el-button v-if="Array.isArray(userInfo.role_ids) && userInfo.role_ids.includes(roleMap.admin)"
                                   size="mini" type="danger" @click="deleteCourseContent(scope.row.id)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <div class="pagination">
                <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="courseContentList.total"
                    :page-size="pageSize"
                    :current-page="courseContentList.page"
                    @current-change="pageChange"
                ></el-pagination>
            </div>
        </el-card>
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import {
    YOUNAIL_COURSE_CONTENT_GET_LIST,
    YOUNAIL_COURSE_CONTENT_GET_DEL_STATUS,
    ROUTER_YOUNAIL_COURSE_CONTENT_INDEX,
} from '@/store/mutations';
import pubStateField from '@/utils/stateField';
import UserSearchInput from '@/components/user/userSearchInput.vue';
import PreveImg from '@/components/preveimg/preve.vue';

export default {
    components: {UserSearchInput, PreveImg},
    computed: {
        ...mapState({
            userInfo: state => state.auth.userInfo,
            routerParams: state => state.routerParams.younailCourseContentIndex,
            courseContentList: state => state.younailCourseContent.courseContentList,
            delCourseContentStatus: state => state.younailCourseContent.delCourseContentStatus,
        })
    },
    mounted() {
        this.getCourseContentList();
    },
    watch: {
        routerParams: {
            handler() {
                this.page = this.routerParams.page;
                this.pageSize = this.routerParams.pageSize;
                this.course_id = this.routerParams.course_id;
                this.title = this.routerParams.title;
                this.type = this.routerParams.type;
                this.status = this.routerParams.status;
                this.is_hot = this.routerParams.is_hot;
            },
            deep: true,
            immediate: true
        },

        course_id(value) {
            if (value === '') {
                this.course_id = null;
                this.getCourseContentList();
            }
        },
        title(value) {
            if (value === '') {
                this.title = null;
                this.getCourseContentList();
            }
        },
        type(value) {
            if (value === '') {
                this.type = null;
                this.getCourseContentList();
            }
        },
        is_hot(value) {
            if (value === '') {
                this.is_hot = null;
                this.getCourseContentList();
            }
        },
        status(value) {
            if (value === '') {
                this.type = null;
                this.getCourseContentList();
            }
        }
    },
    data() {
        return {
            photoHost: pubStateField.photoHost,
            roleMap: pubStateField.roleMap,
            typeMap: pubStateField.younailField.courseTypeMap,

            page: 1,
            pageSize: 20,
            course_id: '',
            title: '',
            type: null,
            is_hot: null,
            status: null,
        };
    },
    methods: {
        ...mapActions({
            YOUNAIL_COURSE_CONTENT_GET_LIST,
            YOUNAIL_COURSE_CONTENT_GET_DEL_STATUS,
            ROUTER_YOUNAIL_COURSE_CONTENT_INDEX,
        }),
        // 获取教程内容列表
        getCourseContentList() {
            this[YOUNAIL_COURSE_CONTENT_GET_LIST]({
                type: YOUNAIL_COURSE_CONTENT_GET_LIST,
                params: {
                    page: this.page,
                    page_size: this.pageSize,
                    course_id: this.course_id,
                    title: this.title,
                    type: this.type,
                    is_hot: this.is_hot,
                    status: this.status,
                }
            });

            this[ROUTER_YOUNAIL_COURSE_CONTENT_INDEX]({
                type: ROUTER_YOUNAIL_COURSE_CONTENT_INDEX,
                params: {
                    page: this.page,
                    page_size: this.pageSize,
                    course_id: this.course_id,
                    title: this.title,
                    is_hot: this.is_hot,
                    type: this.type,
                    status: this.status,
                }
            });
        },
        // 删除教程内容
        async deleteCourseContent(course_id) {
            this.$confirm('确认删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error'
            })
                .then(async () => {
                    await this[YOUNAIL_COURSE_CONTENT_GET_DEL_STATUS]({
                        type: YOUNAIL_COURSE_CONTENT_GET_DEL_STATUS,
                        params: {
                            id: course_id
                        }
                    });
                    if (this.delCourseContentStatus) {
                        this.$message.success('删除成功');
                        this.getCourseContentList();
                    }
                })
                .catch(() => {
                });
        },

        // 跳转教程内容详情
        toCourseContentDetail(course_id) {
            this.$router.push({name: 'younailCourseContentDetail', query: {course_id: course_id}});
        },
        // 分页
        pageChange(page) {
            this.page = page;
            this.getCourseContentList();
        },
        async searchCourseContent() {
            this.page = 1;
            this.getCourseContentList();
        },
        async onSelectChange() {
            this.page = 1;
            this.getCourseContentList();
        }
    }
};
</script>

<style lang="scss" scoped>
.el-table {
    text-align: left;
    line-height: 40px;

    .cell {
        line-height: 32px;
    }
}
</style>
