import {
    YOUNAIL_QUALITY_CONTENT_GET_LIST,
    YOUNAIL_QUALITY_CONTENT_GET_ADD_STATUS,
    YOUNAIL_QUALITY_CONTENT_GET_DEL_STATUS,
    YOUNAIL_QUALITY_CONTENT_GET_UPD_STATUS,
    YOUNAIL_QUALITY_CONTENT_GET_DETAIL_BY_ID,
    YOUNAIL_QUALITY_CONTENT_GET_ADD_TAG_STATUS,
} from '@/store/mutations'
import {
    loginCheck
} from '@/utils/loginCheck'
import {
    Message,
    Notification
} from 'element-ui'

const state = {
    qualityContentList: {},
    addQualityContentStatus: false,
    updQualityContentStatus: false,
    delQualityContentStatus: false,
    qualityContentDetail: {},
    tagAddTagStatus: false,
}

const getters = {}

// actions
const actions = {
    // 获取精品内容列表
    async [YOUNAIL_QUALITY_CONTENT_GET_LIST]({commit, state}, action) {
        await axios.get('/younail/qualityContent/list', {
            params: {
                page: action.params.page,
                page_size: action.params.page_size,
                content_id: action.params.content_id || '',
                title: action.params.title || '',
                is_recommend: action.params.is_recommend || '',
                status: action.params.status || '',
            }
        }).then(response => {
            loginCheck(response.data.request_code)
            if (response.data.request_code === 0) {
                commit(action.type, response.data.data)
            } else {
                Message.error(response.data.message)
            }
        })
    },

    // 创建精品内容
    async [YOUNAIL_QUALITY_CONTENT_GET_ADD_STATUS]({commit, state}, action) {
        commit(action.type, false)

        await axios.post('/younail/qualityContent/create', {
            title: action.params.title || '',
            sub_title: action.params.sub_title || '',
            image_cover: action.params.image_cover || '',
            video_file_id: action.params.video_file_id || '',
            is_recommend: action.params.is_recommend || 2,
            status: action.params.status || 2,
            origin_content: action.params.origin_content || '',
            content: action.params.content || '',
            tag_ids: action.params.tag_ids || '',
        }).then(response => {
            loginCheck(response.data.request_code)
            if (response.data.request_code === 0) {
                commit(action.type, response.data.data)
            } else {
                Message.error(response.data.message)
            }
        })
    },

    // 编辑精品内容
    async [YOUNAIL_QUALITY_CONTENT_GET_UPD_STATUS]({commit, state}, action) {
        commit(action.type, false)

        await axios.post('/younail/qualityContent/update', {
            id: action.params.id,
            title: action.params.title || '',
            sub_title: action.params.sub_title || '',
            image_cover: action.params.image_cover || '',
            video_file_id: action.params.video_file_id || '',
            is_recommend: action.params.is_recommend || 2,
            status: action.params.status || 2,
            origin_content: action.params.origin_content || '',
            content: action.params.content || '',
            tag_ids: action.params.tag_ids || '',
        }).then(response => {
            loginCheck(response.data.request_code)
            if (response.data.request_code === 0) {
                commit(action.type, response.data.data)
            } else {
                Message.error(response.data.message)
            }
        })
    },

    // 删除精品内容
    async [YOUNAIL_QUALITY_CONTENT_GET_DEL_STATUS]({commit, state}, action) {
        commit(action.type, false)
        await axios.post(`/younail/qualityContent/delete`, {
            id: action.params.id
        }).then(response => {
            if (response.data.request_code === 0) {
                commit(action.type, response.data.data)
            } else {
                Message.error(response.data.message)
            }
        })
    },

    // 获取精品内容详情
    [YOUNAIL_QUALITY_CONTENT_GET_DETAIL_BY_ID]({commit, state}, action) {
        let id = action.params.id || ''
        axios.get(`/younail/qualityContent/detail?id=${id}`).then(response => {
            if (response.data.request_code === 0) {
                commit(action.type, response.data.data)
            } else {
                Message.error(response.data.message)
            }
        })
    },
    // 获取标签列表
    async [YOUNAIL_QUALITY_CONTENT_GET_ADD_TAG_STATUS]({commit, state}, action) {
        commit(action.type, false)
        let name = action.params.name
        await axios.get(`/younail/qualityContent/tag/findByName?name=${name}`).then(response => {
            if (response.data.request_code === 0) {
                commit(action.type, response.data.data)
            } else {
                Message.error(response.data.message)
            }
        })
    }
}

// mutations
const mutations = {
    [YOUNAIL_QUALITY_CONTENT_GET_LIST](state, data) {
        state.qualityContentList = data
    },
    [YOUNAIL_QUALITY_CONTENT_GET_ADD_STATUS](state, data) {
        state.addQualityContentStatus = data
    },
    [YOUNAIL_QUALITY_CONTENT_GET_DEL_STATUS](state, data) {
        state.delQualityContentStatus = data
    },
    [YOUNAIL_QUALITY_CONTENT_GET_UPD_STATUS](state, data) {
        state.updQualityContentStatus = data
    },
    [YOUNAIL_QUALITY_CONTENT_GET_DETAIL_BY_ID](state, data) {
        state.qualityContentDetail = data
    },
    [YOUNAIL_QUALITY_CONTENT_GET_ADD_TAG_STATUS](state, data) {
        state.tagAddTagStatus = data
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
