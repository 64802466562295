import Vue from 'vue'
import Router from 'vue-router'

// 登陆
import Login from '@/views/Login.vue'

// 首页
import Home from '@/views/Home.vue'

// 用户管理
import UserMgt from '@/views/auth/userMgt.vue'

import SpiderPetWenda from '@/views/spider/list.vue'

// 问答管理
import WeelvQuestionsList from '@/views/weelv_questions/questionList.vue'
import WeelvQuestionsAnswer from '@/views/weelv_questions/questionAnswer.vue'
import WeelvQuestionsTag from '@/views/weelv_questions/tag.vue'

// 小壳问答
import CatureQuestionsList from '@/views/cature_questions/questionList.vue'
import CatureQuestionsAnswer from '@/views/cature_questions/questionAnswer.vue'
import CatureQuestionsTag from '@/views/cature_questions/tag.vue'


// younail问答
import YounailQuestionsList from '@/views/younail_questions/questionList.vue'
import YounailQuestionsAnswer from '@/views/younail_questions/questionAnswer.vue'

import YounailQualityContentList from '@/views/younail_quality_content/list.vue'
import YounailQualityContentDetail from '@/views/younail_quality_content/detail.vue'

import YounailCourseContentList from '@/views/younail_course_content/list.vue'
import YounailCourseContentDetail from '@/views/younail_course_content/detail.vue'

import SougouIndex from '@/views/sougou/Index.vue'
import SougouXmlIndex from '@/views/sougou/xmlIndex.vue'


Vue.use(Router)

export default new Router({
    routes: [{
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            title: '登录Jehovah | WEE内容管理系统'
        }
    }, {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            title: '首页 | WEE内容管理系统'
        }
    }, {
        path: '/userMgt',
        name: 'userMgt',
        component: UserMgt,
        meta: {
            title: '用户管理 | WEE内容管理系统'
        }
    }, {
        path: '/weelvQuestionsList',
        name: 'weelvQuestionsList',
        component: WeelvQuestionsList,
        meta: {
            title: '问题列表 | WEE内容管理系统',
        }
    }, {
        path: '/weelvQuestionsAnswer',
        name: 'weelvQuestionsAnswer',
        component: WeelvQuestionsAnswer,
        meta: {
            title: '问题详情 | WEE内容管理系统'
        }
    }, {
        path: '/weelvQuestionsTag',
        name: 'weelvQuestionsTag',
        component: WeelvQuestionsTag,
        meta: {
            title: '标签管理 | WEE内容管理系统'
        }
    }, {
        path: '/catureQuestionsList',
        name: 'catureQuestionsList',
        component: CatureQuestionsList,
        meta: {
            title: '问题列表 | WEE内容管理系统'
        }
    }, {
        path: '/catureQuestionsAnswer',
        name: 'catureQuestionsAnswer',
        component: CatureQuestionsAnswer,
        meta: {
            title: '问题详情 | WEE内容管理系统'
        }
    }, {
        path: '/catureQuestionsTag',
        name: 'catureQuestionsTag',
        component: CatureQuestionsTag,
        meta: {
            title: '标签管理 | WEE内容管理系统'
        }
    }, {
        path: '/spiderPetWenda',
        name: 'spiderPetWenda',
        component: SpiderPetWenda,
        meta: {
            title: '采集问答 | WEE内容管理系统'
        }
    }, {
        path: '/younailQuestionsList',
        name: 'younailQuestionsList',
        component: YounailQuestionsList,
        meta: {
            title: '问题列表 | 花袖内容管理系统'
        }
    }, {
        path: '/younailQuestionsAnswer',
        name: 'younailQuestionsAnswer',
        component: YounailQuestionsAnswer,
        meta: {
            title: '问题详情 | 花袖内容管理系统'
        }
    }, {
        path: '/YounailQualityContentList',
        name: 'younailQualityContentList',
        component: YounailQualityContentList,
        meta: {
            title: '精选内容列表 | 花袖内容管理系统'
        }
    }, {
        path: '/younailQualityContentDetail',
        name: 'younailQualityContentDetail',
        component: YounailQualityContentDetail,
        meta: {
            title: '精选内容详情 | 花袖内容管理系统'
        }
    }, {
        path: '/YounailCourseContentList',
        name: 'younailCourseContentList',
        component: YounailCourseContentList,
        meta: {
            title: '教程内容列表 | 花袖内容管理系统'
        }
    }, {
        path: '/younailCourseContentDetail',
        name: 'younailCourseContentDetail',
        component: YounailCourseContentDetail,
      meta: {
        title: '教程内容详情 | 花袖内容管理系统'
      }
    }, {
      path: '/sogou/LizhiXml',
      name: 'sougouIndex',
      component: SougouIndex,
      meta: {
        title: '立知xml | 内容管理系统'
      }
    }, {
      path: '/sogou/xml',
      name: 'sougouXmlIndex',
      component: SougouXmlIndex,
      meta: {
        title: '立知xml | 内容管理系统'
      }
    }]
})
