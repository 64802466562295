const roleMap = {
    admin: 1,
    weelv_normal: 2,
    cature_normal: 3,
    younail_lizhi: 4,
    sougou_lizhi: 5,
}

const photoHost =
    process.env.NODE_ENV !== 'production' || location.host.includes('-test')
        ? {1: 'http://t-aliyunzjk-wee-images.oss-cn-zhangjiakou.aliyuncs.com'}
        : {1: 'http://p-aliyunzjk-wee-images.oss-cn-zhangjiakou.aliyuncs.com'};

const videoHost =
    process.env.NODE_ENV !== 'production' || location.host.includes('-test')
        ? {1: 'http://t-aliyunzjk-wee-videos.oss-cn-zhangjiakou.aliyuncs.com'}
        : {1: 'http://p-aliyunzjk-wee-videos.oss-cn-zhangjiakou.aliyuncs.com'};

const weelvField = {
    domainMap: {
        1: '兴趣爱好',
        2: '兴趣爱好-旅游',
    },
    typeMap: {
        0: '未知',
        1: '原创',
        2: '咨询',
        3: '机器写作',
    },
    baiduQaMap: {
        1: '需要提交',
        2: '无需提交',
        3: '暂不提交',
    },
    contentStatusMap: {
        0: '未回答',
        1: '已回答&无描述',
        2: '已回答&有描述'
    },
    auditStatusMap: {
        // 0: '暂无',
        1: '未审核',
        2: '审核未通过',
        3: '已修改',
        4: '审核通过'
    },
    baiduSubmitStatusMap: {
        0: '未提交',
        1: '待提交',
        2: '已提交',
        3: '审核未通过',
        4: '审核通过',
    },
    baiduFeedbackStatusMap: {
      0: '默认',
      1: '待提交',
      2: '已提交',
      3: '校验中',
      4: '校验失败',
      5: '待审核',
      6: '审核未通过',
      7: '待生效',
      8: '生效成功'
    },
  baiduStatusMap: {
    0: '默认',
    1: '未生效',
    2: '已生效',
    3: '下线'
  },
  syncStatusMap: {
    0: '默认',
    1: '未同步',
    2: '已同步',
    3: '有更新'
  },
  statusMap: {
    1: '已上线',
    2: '已下线',
  }
}

const catureField = {
    domainMap: {
        1: '兴趣爱好',
    },
    typeMap: {
        0: '原创',
        1: '机器写作'
    },
    baiduQaMap: {
        1: '需要提交',
        2: '无需提交',
        3: '暂不提交',
    },
    contentStatusMap: {
        0: '未回答',
        1: '已回答&无描述',
        2: '已回答&有描述'
    },
    auditStatusMap: {
        // 0: '暂无',
        1: '未审核',
        2: '审核未通过',
        3: '已修改',
        4: '审核通过'
    },
    baiduSubmitStatusMap: {
        0: '未提交',
        1: '待提交',
        2: '已提交'
    },
    baiduFeedbackStatusMap: {
        0: '默认',
        1: '待提交',
        2: '已提交',
        3: '校验中',
        4: '校验失败',
        5: '待审核',
        6: '审核未通过',
        7: '待生效',
        8: '生效成功'
    },
    baiduStatusMap: {
        0: '默认',
        1: '未生效',
        2: '已生效',
        3: '下线'
    },
    syncStatusMap: {
        0: '默认',
        1: '未同步',
        2: '已同步',
        3: '有更新'
    }
}

const younailField = {
    domainMap: {
        1: '兴趣爱好',
    },
    typeMap: {
        0: '原创',
        1: '机器写作'
    },
    baiduQaMap: {
        1: '需要提交',
        2: '无需提交',
        3: '暂不提交',
    },
    contentStatusMap: {
        0: '未回答',
        1: '已回答&无描述',
        2: '已回答&有描述'
    },
    auditStatusMap: {
        // 0: '暂无',
        1: '未审核',
        2: '审核未通过',
        3: '已修改',
        4: '审核通过'
    },
    baiduSubmitStatusMap: {
        0: '未提交',
        1: '待提交',
        2: '已提交'
    },
    baiduFeedbackStatusMap: {
      0: '默认',
      1: '待提交',
      2: '已提交',
      3: '校验中',
      4: '校验失败',
      5: '待审核',
      6: '审核未通过',
      7: '待生效',
      8: '生效成功'
    },
  baiduStatusMap: {
    0: '默认',
    1: '未生效',
    2: '已生效',
    3: '下线'
  },
  syncStatusMap: {
    0: '默认',
    1: '未同步',
    2: '已同步',
    3: '有更新'
  },
  courseTypeMap: {
    1: '测量',
    2: '穿戴教程',
    3: '常见问题'
  },
  statusMap: {
    1: '已上线',
    2: '已下线',
  }
}

export default {
    roleMap,
    weelvField,
    catureField,
    photoHost,
    videoHost,
    younailField
}
