<template>
    <div>
        <el-autocomplete
            :size="size"
            v-model="value.value"
            placeholder="请输入目的地名称"
            :fetch-suggestions="getMddList"
            :debounce="500"
            @select="changeValue"
            @input="changeValue"
            :style="{ 'width': width+'px' }"
        ></el-autocomplete>
    </div>
</template>

<script>
export default {
    name: "mdd-search-input",
    props: {
        size: {
            type: String,
            default: 'small',
            required: false
        },
        value: {
            type: Object,
            default: () => {
                return {id: '', value: ''}
            },
            required: false
        },
        type: {
            type: String,
            default: '',
            required: false
        },
        width: {
            type: Number,
            default: 400,
            required: false
        }
    },
    data() {
        return {
            cancelToken: null,
            timeout: null
        }
    },
    methods: {
        getMddList(mddName, callback) {
            if (this.cancelToken !== null) {
                this.cancelToken.cancel('')
            }

            this.cancelToken = axios.CancelToken.source()

            let type = ''
            if (this.type === 'state') {
                type = 1
            } else if (this.type === 'country') {
                type = 2
            } else if (this.type === 'province') {
                type = 3
            } else if (this.type === 'city') {
                type = 4
            }

            axios.get(`/weelv/mdd/list?name=${mddName || ''}&type=${type}&page_size=100`, {
                cancelToken: this.cancelToken.token
            }).then(response => {
                if (response.data.request_code === 0) {
                    let mddData = [{id: 0, value: ''}]
                    response.data.data.data.forEach(item => {
                        mddData.push({
                            id: item.id,
                            value: item.name,
                        })
                    })
                    clearTimeout(this.timeout)
                    this.timeout = setTimeout(() => {
                        callback(mddData)
                    }, 1000 * Math.random())
                }
            })
        },
        changeValue(item) {
            if (!item) {
                this.$emit('input', {id: 0, value: ''})
            } else {
                if (typeof item === 'object') {
                    this.$emit('input', item)
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
