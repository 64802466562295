<template>
    <div>
        <el-autocomplete
            ref="seachInput"
            :size="size"
            v-model="value.value"
            :placeholder="placeholder"
            :fetch-suggestions="getList"
            :trigger-on-focus="true"
            :debounce="500"
            @select="onSelect"
            @change="changeInput"
            :style="{ width: width + 'px' }"
            clearable
        ></el-autocomplete>
    </div>
</template>

<script>
export default {
    props: {
        size: {
            type: String,
            default: 'medium',
            required: false
        },
        value: {
            type: Object,
            default: () => {
                return {id: '', value: ''};
            },
            required: false
        },
        url: {
            type: String,
            default: '',
            required: false
        },
        product_type_id: {
            type: Number,
            default: 0,
            required: false
        },
        name: {
            type: String,
            default: "",
            required: false
        },
        placeholder: {
            type: String,
            default: '',
            required: false
        },
        isShow: {
            type: Boolean,
            default: false,
            required: false
        },
        width: {
            type: Number,
            default: 200,
            required: false
        }
    },
    data() {
        return {
            cancelToken: null,
            timeout: null,
        };
    },
    methods: {
        getList(Name, callback) {
            let params = ""

            let ajaxUrl = this.url + (Name || '');
            axios.get(`${ajaxUrl}`).then(response => {
                if (response.data.request_code === 0) {
                    let lsData = [{id: 0, value: ''}];
                    response.data.data.data.forEach(item => {
                        let single = {
                            id: item.id,
                            value: item.name,
                        };

                        if (item.product_type_id) {
                            single.product_type_id = item.product_type_id;
                        }
                        if (item.image_cover) {
                            single.image_cover = item.image_cover;
                        }
                        if (item.product_id) {
                            single.product_id = item.product_id;
                        }

                        lsData.push(single);
                    });
                    clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                        callback(lsData);
                    }, 1000 * Math.random());
                }
            });
        },
        onSelect(item) {
            this.$emit('select', item);
        },
        changeInput() {
            if (this.value && this.value.value == '') {
                let lsData = [{id: '', value: ''}];
                this.$emit('select', lsData);
            }

        },
    }
};
</script>

<style scoped></style>
