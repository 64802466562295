<template>
    <div class="main-page">
        <el-card>
            <div class="page-header">
                <div>
                    <span>精选内容列表</span>
                    <span style="margin-left: 50px;font-size: 14px;font-weight: 700;">
                        该条件下精选内容总数：{{ qualityContentList.total }}个
                    </span>
                </div>
                <div>
                    <el-button size="mini" type="primary" class="add-new-btn" @click="toQualityContentDetail('')">
                        新建精选内容
                    </el-button>
                </div>
            </div>
            <div class="flex-row flex-js-start flex-al-center mg-t30">
                <el-input class="mg-r10 w80" v-model="content_id" size="small" placeholder="id"></el-input>
                <el-input class="mg-r10 w320" v-model="title" size="small" placeholder="请输入精选内容标题"></el-input>
                <el-select v-model="is_recommend" class="selection mg-r10 w120" size="mini" placeholder="推荐状态"
                           @change="onSelectChange">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="推荐" value="1"></el-option>
                    <el-option label="不推荐" value="2"></el-option>
                </el-select>
                <el-select v-model="status" class="selection mg-r10 w120" size="mini" placeholder="上线状态"
                           @change="onSelectChange">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="已上线" value="1"></el-option>
                    <el-option label="已下线" value="2"></el-option>
                </el-select>
                <el-button type="primary" size="mini" @click="searchQualityContent">筛选</el-button>
            </div>

            <el-table class="mg-t20" :highlight-current-row="true" :stripe="true" :data="qualityContentList.data"
                      align="left">
                <el-table-column prop="id" label="ID" width="70"></el-table-column>
                <el-table-column prop="title" label="标题"></el-table-column>
                <el-table-column prop="sub_title" label="副标题"></el-table-column>
                <el-table-column prop="image_cover" label="封面图">
                    <template slot-scope="scope">
                        <span v-if="scope.row.image_cover">
                            <img :src="`${photoHost[1]}/${scope.row.image_cover}?x-oss-process=image/resize,w_200`"/>
                        </span>
                        <el-tag v-else type="danger">暂无封面图</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="video_file_id" label="视频">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.video_file_id" type="success">已有视频</el-tag>
                        <el-tag v-else type="danger">暂无视频</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="is_recommend" label="推荐状态">
                    <template slot-scope="scope">
                        <el-tag :type="{1: 'success', 2: 'primary'}[scope.row.is_recommend]">
                            {{ {1: '推荐', 2: '不推荐'}[scope.row.is_recommend] }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="上线状态">
                    <template slot-scope="scope">
                        <el-tag :type="{1: 'success', 2: 'danger'}[scope.row.status]">
                            {{ {1: '已上线', 2: '已下线'}[scope.row.status] }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作"
                                 :width="Array.isArray(userInfo.role_ids) && userInfo.role_ids.includes(roleMap.admin) ? 180 : 80">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary" @click="toQualityContentDetail(scope.row.id)">详情
                        </el-button>
                        <el-button v-if="Array.isArray(userInfo.role_ids) && userInfo.role_ids.includes(roleMap.admin)"
                                   size="mini" type="danger" @click="deleteQualityContent(scope.row.id)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <div class="pagination">
                <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="qualityContentList.total"
                    :page-size="pageSize"
                    :current-page="qualityContentList.page"
                    @current-change="pageChange"
                ></el-pagination>
            </div>
        </el-card>
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import {
    YOUNAIL_QUALITY_CONTENT_GET_LIST,
    YOUNAIL_QUALITY_CONTENT_GET_DEL_STATUS,
    ROUTER_YOUNAIL_QUALITY_CONTENT_INDEX,
} from '@/store/mutations';
import pubStateField from '@/utils/stateField';
import UserSearchInput from '@/components/user/userSearchInput.vue';

export default {
    components: {UserSearchInput},
    computed: {
        ...mapState({
            userInfo: state => state.auth.userInfo,
            routerParams: state => state.routerParams.younailQualityContentIndex,
            qualityContentList: state => state.younailQualityContent.qualityContentList,
            delQualityContentStatus: state => state.younailQualityContent.delQualityContentStatus,
        })
    },
    mounted() {
        this.getQualityContentList();
    },
    watch: {
        routerParams: {
            handler() {
                this.page = this.routerParams.page;
                this.pageSize = this.routerParams.pageSize;
                this.content_id = this.routerParams.content_id;
                this.title = this.routerParams.title;
                this.is_recommend = this.routerParams.is_recommend;
                this.status = this.routerParams.status;
            },
            deep: true,
            immediate: true
        },

        content_id(value) {
            if (value === '') {
                this.content_id = null;
                this.getQualityContentList();
            }
        },
        title(value) {
            if (value === '') {
                this.title = null;
                this.getQualityContentList();
            }
        },
        is_recommend(value) {
            if (value === '') {
                this.type = null;
                this.getQualityContentList();
            }
        },
        status(value) {
            if (value === '') {
                this.type = null;
                this.getQualityContentList();
            }
        }
    },
    data() {
        return {
            photoHost: pubStateField.photoHost,
            roleMap: pubStateField.roleMap,

            page: 1,
            pageSize: 20,
            content_id: '',
            title: '',
            is_recommend: null,
            status: null,
        };
    },
    methods: {
        ...mapActions({
            YOUNAIL_QUALITY_CONTENT_GET_LIST,
            YOUNAIL_QUALITY_CONTENT_GET_DEL_STATUS,
            ROUTER_YOUNAIL_QUALITY_CONTENT_INDEX,
        }),
        // 获取精选内容列表
        getQualityContentList() {
            this[YOUNAIL_QUALITY_CONTENT_GET_LIST]({
                type: YOUNAIL_QUALITY_CONTENT_GET_LIST,
                params: {
                    page: this.page,
                    page_size: this.pageSize,
                    content_id: this.content_id,
                    title: this.title,
                    is_recommend: this.is_recommend,
                    status: this.status,
                }
            });

            this[ROUTER_YOUNAIL_QUALITY_CONTENT_INDEX]({
                type: ROUTER_YOUNAIL_QUALITY_CONTENT_INDEX,
                params: {
                    page: this.page,
                    page_size: this.pageSize,
                    content_id: this.content_id,
                    title: this.title,
                    is_recommend: this.is_recommend,
                    status: this.status,
                }
            });
        },
        // 删除精选内容
        async deleteQualityContent(content_id) {
            this.$confirm('确认删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error'
            })
                .then(async () => {
                    await this[YOUNAIL_QUALITY_CONTENT_GET_DEL_STATUS]({
                        type: YOUNAIL_QUALITY_CONTENT_GET_DEL_STATUS,
                        params: {
                            id: content_id
                        }
                    });
                    if (this.delQualityContentStatus) {
                        this.$message.success('删除成功');
                        this.getQualityContentList();
                    }
                })
                .catch(() => {
                });
        },

        // 跳转精选内容详情
        toQualityContentDetail(content_id) {
            this.$router.push({name: 'younailQualityContentDetail', query: {content_id: content_id}});
        },
        // 分页
        pageChange(page) {
            this.page = page;
            this.getQualityContentList();
        },
        async searchQualityContent() {
            this.page = 1;
            this.getQualityContentList();
        },
        async onSelectChange() {
            this.page = 1;
            this.getQualityContentList();
        }
    }
};
</script>

<style lang="scss" scoped>
.el-table {
    text-align: left;
    line-height: 40px;

    .cell {
        line-height: 32px;
    }
}
</style>
